import React from 'react'
import PropTypes from 'prop-types'

import InputField from '_components/Input'

const GroupName = ({ register, error }) => (
  <div className='form-group'>
    <label className='c-txt-blue'>Group name</label>
    <InputField
      className='form-control'
      id='groupName'
      name='groupName'
      type='text'
      register={register}
    />
    <small className='errorMessage'>{error?.message}</small>
  </div>
)

GroupName.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  register: PropTypes.object,
}

export default GroupName
