import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export default function ModalCreateEvent({ isShow, toggle, message, ...props }) {
  const history = useHistory()

  return (
    <Modal isOpen={isShow} toggle={toggle} backdrop='static' {...props} className='modal-event'>
      <ModalHeader>Create Event</ModalHeader>
      <ModalBody>
        <div className='content'>
          {message}
        </div>
        <div className='d-flex align-items-center gap-3 mt-3'>
          <button
            className='btn btn-outline-primary rounded-pill shadow-none'
            onClick={() => history.push('/group/search')}
          >
            Join group
          </button>
          <button
            className='btn btn-primary rounded-pill shadow-none'
            onClick={() => history.push('/group/create')}
          >
            Create group
          </button>
        </div>
        {/* <div>
          <a href='http://localhost:3000' target='_blank' rel='noreferrer'>
            localhost:3000
          </a>
        </div> */}
      </ModalBody>
    </Modal>
  )
}
