/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import { DEFAULT_OPTION, DEFAULT_SUB_ITEM } from '_utils/constant'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import Swal from 'sweetalert2'
import InputProduct from './InputProduct'

const disabledStyle = {
  WebkitFilter: 'grayscale(100%)',
  filter: 'grayscale(100%)',
}

const SubItem = (props) => {
  const {
    control,
    register,
    nestIndex,
    productIndex,
    removeSubItem,
    insertSubItem,
    subItem,
    appendSubItem,
    watch,
    errors,
  } = props
  const {
    fields,
    remove: removeOption,
    append: appendOption,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems.${nestIndex}.list`,
  })

  const isError = !!errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list
  const watchFieldArray = watch(`products.${productIndex}.subItems.${nestIndex}.list`)
  const controlledFields =
    watchFieldArray?.length > 0
      ? fields.map((field, _index) => ({
          ...field,
          ...watchFieldArray[_index],
        }))
      : []

  const onAddOption = () => {
    appendOption({ ...DEFAULT_OPTION })
  }

  const onRemoveOption = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to remove this option?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        removeOption(index)
      }
    })
  }

  const onCopySubItem = () => {
    insertSubItem(parseInt(nestIndex + 1, 10), JSON.parse(JSON.stringify({ ...subItem })))
  }

  const onAddSubItem = () => {
    appendSubItem(JSON.parse(JSON.stringify({ ...DEFAULT_SUB_ITEM })))
  }

  const onRemoveSubItem = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to remove this sub item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        removeSubItem(nestIndex)
      }
    })
  }

  return (
    <div className='sub-item c-form is-active'>
      <div className='sub-item-div'>
        <div className='row'>
          <div className='col-3 align-self-center col-index'>Sub item {nestIndex + 1}</div>
          <div className='col-9'>
            <InputProduct
              type='text'
              className={`form-control form-control-bg  ${
                errors?.products !== undefined &&
                Object.entries(errors?.products).length !== 0 &&
                errors?.products?.[productIndex]?.subItems?.[nestIndex]?.type &&
                'is-error-sub-item'
              }`}
              placeholder='Add Category'
              register={register(`products.${productIndex}.subItems.${nestIndex}.type`)}
              error={errors?.products?.[productIndex]?.subItems?.[nestIndex]?.type?.message}
            />
          </div>
        </div>
        {controlledFields?.map((_item, index) => (
          <div
            className={`row sub-item-option align-items-${isError ? 'start' : 'center'}`}
            key={index}
          >
            <div className='sub-item-info col-6'>
              <span className='sub-txt'>{index + 1}. </span>
              <InputProduct
                type='text'
                className={`form-control form-control-line ${
                  errors?.products !== undefined &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list !== undefined &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list[index]?.name &&
                  'is-error-sub-item'
                }`}
                placeholder='Name...'
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.name`
                )}
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.name
                    ?.message
                }
              />
            </div>
            <div className='sub-item-info col-5'>
              <span className='sub-txt'>$ </span>
              <InputProduct
                className={`form-control form-control-line ${
                  errors?.products !== undefined &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list !== undefined &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list[index]?.price &&
                  'is-error-sub-item'
                }`}
                placeholder='Price...'
                type='number'
                step='0.01'
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.price`
                )}
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.price
                    ?.message
                }
              />
            </div>
            <div
              className='d-flex justify-content-end align-items-center col-1'
              style={disabledStyle}
            >
              <a className='btn-remove' onClick={onRemoveOption} />
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='sub-item-info col-12'>
            <a onClick={onAddOption}>Add Option</a>
          </div>
        </div>
      </div>
      <div className='sub-item-div sub-item-div-last'>
        <div className='row'>
          <div className='col-6'>
            <div className='slider-toogle d-flex align-items-center'>
              <label className='mb-0 mr-1 required-label'>Required</label>
              <label className='switch'>
                <input
                  type='checkbox'
                  {...register(`products.${productIndex}.subItems.${nestIndex}.required`)}
                />
                <span className='slider round'></span>
              </label>
            </div>
          </div>
          <div className='col-6'>
            <div className='btn-action-01'>
              <a className='btn-add-01' onClick={onAddSubItem} />
              <a className='btn-copy' onClick={onCopySubItem} />
              <a className='btn-remove' onClick={onRemoveSubItem} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubItem
