import React from 'react'

export default function ButtonPayment({ isDisable }) {
  return (
    <a
      href='#'
      style={isDisable ? { backgroundColor: 'gray' } : {}}
      className='btn-purchase btn-purchase-01'
    >
      <span>Click here to make Payment</span>
    </a>
  )
}
