import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import eventApi from '_api/event'
import { EVENT_CLOSE } from '_utils/constant'
import { convertToCurrentGMT, getDateRemainFromNow } from '_utils/function'
import { setCreateEventData } from '_utils/localData'

const now = moment().unix()
const MyEventItem = ({ event, openModal, setShareLink, isByAdmin = false }) => {
  const [shareCode, setShareCode] = useState(null)
  const { addToast } = useToasts()

  // const [isDisabled, setisDisabled] = useState(false)

  const history = useHistory()

  const onShare = () => {
    if (event?.shopId) return

    setShareLink(
      `${process.env.REACT_APP_APP_URL}/event/detail-shared?eid=${event.id}&shareCode=${shareCode}`
    )
    openModal()
  }
  /*
    Need to call API here, because the data of some
    fields will not be correct when getting data from redux
  */
  const getEvent = async (eid, edit = false) => {
    if (!eid) return
    let eventDetail = {}
    try {
      const res = await eventApi.getEventDetail({
        eid,
        isGetProducts: true,
        isGetDeliveryZones: true,
        isEventShop: !!event?.shopId,
        isByAdmin,
      })
      eventDetail = res.msgResp
      if (eventDetail && eventDetail.id) {
        const { closingTime, pickupTime } = eventDetail

        const closingTimeCurrentGMT = convertToCurrentGMT(closingTime)

        const pickupTimeCurrentGMT = convertToCurrentGMT(pickupTime)
        eventDetail = {
          ...eventDetail,
          closingTime: closingTimeCurrentGMT,
          pickupTime: pickupTimeCurrentGMT,
        }
        if (!edit) {
          delete eventDetail.id
          delete eventDetail.deliveryStatus
          eventDetail.deliveryCost = 0
          eventDetail.discount = 0
          setCreateEventData(eventDetail)
          history.push('/event/create')
        } else {
          for (let i = 0; i < eventDetail.products.length; i++) {
            eventDetail.products[i].pid = eventDetail.products[i].id
          }
          setCreateEventData(eventDetail)
          history.push(`/event/update?id=${eventDetail?.id}`)
        }
      }
    } catch (error) {
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleRehostEvent = () => {
    if (!event.id) return
    getEvent(event.id)
  }
  const handleEditEvent = () => {
    if (!event.id) return
    getEvent(event.id, true)
  }

  useEffect(() => {
    const getShareCode = async () => {
      const { msgCode, msgResp } = await eventApi.getShareEventCode(event.id)
      if (msgCode % 100 === 0) {
        setShareCode(msgResp.shareCode)
      }
    }

    if (!event?.shopId) {
      if (event?.shareCode) {
        setShareCode(event.shareCode)
      } else if (!isByAdmin) {
        getShareCode()
      }
    }
  }, [event, isByAdmin])

  const onLinkToListOrder = () => {
    let url = `/event/manage-orders?eventId=${event.id}`

    if (event?.shopId) {
      url += '&eventShop=true'
    }

    if (isByAdmin) {
      url += '&isByAdmin=true'
    }

    history.push(url)
  }

  const disabledStyle = {
    cursor: 'pointer',
    backgroundColor: 'rgb(222, 226, 230)',
    filter: 'grayscale(100%)',
  }

  const disabledStyleActions = {
    background: 'rgb(241, 241, 241)',
    pointerEvents: 'none',
  }

  const isDisabled =
    event?.pickupTimestamp < now || event?.status === EVENT_CLOSE || event?.closingTimestamp < now

  return (
    <div
      className='event-item group-list group-list--event group-list--event01'
      style={isDisabled ? disabledStyle : {}}
    >
      <div className='group-list__item' style={isDisabled ? disabledStyle : {}}>
        <div className='group-list__info'>
          <h4
            onClick={onLinkToListOrder}
            className='cursor-pointer'
            style={isDisabled ? disabledStyle : {}}
          >
            {event.title}
          </h4>

          <p className='paragraph-pre-wrap' style={isDisabled ? disabledStyle : {}}>
            {event.description}
          </p>
        </div>
        <div className='group-list__btn text-right'>
          <span
            className='txt-date mb-1'
            style={isDisabled ? disabledStyle : {}}
          >{`closing in ${getDateRemainFromNow(convertToCurrentGMT(event?.closingTime))}`}</span>
          <span
            className='txt-date mb-2'
            style={isDisabled ? disabledStyle : {}}
          >{`Delivery in ${getDateRemainFromNow(convertToCurrentGMT(event?.pickupTime))}`}</span>
          <span
            className='txt-number'
            style={isDisabled ? { ...disabledStyle, background: 'gray' } : {}}
          >
            {event.totalOrderCount} BUY
          </span>
        </div>
      </div>
      <ul className='nav-tab'>
        <li style={isDisabled || isByAdmin ? disabledStyleActions : {}} onClick={handleEditEvent}>
          Edit Post
        </li>
        <li style={isDisabled || isByAdmin ? disabledStyleActions : {}} onClick={handleRehostEvent}>
          Re-host
        </li>
        <li
          style={
            isDisabled || event?.shopId || isByAdmin
              ? { ...disabledStyle, background: 'rgb(241, 241, 241)' }
              : {}
          }
          onClick={onShare}
        >
          Share
        </li>
      </ul>
    </div>
  )
}

MyEventItem.propTypes = {
  event: PropTypes.object,
  openModal: PropTypes.func,
  setShareLink: PropTypes.func,
}

export default MyEventItem
