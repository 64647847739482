import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MemberDetail from './MemberDetail';
import MemberList from './MemberList';

function Members() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/member-list`} component={MemberList} />
      <Route path={`${path}/member-detail`} component={MemberDetail} />
    </Switch>
  );
}

export default Members
