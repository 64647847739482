import React from 'react'
import { FaStore } from 'react-icons/fa'
import { MdGroups } from 'react-icons/md'

function WrapEventTag({ children, className, style }) {
  return (
    <div className={`d-flex align-items-center ${className}`} style={style}>
      {children}
    </div>
  )
}

export default function EventTag({ isShop, size, className, color, style }) {
  return (
    <WrapEventTag className={className} style={style}>
      <span style={{ color }}>{isShop ? 'Shop' : 'Group'}</span>
      {isShop ? <FaStore size={size} color={color} /> : <MdGroups size={size} color={color} />}
    </WrapEventTag>
  )
}
