import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import Select, { components } from 'react-select'
import { FormFeedback } from 'reactstrap'

const Input = (props) => <components.Input {...props} isHidden={false} />

export default function EditableSelect(props) {
  const {
    productNameDefault,
    name,
    control,
    setValue,
    className,
    hasError,
    errorMessage,
    productIndex,
    watchSelectedShop,
    watchProducts,
  } = props

  const listProductFilled = watchProducts?.filter((product) => product.shopProductId !== '')

  const filteredProducts =
    listProductFilled.length === 0
      ? watchSelectedShop?.products
      : watchSelectedShop?.products.filter(
          (shopProduct) =>
            !listProductFilled.some(
              (filledProduct) => filledProduct.shopProductId === shopProduct.id
            )
        )

  const [inputValue, setInputValue] = useState('')

  const productNameDefaultMapped = {
    label: productNameDefault,
    value: productNameDefault,
  }

  useEffect(() => {
    if (productNameDefault && Object.keys(productNameDefaultMapped).length > 0) {
      setInputValue(productNameDefault)
    }
  }, [JSON.stringify(productNameDefaultMapped)])

  const listProductMapped = filteredProducts?.map((product) => ({
    label: product.name,
    value: product.id,
    description: product.description,
    price: product.price,
    nameIndex: name,
    availableOfStock: product.availableOfStock,
    limitPerOrder: product.limitPerOrder,
    minOrderQty: product.minOrderQty,
    photoUrls: product.photoUrls,
    subItems: product.subItems,
  }))

  const [options, setOptions] = useState(listProductMapped)

  useEffect(() => {
    setOptions(listProductMapped)
  }, [JSON.stringify(listProductMapped)])

  const selectRef = useRef()

  const onInputChange = (text, { action }) => {
    if (action === 'input-change') {
      setInputValue(text)
      setValue(name, text)
    }
  }

  const onChange = (option) => {
    // setSelectedOption(option)
    setInputValue(option ? option.label : '')
    setValue(name, option ? option.label : '')

    if (option) {
      setValue(`products.${productIndex}.shopProductId`, option.value)
      setValue(`products.${productIndex}.photoUrls`, [option.photoUrls])
      setValue(`products.${productIndex}.description`, option.description)
      setValue(`products.${productIndex}.price`, option.price)
      setValue(`products.${productIndex}.maxQuantity`, option.availableOfStock)
      setValue(`products.${productIndex}.limitPerOrder`, option.limitPerOrder)
      setValue(`products.${productIndex}.minOrderQty`, option.minOrderQty)
      setValue(`products.${productIndex}.subItems`, option.subItems)
    }
  }

  return (
    <div>
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            key={name}
            className={className}
            ref={selectRef}
            options={options}
            isClearable={true}
            placeholder={''}
            value={
              options.find((option) => option.label === field.value) || productNameDefaultMapped
            }
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={(opt) => {
              field.onChange(opt ? opt.value : '')
              onChange(opt)
            }}
            controlShouldRenderValue={true}
            components={{ Input }}
            styles={{
              control: (provided) => ({
                ...provided,
                border: hasError ? '1px solid red' : '1px solid #179d9a',
                boxShadow: 'none',
                '&:hover': {
                  border: hasError ? '1px solid red' : '1px solid #179d9a',
                },
              }),
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : 'black',
                backgroundColor: state.isSelected ? '#179d9a' : 'white',
                '&:hover': {
                  backgroundColor: '#179d9a',
                  color: 'white',
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: '0.275rem 0.75rem',
                fontSize: '1rem',
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral80: '#179d9a',
              },
            })}
          />
        )}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </div>
  )
}
