import React from 'react'

function SelectEventStatus({ onChangeEventStatus, eventStatus }) {
  return (
    <>
      <select
        name='event-status'
        className='form-select rounded-0'
        value={eventStatus}
        onChange={(e) => onChangeEventStatus(e)}
      >
        <option value='default'>Event Status</option>
        <option value='0'>Close Order</option>
        <option value='1'>Open Order</option>
      </select>
    </>
  )
}

export default SelectEventStatus
