import React from 'react'
import './style.scss'

const Pagination = ({ pages, currentPage, onPageChange, className }) => {
  const pageNumbers = []

  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i)
  }

  const handleClick = (event, number) => {
    event.preventDefault()
    onPageChange(number)
  }

  return (
    <nav className={`my-navigation ${className}`} aria-label='Page navigation example'>
      <ul className='pagination justify-content-center'>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className='page-link' href='#' onClick={(e) => handleClick(e, currentPage - 1)}>
            Previous
          </a>
        </li>
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
            <a className='page-link' href='#' onClick={(e) => handleClick(e, number)}>
              {number}
            </a>
          </li>
        ))}
        <li className={`page-item ${currentPage === pages ? 'disabled' : ''}`}>
          <a className='page-link' href='#' onClick={(e) => handleClick(e, currentPage + 1)}>
            Next
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
