import React from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { Modal, ModalBody } from 'reactstrap'

function ModalNotifySuccess({ isOpenModal, toggleModal, ...args }) {
  return (
    <Modal isOpen={isOpenModal} toggle={toggleModal} {...args}>
      <div
        style={{
          height: 100,
          background: '#27ae60',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BsFillCheckCircleFill size={50} className={'mb-1'} />
      </div>
      <ModalBody>
        <div className='text-center fw-bold f-18'>Payment Success</div>
        <div className='text-center mb-3 mt-2'>
          Your group has been enabled the new transaction successfully.
        </div>
        <button className={'btn btn-submit text-white d-flex'} onClick={toggleModal}>
          OK
        </button>
      </ModalBody>
    </Modal>
  )
}

export default ModalNotifySuccess
