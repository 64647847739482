import HitPayLogo from '_images/hitpay-logo.jpg'
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import './styles.scss'

const ModalHitPayPayment = ({ isShow, toggle, service, isSubmittedPayment, confirmPayment }) => (
  <Modal isOpen={isShow} toggle={toggle} className='modal-hit-pay-payment'>
    <ModalHeader toggle={toggle}>
      <span className='d-inline-block title-service' style={{ transform: 'translateX(20px)' }}>
        {service?.type}
      </span>
    </ModalHeader>
    <ModalBody>
      <div className='text-center'>
        <img src={HitPayLogo} alt='hitpay' width={150} style={{ objectFit: 'contain' }} />
      </div>
      <div className='payment-notice-container py-2'>
        <p className='payment-notice'>
          Your payment will be securely processed through HitPay with your credit card.
        </p>
      </div>
      {/* <p className='text-center' style={{ fontSize: 16 }}>
        Please select a payment option:
      </p> */}
      {/* Pro Option Card */}
      <div className='card mx-auto' style={{ width: '18rem' }}>
        <div className='card-body d-flex flex-column'>
          <h5 className='card-title'>Premium</h5>
          <h6 className='card-subtitle mb-2'>${service?.price}/month</h6>
          <p className='card-text'>
            Upgrade to our Pro plan and unlock all premium features, including scheduled events, a
            desktop version, and the ability to switch group modes.{' '}
            <b className='highlight'>You will be used free for 1 month.</b>
          </p>
          <button
            className='btn btn-primary rounded-pill mt-auto'
            onClick={(e) => confirmPayment(e, 'pro')}
            disabled={isSubmittedPayment}
          >
            {isSubmittedPayment && service.selectedSubscription === 'pro'
              ? 'Please wait...'
              : 'Upgrade to Pro'}
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
)

export default ModalHitPayPayment
