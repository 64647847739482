import { convertTimeStringWithDuration } from '_utils/function'
import React, { useMemo } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const BoxForOrder = ({ order }) => (
  <div style={{ border: '1px solid', position: 'relative', margin: '15px 0' }}>
    <div className='truncate'>
      <span style={{ color: '#0BADAD' }}>[Order No: {order?.eOrderNo}] </span>
      <span>{order?.eTitle}</span>
    </div>
    <div style={{ marginTop: 10, padding: '10px 13px', fontSize: '0.9rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Admin Fee</div>
        <div>${order?.adminCostPerOrder?.toFixed(2)}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Delivery Fee</div>
        <div>${order?.deliveryPerOrder?.toFixed(2)}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Discount Fee</div>
        <div>${order?.discountPerOrder?.toFixed(2)}</div>
      </div>
      {order?.allProductPerOrder.map((item, _index) => (
        <React.Fragment key={_index}>
          <div>
            <span>{item.pName} </span>
            {item.pSubItems && item.pSubItems.length > 0 ? (
              <>
                (
                {item.pSubItems.map((_item, idex) => (
                  <span key={idex}>
                    {_item.name}
                    {idex === item.pSubItems.length - 1 ? '' : ', '}
                  </span>
                ))}
                )
              </>
            ) : null}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>x{item.pQuantity}</div>
            <div>${Number(item.pPrice * item.pQuantity).toFixed(2)}</div>
          </div>
        </React.Fragment>
      ))}
    </div>
  </div>
)

const BoxForDelivery = ({ delivery }) => (
  <div style={{ border: '1px solid', position: 'relative', margin: '15px 0' }}>
    <div className='truncate'>
      <span>
        Delivery for (
        {convertTimeStringWithDuration(delivery.dDeliveryTime, delivery.dDeliveryDuration)})
      </span>
    </div>
    <div style={{ marginTop: 10, padding: '10px 13px', fontSize: '0.9rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Delivery Personal Fee</div>
        <div>${delivery.dzPrice?.toFixed(2)}</div>
      </div>
    </div>

    <div style={{ borderTop: '1px solid', fontSize: '0.9rem' }}>
      <div style={{ padding: '10px 13px' }}>
        {`Location: ${delivery.dzName}, address: ${delivery.address}`}
      </div>
    </div>
  </div>
)

export default function ModalPaymentScreen({
  data,
  modalPaymentScreen,
  togglePaymentScreen,
  handleInvalidPayment,
}) {
  const totalPay = useMemo(() => {
    let price = 0
    if (data.listCombinedOrder && data.listCombinedOrder.length) {
      data.listCombinedOrder.forEach((el) => {
        price += el?.totalFee
      })
    }
    if (data.listCombinedDelivery && data.listCombinedDelivery.length) {
      data.listCombinedDelivery.forEach((el) => {
        price += el?.dzPrice
      })
    }
    return price
  }, [data])

  return (
    <div>
      <Modal
        isOpen={modalPaymentScreen}
        toggle={togglePaymentScreen}
        scrollable={true}
        centered
        size='xl'
      >
        <ModalHeader style={{ margin: '0 auto', padding: 0, border: 0 }}>
          <span className='c-txt-gra'>{data.uName}</span>
          <br />
          <span className='c-txt-gra'>
            {' '}
            Payment made for $
            {data && data.totalFee >= 0 ? data?.totalFee?.toFixed(2) : data?.dzPrice?.toFixed(2)}
          </span>
        </ModalHeader>
        <ModalBody className='d-flex flex-column'>
          {data.paymentScreenshot && (
            <img
              className='mb-2'
              style={{ margin: '0 auto' }}
              src={data.paymentScreenshot}
              alt=''
            />
          )}
          {(data.listCombinedOrder && !!data.listCombinedOrder.length) ||
          (data.listCombinedDelivery && !!data.listCombinedDelivery.length) ? (
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1rem' }}>
              {data.listCombinedDelivery.map((delivery, index) => (
                <BoxForDelivery key={index} delivery={delivery} />
              ))}

              {data.listCombinedOrder.map((order, idex) => (
                <BoxForOrder key={idex} order={order} />
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '1.2rem',
                  color: '#ff6903',
                }}
              >
                <div>Total</div>
                <div>${totalPay.toFixed(2)}</div>
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center', flexDirection: 'row', border: 0 }}>
          <div className='col-8 px-0 m-0' style={{ flexBasis: '70%' }}>
            <button className='btn btn--org btn--org02' onClick={togglePaymentScreen}>
              OK
            </button>
          </div>
          <div
            className='col-8 px-0 m-0'
            style={{ flexBasis: '70%', paddingTop: 10, textAlign: 'center', cursor: 'pointer' }}
            onClick={() =>
              handleInvalidPayment(data.ref, data.status, data.combinedPaymentRef, data.eid)
            }
          >
            If invalid payment, click here
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
