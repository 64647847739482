import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

export default function CollectionConfirm({
  day, host, handleConfirmCollection, isSubmittedCollect }) {
  return (
    <div>
      <div id="pendingCollectionHtmlBlock" className="row-bot" style={{ display: 'block' }}>
        <p className="c-txt-18 text-center">
          <strong>Collection scheduled on <span id="pickupTime">{day}</span></strong><br />
          Have you collected from
          <span id="sellerName2"> {host}</span>?
        </p>
        <button className="btn btn--main" onClick={(e) => handleConfirmCollection(e)} disabled={isSubmittedCollect}>
          {isSubmittedCollect ? <Spinner color='light' size='sm' /> : 'COLLECTED'}
        </button>
      </div>
    </div>
  )
}

CollectionConfirm.propTypes = {
  handleConfirmCollection: PropTypes.func,
  day: PropTypes.string,
  host: PropTypes.string,
  isSubmittedCollect: PropTypes.bool
}
