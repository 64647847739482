import React from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Button } from 'reactstrap'

function Pagination({ currentPage, onHandlePrev, onHandleNext }) {
  return (
    <div className='wrap-pagination d-flex align-items-center'>
      <span className='me-3 ms-auto'>Viewing 1-10 of {currentPage}</span>
      <div className='d-flex align-items-center'>
        <Button
          className='btn btn-prev rounded-0 shadow-none'
          outline
          onClick={(e) => onHandlePrev(e)}
        >
          <BsChevronLeft />
        </Button>
        <Button className='btn btn-next rounded-0 shadow-none mt-0' outline onClick={onHandleNext}>
          <BsChevronRight />
        </Button>
      </div>
    </div>
  )
}

export default Pagination
