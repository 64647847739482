import { getDeliveryByBuyer, getMulListDeliveryCreatedByHost } from '_redux/modules/delivery'
import { getListCombinedPayment } from '_redux/modules/event'
import { getMyOrders } from '_redux/modules/order'
import { DELIVERY_OPENED } from '_utils/constant'
import {
  convertTimeStringWithDuration,
  convertTimeStringWithDurationTwoPart,
  convertToCurrentGMT,
  sortListMyDelivery,
  sortListMyOrder,
} from '_utils/function'
import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LoadingOverlay from '_components/LoadingOverlay'
import { getMyShops } from '_redux/modules/shop'
import BoxAddDeliveryByBuyer from '../components/BoxAddDeliveryByBuyer'
import ItemDeliveryBooking from '../components/ItemDeliveryBooking'
import ItemOrder from '../components/ItemOrder'
import ModalDelivery from '../components/ModalDelivery'

const now = moment().unix()

function ListMyPurchases() {
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = getUserInfo()
  if (!userInfo) {
    history.push('/sign-in')
    return null
  }
  const { id: userId } = userInfo

  const [isLoading, setIsLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [deliveryInfo, setDeliveryInfo] = useState(null)

  const { myOrders, listHostId, listHostDetail } = useSelector((state) => state.order)
  const { listDeliveryCreatedByHost, listDeliveryByBuyer } = useSelector((state) => state.delivery)
  const { myShops } = useSelector((state) => state.shop)

  const initialOrders = async () => {
    setIsLoading(true)
    await dispatch(getMyOrders)
    await dispatch(getMyShops())
    await dispatch(getDeliveryByBuyer(userId))
    setIsLoading(false)
  }

  const initListCombined = async (_myOrders, _listDeliveryByBuyer) => {
    if (_myOrders?.length && _listDeliveryByBuyer?.length) {
      const listCombinedPaymentRef = []
      _myOrders.forEach((element) => {
        const { combinedPaymentRef = '' } = element
        if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
          if (combinedPaymentRef) {
            listCombinedPaymentRef.push(combinedPaymentRef)
          }
        }
      })
      _listDeliveryByBuyer.forEach((element) => {
        const { combinedPaymentRef = '' } = element
        if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
          if (combinedPaymentRef) {
            listCombinedPaymentRef.push(combinedPaymentRef)
          }
        }
      })
      if (listCombinedPaymentRef.length) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef))
      }
    }
  }

  const addDelivery = async (hostId, entityId) => {
    const { listDeliveryCreated } = listDeliveryCreatedByHost.find((item) => item.hostId === hostId)
    const { deliveryZones } = listDeliveryCreated[0]
    const { eCreatedUserName, eCreatedUserPhotoUrl } = listHostDetail?.find(
      (item) => item.eCreatedUserId === hostId
    )

    const newListDeliveryCreated = listDeliveryCreated
      ?.sort((a, b) => b?.deliveryTimestamp - a?.deliveryTimestamp)
      ?.filter(
        (item) =>
          (item?.groupId === entityId || item?.shopId === entityId) &&
          item?.deliveryStatus === DELIVERY_OPENED &&
          item?.deliveryTimestamp > now
      )
      ?.map((item) => ({
        ...item,
        deliveryTime: convertTimeStringWithDuration(item.deliveryTime, item.deliveryDuration),
      }))

    setDeliveryInfo({
      deliveryZones: newListDeliveryCreated[0]?.deliveryZones || deliveryZones,
      listDeliveryCreated: newListDeliveryCreated,
      eCreatedUserName,
      eCreatedUserPhotoUrl,
    })
    setIsShowModal(true)
  }

  useEffect(() => {
    initListCombined(myOrders, listDeliveryByBuyer).then(() => {})
  }, [JSON.stringify(myOrders), JSON.stringify(listDeliveryByBuyer)])

  useEffect(() => {
    initialOrders().then(() => {})
  }, [])

  useEffect(() => {
    const shopIds = myShops.map((shop) => shop.id)

    dispatch(getMulListDeliveryCreatedByHost(listHostId, shopIds))
  }, [listHostId, myShops])

  const renderBoxAddDelivery = () => {
    let lastType = null
    return (
      <>
        {listHostDetail &&
        listHostDetail.length > 0 &&
        listDeliveryCreatedByHost &&
        listDeliveryCreatedByHost.length > 0 ? (
          <>
            {listHostDetail
              .filter((item) => {
                const deliveryHost = listDeliveryCreatedByHost.find(
                  (del) => del.hostId === item.eCreatedUserId
                )
                if (!deliveryHost) return false

                const listDeliveryCreated = deliveryHost.listDeliveryCreated.filter(
                  (del) =>
                    del.deliveryStatus === DELIVERY_OPENED &&
                    ((item.eGroupId && del.groupId) || (item.eShopId && del.shopId)) &&
                    del.deliveryTimestamp > now &&
                    del.deliveryZones &&
                    del.deliveryZones.length > 0
                )

                return listDeliveryCreated.length > 0
              })
              .map((item, index) => {
                const {
                  eCreatedUserName,
                  eCreatedUserPhotoUrl,
                  eCreatedUserId,
                  eGroupName,
                  eGroupId,
                  eShopId,
                  eShopName,
                } = item

                const deliveryHost = listDeliveryCreatedByHost.find(
                  (del) => del.hostId === eCreatedUserId
                )

                if (!deliveryHost) {
                  return null
                }

                let { listDeliveryCreated = [] } = deliveryHost

                listDeliveryCreated = listDeliveryCreated?.filter(
                  (del) =>
                    del.deliveryStatus === DELIVERY_OPENED &&
                    del.deliveryTimestamp > now &&
                    del.deliveryZones &&
                    del.deliveryZones.length > 0
                )

                if (listDeliveryCreated && listDeliveryCreated.length) {
                  const currentType = eGroupId ? 'Delivery Group' : 'Delivery Shop'
                  const showHeader = currentType !== lastType
                  lastType = currentType

                  return (
                    <React.Fragment key={index}>
                      {showHeader && (
                        <p className='mb-2' style={{ fontSize: 16, fontWeight: 600 }}>
                          {currentType}
                        </p>
                      )}
                      <BoxAddDeliveryByBuyer
                        createdUserPhotoUrl={eCreatedUserPhotoUrl}
                        createdUserName={eCreatedUserName}
                        groupName={eGroupName}
                        hostId={eCreatedUserId}
                        groupId={eGroupId}
                        addDelivery={addDelivery}
                        shopId={eShopId}
                        shopName={eShopName}
                        hideHeader={true}
                      />
                    </React.Fragment>
                  )
                }

                return null
              })}
          </>
        ) : null}
      </>
    )
  }

  const handleToDeliveryDetail = (_ref, dShopId) => {
    let url = `/event/delivery-detail?ref=${_ref}`
    if (dShopId) {
      url += '&deliveryShop=true'
    }
    history.push(url)
  }

  return (
    <React.Fragment>
      {isLoading && <LoadingOverlay />}
      {!isLoading && (
        <div className='group-list group-list--event mt-3'>
          {renderBoxAddDelivery()}

          {listDeliveryByBuyer &&
            !!listDeliveryByBuyer.length &&
            sortListMyDelivery(listDeliveryByBuyer).listDeliveryActiveSort.map((item, index) => (
              <ItemDeliveryBooking
                key={index}
                createdUserPhotoUrl={item.dCreatedUserPhotoUrl}
                createdUserName={item.dCreatedUserName}
                groupName={item.dGroupName}
                deliveryTimeString={convertTimeStringWithDurationTwoPart(
                  item.dDeliveryTime,
                  item.dDeliveryDuration
                )}
                deliveryTime={convertToCurrentGMT(item.dDeliveryTime)}
                bookerStatus={item.bookerStatus}
                status={item.status}
                combinedPaymentRef={item.combinedPaymentRef || null}
                dRef={item.ref}
                onClick={() => handleToDeliveryDetail(item.ref, item?.dShopId)}
              />
            ))}

          {(!myOrders || !myOrders?.length) && (
            <p className='fw-bold text-center' style={{ fontSize: '1rem' }}>
              No purchase found.
            </p>
          )}
          {myOrders &&
            !!myOrders.length &&
            sortListMyOrder(myOrders).listOrderActiveSort.map((o, i) => (
              <ItemOrder key={o.id || i} order={o} />
            ))}

          {listDeliveryByBuyer &&
            !!listDeliveryByBuyer.length &&
            sortListMyDelivery(listDeliveryByBuyer).listDeliveryDisableSort.map((item, index) => (
              <ItemDeliveryBooking
                key={index}
                createdUserPhotoUrl={item.dCreatedUserPhotoUrl}
                createdUserName={item.dCreatedUserName}
                groupName={item.dGroupName}
                deliveryTimeString={convertTimeStringWithDurationTwoPart(
                  item.dDeliveryTime,
                  item.dDeliveryDuration
                )}
                deliveryTime={convertToCurrentGMT(item.dDeliveryTime)}
                bookerStatus={item.bookerStatus}
                status={item.status}
                combinedPaymentRef={item.combinedPaymentRef || null}
                dRef={item.ref}
                onClick={() => handleToDeliveryDetail(item.ref)}
                isDisable={true}
              />
            ))}

          {myOrders &&
            !!myOrders.length &&
            sortListMyOrder(myOrders).listOrderDisableSort.map((o, i) => (
              <ItemOrder key={o.id || i} order={o} />
            ))}
        </div>
      )}
      {isShowModal && (
        <ModalDelivery
          isShowModal={isShowModal}
          deliveryInfo={deliveryInfo}
          toggle={() => setIsShowModal(false)}
        />
      )}
    </React.Fragment>
  )
}

export default ListMyPurchases
