import moment from 'moment'

export const PAYMENT_HIT_PAY = 1
export const MODAL_INFO_CREATE_GROUP = 'MODAL_INFO_CREATE_GROUP'
export const ALL_EVENTS_OPENING = 'ALL_EVENTS_OPENING'
export const USER_TOKEN = 'USER_TOKEN'
export const USER_INFO = 'USER_INFO'
export const FACEBOOK = 'facebook'
export const GOOGLE = 'google'
export const UNKNOWN = 'unknown'
export const SOCIAL_TOKEN = 'SOCIAL_TOKEN'
export const DEVICE_USER = 'DEVICE_USER'
export const PHONE = 'PHONE'
export const DESKTOP = 'DESKTOP'
export const USER_AVATAR_DEFAULT =
  'https://firebasestorage.googleapis.com/v0/b/co-bee-stg.appspot.com/o/images%2Fsample.jpeg?alt=media&token=07160743-72a8-4856-ae7c-e2dcbe82f5be'

export const LIST_GROUP = 'LIST_GROUP'
export const LIST_PUBLIC_GROUP = 'LIST_PUBLIC_GROUP'
export const DATA_FIREBASE = 'DATA_FIREBASE'
export const DEBUG_MODE = 'DEBUG_MODE'
export const DEBUG_SCOPE = 'DEBUG_SCOPE'
export const LIST_EVENT = 'LIST_EVENT'
export const LIST_MY_EVENT = 'LIST_MY_EVENT'
export const GROUP_ID_SELECTED = 'GROUP_ID_SELECTED'

export const LIST_ORDER = 'LIST_ORDER'

export const PIN_MIN_VALUE = 0
export const PIN_MAX_VALUE = 9
export const POSTAL_CODE_MAX_VALUE = 6
export const PASSCODE_MIN_VALUE = 4
export const PASSCODE_MAX_VALUE = 4

export const EMAIL_IS_TAKEN_CODE = 10013
export const SUCCESS = 'success'
export const ERROR = 'error'

export const ORDER_CANCELED = -1

export const BUYER_REFUNDED = -2
export const BUYER_ORDER_CANCELED = -1
export const BUYER_ORDER_UNPAID = 1
export const BUYER_ORDER_PAID = 2
export const BUYER_ORDER_COLLECTED = 3

export const HOST_ORDER_CANCELED = -1
export const HOST_ORDER_UNPAID = 1
export const HOST_ORDER_VERIFIED = 2
export const HOST_ORDER_COLLECTED = 3

export const SUBITEM_REQUIRED = 1

export const DONT_EXIST_IN_ARRAY = -1

export const GROUP_IS_HIDDEN = 2
export const GROUP_IS_PUBLIC = 1
export const GROUP_IS_PRIVATE = 0
export const GROUP_APPROVED = 2
export const GROUP_REQUESTING_APPROVAL = 3
export const GROUP_DONT_APPROVAL = 1
export const GROUP_BANNED = 1
export const GROUP_DONT_BAN = 0
export const GROUP_IS_ADMIN = 1
export const GROUP_IS_HOST = 1
export const GROUP_IS_NOT_ADMIN = 0
export const GROUP_IS_NOT_HOST = 0
export const AVAILABLE_STOCK_DEFAULT = 1000
export const LIMIT_PER_ORDER_DEFAULT = 1000
export const MINIMUM_ORDER_PER_PRODUCT = 1
export const END_DAY = '23:59'
export const DEFAULT_COST = 0
export const DEFAULT_QUANTITY = 0
export const FIRST_INDEX_PRODUCT_CSV = 3
export const FIRST_INDEX_SUBITEM_CSV = 20

export const EVENT_UNPAID = 1
export const EVENT_PAID = 2
export const PAYNOW = 1
export const NOT_PAYNOW = 0
export const NOT_PAYABLE = 0
export const BUYER_UNPAID = 1
export const BUYER_PAID = 2
export const CREATE_EVENT_DATA = 'CREATE_EVENT_DATA'
export const EVENT_PAY_LATER = 0

export const SINGAPORE_CODE = '+65'
export const SINGAPORE_CODE_NO_ADD = '65'

export const EVENT_CANCELED = -1
export const BUYER_CANCELED = -1
export const EVENT_IS_REOPEN = 0
export const PAYABLE = 1
export const IS_CLOSING_DAY = 0
export const IS_PICKUP_DAY = 0
export const BUYER_COLLECTED = 3

export const DEFAULT_OPTION = {
  name: '',
  price: 0,
}
export const DEFAULT_SUB_ITEM = {
  type: '',
  required: 0,
  list: [DEFAULT_OPTION],
}
export const DEFAULT_PRODUCT = {
  pid: '',
  name: '',
  description: null,
  price: 0,
  maxQuantity: AVAILABLE_STOCK_DEFAULT,
  defaultQuantity: DEFAULT_QUANTITY,
  limitPerOrder: LIMIT_PER_ORDER_DEFAULT,
  subItems: [],
  photoUrls: [],
  minOrderQty: MINIMUM_ORDER_PER_PRODUCT,
}

export const EVENT_STATUS_OPEN = 1
export const EVENT_COLECTED = 3

export const HOST_ORDER_COLECTED = 3

export const SHORT_DATE = 'DD-MM-YYYY'
export const DATE_FORMAT = 'DD-MM-YYYY HH:mm'

export const EVENT_CLOSE = 0

export const GMT_0 = 'GMT-00:00'

export const FEEDBACK = 'feedback'

export const LIST_TIME = [
  { text: '1AM', value: 1, id: 1 },
  { text: '2AM', value: 2, id: 2 },
  { text: '3AM', value: 3, id: 3 },
  { text: '4AM', value: 4, id: 4 },
  { text: '5AM', value: 5, id: 5 },
  { text: '6AM', value: 6, id: 6 },
  { text: '7AM', value: 7, id: 7 },
  { text: '8AM', value: 8, id: 8 },
  { text: '9AM', value: 9, id: 9 },
  { text: '10AM', value: 10, id: 10 },
  { text: '11AM', value: 11, id: 11 },
  { text: '12AM', value: 12, id: 12 },
  { text: '1PM', value: 13, id: 13 },
  { text: '2PM', value: 14, id: 14 },
  { text: '3PM', value: 15, id: 15 },
  { text: '4PM', value: 16, id: 16 },
  { text: '5PM', value: 17, id: 17 },
  { text: '6PM', value: 18, id: 18 },
  { text: '7PM', value: 19, id: 19 },
  { text: '8PM', value: 20, id: 20 },
  { text: '9PM', value: 21, id: 21 },
  { text: '10PM', value: 22, id: 22 },
  { text: '11PM', value: 23, id: 23 },
  { text: '12PM', value: 24, id: 24 },
]

export const LIST_TIME_2 = [
  { text: '1AM', valueString: '01:00', id: 1, valueNumber: 1 },
  { text: '2AM', valueString: '02:00', id: 2, valueNumber: 2 },
  { text: '3AM', valueString: '03:00', id: 3, valueNumber: 3 },
  { text: '4AM', valueString: '04:00', id: 4, valueNumber: 4 },
  { text: '5AM', valueString: '05:00', id: 5, valueNumber: 5 },
  { text: '6AM', valueString: '06:00', id: 6, valueNumber: 6 },
  { text: '7AM', valueString: '07:00', id: 7, valueNumber: 7 },
  { text: '8AM', valueString: '08:00', id: 8, valueNumber: 8 },
  { text: '9AM', valueString: '09:00', id: 9, valueNumber: 9 },
  { text: '10AM', valueString: '10:00', id: 10, valueNumber: 10 },
  { text: '11AM', valueString: '11:00', id: 11, valueNumber: 11 },
  { text: '12AM', valueString: '12:00', id: 12, valueNumber: 12 },
  { text: '1PM', valueString: '13:00', id: 13, valueNumber: 13 },
  { text: '2PM', valueString: '14:00', id: 14, valueNumber: 14 },
  { text: '3PM', valueString: '15:00', id: 15, valueNumber: 15 },
  { text: '4PM', valueString: '16:00', id: 16, valueNumber: 16 },
  { text: '5PM', valueString: '17:00', id: 17, valueNumber: 17 },
  { text: '6PM', valueString: '18:00', id: 18, valueNumber: 18 },
  { text: '7PM', valueString: '19:00', id: 19, valueNumber: 19 },
  { text: '8PM', valueString: '20:00', id: 20, valueNumber: 20 },
  { text: '9PM', valueString: '21:00', id: 21, valueNumber: 21 },
  { text: '10PM', valueString: '22:00', id: 22, valueNumber: 22 },
  { text: '11PM', valueString: '23:00', id: 23, valueNumber: 23 },
  { text: '12PM', valueString: '24:00', id: 24, valueNumber: 24 },
]

export const TOKEN_EXPIRED = 99999

export const UNAUTHORIZED = 'Unauthorized'

export const LIMIT_TEXT = 64

export const MAXIMUM_NUMBER_OF_EVENT_IMAGES = 5
export const SHIFT_UP = 'UP'
export const SHIFT_DOWN = 'DOWN'

export const SIZE_IMAGE = 256
export const NUMBER_ENLARGE_DEFAULT = 3

export const NO_SUBDOMAIN = 0
export const SUBDOMAIN_NOT_DEPLOY = 1
export const SUBDOMAIN_DEPLOYED = 2

export const DOMAIN = window.location.hostname
export const ALLOW_SCHEDULE_EVENT = 1

export const DEFAULT_DELIVERY_OPTION = {
  name: '',
  price: 0,
}
export const NO_DELIVERY = 1
export const DELIVERY_BUT_HAVE_NOT_BOOKED = 2
export const DELIVERY_AND_HAVE_BOOKED = 3
export const DELIVERY_CLOSED = 0
export const DELIVERY_NOT_CLOSE = 1
export const DELIVERY_BUYER_REFUND = -2
export const DELIVERY_BUYER_CANCELLED = -1
export const DELIVERY_BUYER_UNPAID = 1
export const DELIVERY_BUYER_PAID = 2

export const DELIVERY_HOST_CANCELLED = -1
export const DELIVERY_HOST_UNPAID = 1
export const DELIVERY_HOST_PAID = 2

export const DELIVERY_OPENED = 1

// hardCode, will fix later
export const PAYMENT_SERVICE_PHONE = 'XXXXXXX'
export const PAYMENT_SERVICE_EXPIRY_DATE = '21001231'
export const PAYMENT_SERVICE_SCHEDULE_PRICE = 30
export const PAYMENT_SERVICE_SCHEDULE_REF = 'SC11111'
export const PAYMENT_SERVICE_SUBDOMAIN_REF = 'SU11111'

// Status payment Subdomain, Schedule Event
export const PAYMENT_STATUS_CANCEL_BY_USER = -2
export const PAYMENT_STATUS_CANCEL_BY_COBEE = -1
export const PAYMENT_STATUS_UNPAID = 1
export const PAYMENT_STATUS_PAID = 2
export const PAYMENT_STATUS_VERIFY = 3

export const STATUS_NOT_USE = 0
export const STATUS_PENDING = 1
export const STATUS_PAID = 2

export const SCHEDULE_STATUS_NOT_USE = 0
export const SCHEDULE_STATUS_PENDING = 1
export const SCHEDULE_STATUS_PAID = 2

export const NO_PAY_TOGETHER = 0
export const PAY_TOGETHER = 1

export const GROUP_BUY = 'GROUP_BUY'
export const DELIVERY_OPTION = 1
export const SELF_PICKUP = 2
export const CHECKED = 1
export const UNCHECKED = 0

export const SUBSCRIPTION_CANCEL_STATUS = 0
export const SUBSCRIPTION_PENDING_STATUS = 1
export const SUBSCRIPTION_ACTIVE_STATUS = 2
export const SUBSCRIPTION_TRAIL_STATUS = 3

export const SHOP_QTY = 2
export const LIST_SHOP_PAID = 'LIST_SHOP_PAID'

export const ITEMS_PER_PAGE = 10
export const EVENT_NUMBER_PAGE_DEFAULT = 1
export const SHOP_NUMBER_PAGE_DEFAULT = 1

export const PACKAGE_TYPE_SCHEDULE_EVENT = 3
export const PACKAGE_TYPE_COBEE = 6
export const PACKAGE_TYPE_COBEE_DESKTOP = 5
export const PACKAGE_TYPE_PREMIUM_ACCOUNT = 7
export const LIST_PACKAGE_TYPE = [
  {
    name: 'Subscription',
    type: 1,
    forProject: 'co-hoot',
  },
  {
    name: 'Lazada',
    type: 2,
    forProject: 'co-hoot',
  },
  {
    name: 'Schedule',
    type: 3,
    forProject: 'co-bee',
  },
  {
    name: 'SubDomain',
    type: 4,
    forProject: 'co-bee',
  },
  {
    name: 'Co-bee Desktop',
    type: 5,
    forProject: 'co-bee',
  },
  {
    name: 'Group',
    type: 6,
    forProject: 'co-bee',
  },
  {
    name: 'Premium Account',
    type: 7,
    forProject: 'co-bee',
  },
]

export const CO_BEE_FEATURE_APPROVED_STATUS = 2
export const CO_BEE_FEATURE_PENDING_STATUS = 1
export const CO_BEE_FEATURE_CANCEL_STATUS = 0
export const CO_BEE_FEATURE_FAILED_STATUS = -1
export const CO_BEE_FEATURE_REFUNDED_STATUS = -2

export const CO_BEE_FEATURE_VERIFY_PAYMENT_STATUS = 3
export const CO_BEE_FEATURE_PAID_PAYMENT_STATUS = 2
export const CO_BEE_FEATURE_UNPAID_PAYMENT_STATUS = 1
export const CO_BEE_FEATURE_EXPIRE_PAYMENT_STATUS = 0
export const CO_BEE_FEATURE_FAIL_PAYMENT_STATUS = -1
export const CO_BEE_FEATURE_REFUNDED_PAYMENT_STATUS = -2

export const OPTION_CURRENCY_SG = {
  style: 'currency',
  currency: 'SGD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
}

export const COLOR_STATUS_APPROVED = '#27ae60'
export const COLOR_STATUS_PENDING = '#F06A0F'
export const COLOR_STATUS_CANCELLED = '#e74c3c'
export const COLOR_STATUS_FAILED = '#6d6d6d'
export const COLOR_STATUS_REFUNDED = '#3498db'

export const RELEASE_DATE_EXAMPLE = moment('20-07-2024', 'DD-MM-YYYY').unix()
export const TURN_ON = 1
export const TURN_OFF = 0

export const MENU_ITEMS = [
  {
    name: 'My Group Buy',
    path: '/group/my-group-buy',
    className: 'step-2',
  },
  {
    name: 'Co-Hoot',
    path: '/co-hoot',
    className: 'step-3',
  },
]
