/* eslint-disable array-callback-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import deliveryApi from '_api/delivery'
import {
  callNewDeliveryCreatedByHost,
  getListDeliveryCreatedByHost,
  getMulListDeliveryCreatedByHost,
  getScheduledDelivery,
} from '_redux/modules/delivery'
import { getMyEvents, getMyShopEvents, getScheduledEvent } from '_redux/modules/event'
import { getMyGroups } from '_redux/modules/group'
import { DEFAULT_DELIVERY_OPTION, GROUP_IS_HOST, LIST_TIME } from '_utils/constant'
import {
  convertTimeStringWithDuration,
  convertToCurrentGMT,
  convertToGMT0,
  sortListEventsByPickupTimestamp,
} from '_utils/function'
import { getUserInfo } from '_utils/localData'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { getMyShops } from '_redux/modules/shop'
import BoxDeliveryDateByHost from '../components/BoxDeliveryDateByHost'
import DeliveryOptionsModal from '../components/DeliveryOptionsModal'
import MyEventItem from '../components/MyEventItem'
import MyScheduledDelivery from '../components/MyScheduleDelivery'
import MyScheduledEvent from '../components/MyScheduledEvent'
import './style.scss'

const now = moment().unix()

const MyEventList = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const userInfo = getUserInfo()
  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }
  const { id: userId } = userInfo
  // const userHasShop = userInfo?.shopLinks && userInfo.shopLinks.length > 0
  const { myGroups } = useSelector((state) => state.group)
  const { myShops } = useSelector((state) => state.shop)
  const { myEvents, myShopEvents, allScheduleEvent } = useSelector((state) => state.event)
  const { listDeliveryCreatedByHost, allScheduleDelivery } = useSelector((state) => state.delivery)

  let { listDeliveryCreated = null } =
    listDeliveryCreatedByHost.find((item) => item.hostId === userId) || {}

  listDeliveryCreated = listDeliveryCreated
    ? listDeliveryCreated.sort((a, b) => a.deliveryTimestamp - b.deliveryTimestamp)
    : null

  const { listScheduleDelivery = null } =
    allScheduleDelivery.find((item) => item.hostId === userId) || {}

  const [isLoading, setIsLoading] = useState(false)

  const [textBtn, setTextBtn] = useState('COPY')
  const [shareLink, setShareLink] = useState('')
  const [modal, setModal] = useState(false)
  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [timeGetDeliveryByHost, setTimeGetDeliveryByHost] = useState(0)
  const [timeGetScheduleEvent, setTimeGetScheduleEvent] = useState(0)
  const [timeGetScheduleDelivery, setTimeGetScheduleDelivery] = useState(0)
  const [errorMessageDeliveryZone, setErrorMessageDeliveryZone] = useState('')
  const [combinedListMyEvents, setCombinedListMyEvents] = useState([])
  const [optionsEntity, setOptionsEntity] = useState([])
  const [selectedEntity, setSelectedEntity] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])
  const [deliveryHourStart, setDeliveryHourStart] = useState('10AM')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('1PM')

  const toggle = () => setModal(!modal)

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  const isCloseEvent = (pickupTimestamp) => {
    if (pickupTimestamp < now) {
      return true
    }
    return false
  }

  useEffect(() => {
    let isMounted = true

    const getEvents = async () => {
      setIsLoading(true)
      try {
        await dispatch(getMyEvents)
        await dispatch(getScheduledEvent)
        await dispatch(getMyGroups)
        // should not use Promise.all here because we need to get myShopEvents after myShops
        await dispatch(getMyShops())
        await dispatch(getMyShopEvents)
        await dispatch(getListDeliveryCreatedByHost(userId, ''))
        await dispatch(getScheduledDelivery(userId))
      } catch (error) {
        addToast(error?.message, { appearance: 'error', autoDismiss: true })
      }
      if (isMounted) {
        setIsLoading(false)
      }
    }

    getEvents()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    const combinedEvents = [...myEvents, ...myShopEvents]
    setCombinedListMyEvents(combinedEvents)
  }, [myShopEvents.length, myEvents.length])

  const [deliveryTime, setDeliveryTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  )

  useEffect(() => {
    if (myGroups && (myGroups.length || myShops.length)) {
      const hostForGroup = myGroups?.filter((group) => group.isHost === GROUP_IS_HOST)
      const combinedGroupShop = [...hostForGroup, ...myShops]
        .sort((a, b) => {
          if (a.id && !b.id) return -1 // field "id" is for group.id, else is for shop.shopId
          if (!a.id && b.id) return 1
          return 0
        })
        .map((item) => ({
          ...item,
          name: item.name || item.shopName,
        }))

      if (listDeliveryCreated && Array.isArray(listDeliveryCreated)) {
        const deliveryZonesFound = listDeliveryCreated.find(
          (item) =>
            item.groupId === combinedGroupShop[0]?.gid || item.shopId === combinedGroupShop[0]?.id
        )?.deliveryZones

        if (deliveryZonesFound) {
          setDeliveryZones(deliveryZonesFound)
        }
      }

      setOptionsEntity(combinedGroupShop)
      setSelectedEntity(combinedGroupShop[0]?.gid || combinedGroupShop[0]?.id)
    }
  }, [myGroups, JSON.stringify(myShops), listDeliveryCreated])

  useEffect(() => {
    dispatch(getListDeliveryCreatedByHost(userId, ''))
  }, [timeGetDeliveryByHost])

  useEffect(() => {
    dispatch(getScheduledDelivery(userId))
  }, [timeGetScheduleDelivery])

  useEffect(() => {
    dispatch(getScheduledEvent)
  }, [timeGetScheduleEvent])

  useEffect(() => {
    if (!openModalAddDelivery) {
      setErrorMessageDeliveryZone('')
    }
  }, [openModalAddDelivery])

  useEffect(() => {
    dispatch(getMulListDeliveryCreatedByHost([userId], []))
  }, [])

  useEffect(() => {
    if (myShops && Array.isArray(myShops) && selectedEntity) {
      const shopIds = myShops.map((shop) => shop.id)
      dispatch(getMulListDeliveryCreatedByHost([userId], shopIds))

      const shop = myShops.find((item) => item.id === selectedEntity)
      if (shop) {
        setDeliveryZones(shop.rememberedDeliveryZones)
      } else {
        const getRememberedDeliveryZones = async () => {
          const deliveryZonesGroup = await deliveryApi.getRememberedDeliveryZones('', false)
          const newDeliveryZones = deliveryZonesGroup.msgResp.length
            ? deliveryZonesGroup.msgResp
            : [...deliveryZones, DEFAULT_DELIVERY_OPTION]
          setDeliveryZones(newDeliveryZones)
        }
        getRememberedDeliveryZones()
      }
    }
  }, [selectedEntity, JSON.stringify(myShops)])

  useEffect(() => {
    if (
      listDeliveryCreated &&
      Array.isArray(listDeliveryCreated) &&
      selectedEntity &&
      selectedEntity !== ''
    ) {
      const deliveryZonesFound = listDeliveryCreated.find(
        (item) => item.groupId === selectedEntity || item.shopId === selectedEntity
      )?.deliveryZones

      if (deliveryZonesFound) {
        setDeliveryZones(deliveryZonesFound)
      }
    }
  }, [selectedEntity, listDeliveryCreated])

  const handleOnClickOk = async () => {
    setIsSubmitting(true)
    const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
    const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
    const timeStart = LIST_TIME[deliveryStartIndex].value
    const timeEnd = LIST_TIME[deliveryEndIndex].value
    const deliveryDuration = (timeEnd - timeStart) * 3600
    if (deliveryDuration < 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      setIsSubmitting(false)
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    if (!rememberedDeliveryZones.length) {
      setErrorMessageDeliveryZone('Please fill in all delivery zones')
      setIsSubmitting(false)
      return
    }

    const deliveryTimeLocal = `${moment(deliveryTime).format('DD-MM-YYYY')} ${timeStart}:00`

    const deliveryTimestamp = moment(deliveryTimeLocal, 'DD-MM-YYYY HH:mm').unix()

    if (deliveryTimestamp < now) {
      setIsSubmitting(false)
      addToast('Delivery time must after current time', { appearance: 'error', autoDismiss: true })
      return
    }

    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    const findGroupIndex = myGroups?.findIndex((item) => item.id === selectedEntity)
    const findShopIndex = myShops?.findIndex((item) => item.id === selectedEntity)

    try {
      const payload = {
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration,
      }

      if (findGroupIndex !== -1) {
        payload.groupId = selectedEntity
        payload.isEventShop = false
      } else if (findShopIndex !== -1) {
        payload.shopId = selectedEntity
        payload.isEventShop = true
      }

      await deliveryApi.createDeliveryByHost(payload, payload.isEventShop)

      dispatch(callNewDeliveryCreatedByHost())
      setTimeGetDeliveryByHost((prevState) => prevState + 1)

      const dynamicRememberedDeliveryZones = !payload.isEventShop
        ? 'rememberedDeliveryZones'
        : 'shopEventRememberedDeliveryZones'
      const updateData = { [dynamicRememberedDeliveryZones]: rememberedDeliveryZones }

      await deliveryApi.updateRememberedDeliveryZones(userId, updateData, payload.isEventShop)
      addToast('Added delivery', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      return
    }
    setIsSubmitting(false)
    toggleModalAddDelivery()
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryTime(e)
  }

  const onChangeZoneDelivery = (index, type, value) => {
    deliveryZones[index][type] = value
    setDeliveryZones([...deliveryZones])
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const handleOnRemoveDeliveryZone = (index) => {
    deliveryZones.splice(index, 1)
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
  }

  const onOpenAddDelivery = async () => {
    const deliveryZonesGroup = await deliveryApi.getRememberedDeliveryZones('', false)

    const newDeliveryZones = deliveryZonesGroup.msgResp.length
      ? deliveryZonesGroup.msgResp
      : [...deliveryZones, DEFAULT_DELIVERY_OPTION]

    setDeliveryZones(newDeliveryZones)
    toggleModalAddDelivery()
  }

  return (
    <div className='row-top my-events-page'>
      {isLoading && <div>Loading...</div>}
      {!isLoading ? (
        <>
          <div
            className='text-add-delivery d-flex flex-row-reverse mt-2 mb-2'
            onClick={onOpenAddDelivery}
          >
            Add delivery
          </div>

          {listDeliveryCreated && listDeliveryCreated.length
            ? listDeliveryCreated
                .filter((ele) => ele.deliveryTimestamp >= now)
                .sort((a, b) => {
                  // Đưa các phần tử của group lên trên
                  if (a.groupId && !b.groupId) return -1
                  if (!a.groupId && b.groupId) return 1
                  // Nếu cùng loại (cả hai là group hoặc cả hai là shop), sắp xếp theo thời gian
                  return a.deliveryTimestamp - b.deliveryTimestamp
                })
                .map((item, idex) => (
                  <BoxDeliveryDateByHost
                    key={idex}
                    createdUserPhotoUrl={item.createdUserPhotoUrl}
                    createdUserName={item.createdUserName}
                    entityName={item.groupName || item.shopName}
                    deliveryTimeString={convertTimeStringWithDuration(
                      item.deliveryTime,
                      item.deliveryDuration
                    )}
                    deliveryTime={convertToCurrentGMT(item.deliveryTime)}
                    deliveryId={item.id}
                    deliveryShop={item?.shopId}
                  />
                ))
            : null}

          {sortListEventsByPickupTimestamp(combinedListMyEvents)
            ?.filter((item) => !isCloseEvent(item.pickupTimestamp))
            .map((event, index) => (
              <MyEventItem
                event={event}
                openModal={toggle}
                setShareLink={setShareLink}
                key={index}
              />
            ))}

          {listScheduleDelivery?.map((delivery, index) => (
            <MyScheduledDelivery
              key={index}
              delivery={delivery}
              hostId={userId}
              setTimeGetScheduleDelivery={setTimeGetScheduleDelivery}
            />
          ))}

          {allScheduleEvent?.map((event, index) => (
            <MyScheduledEvent
              event={event}
              openModal={toggle}
              setShareLink={setShareLink}
              isScheduledEvent={true}
              key={index}
              setTimeGetScheduleEvent={setTimeGetScheduleEvent}
            />
          ))}

          {listDeliveryCreated && listDeliveryCreated.length
            ? listDeliveryCreated
                .filter((ele) => ele.deliveryTimestamp < now)
                .map((item, idex) => (
                  <BoxDeliveryDateByHost
                    key={idex}
                    createdUserPhotoUrl={item.createdUserPhotoUrl}
                    createdUserName={item.createdUserName}
                    groupName={item.groupName}
                    deliveryTimeString={convertTimeStringWithDuration(
                      item.deliveryTime,
                      item.deliveryDuration
                    )}
                    deliveryTime={convertToCurrentGMT(item.deliveryTime)}
                    deliveryId={item.id}
                    greyOut={true}
                  />
                ))
            : null}

          {sortListEventsByPickupTimestamp(combinedListMyEvents)
            ?.filter((item) => isCloseEvent(item.pickupTimestamp))
            .map((event, index) => (
              <MyEventItem
                event={event}
                openModal={toggle}
                setShareLink={setShareLink}
                key={index}
              />
            ))}

          {combinedListMyEvents.length === 0 && !isLoading && (
            <div>You don&apos;t have any events!!!</div>
          )}

          <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='modal-header-created'>
              <span className='c-txt-gray'>Share Link</span>
            </ModalHeader>
            <ModalBody>
              <div className='c-form'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control--radius shareLink'
                    value={shareLink}
                    readOnly
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className='modal-footer-created'>
              <div className='col-12'>
                <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
                  {textBtn}
                </button>
              </div>
              <div className='col-12 mt-2'>
                <button className='btn btn--solid btn--solid04' onClick={toggle}>
                  CLOSE
                </button>
              </div>
            </ModalFooter>
          </Modal>

          {openModalAddDelivery && (
            <DeliveryOptionsModal
              modal={openModalAddDelivery}
              toggle={toggleModalAddDelivery}
              handleOnClickOk={handleOnClickOk}
              deliveryZones={deliveryZones}
              deliveryTime={deliveryTime}
              onChangeZoneDelivery={onChangeZoneDelivery}
              onChangeTimeDelivery={onChangeTimeDelivery}
              handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
              handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
              deliveryHourStart={deliveryHourStart}
              setDeliveryHourStart={setDeliveryHourStart}
              deliveryHourEnd={deliveryHourEnd}
              setDeliveryHourEnd={setDeliveryHourEnd}
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              optionsEntity={optionsEntity}
              isSubmitting={isSubmitting}
              errorMessageDeliveryZone={errorMessageDeliveryZone}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

export default MyEventList
