import axiosClient from './axiosClient'

const shopApi = {
  getListShopPaid: () => {
    const url = '/get-list-shop-paid'

    return axiosClient.get(url)
  },
  getListShopByHost: () => {
    const url = '/list-shops-by-host?getProducts=1'

    return axiosClient.get(url)
  },
  getShopById: (id) => {
    const url = `/get-shop-event-detail?id=${id}&getProducts=1`

    return axiosClient.get(url)
  },
}
export default shopApi
