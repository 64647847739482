/* eslint-disable max-len */
import deliveryApi from '_api/delivery'
import userApi from '_api/user'
import LoadingComponent from '_components/LoadingComponent'
import useQuery from '_hooks/useQuery'
import { deleteDelivery, getListDeliveryAndDeliveryBooking } from '_redux/modules/delivery'
import { DELIVERY_BUYER_CANCELLED, DELIVERY_HOST_CANCELLED, DESKTOP } from '_utils/constant'
import { convertTimeStringWithDuration } from '_utils/function'
import { getDeviceUser, getUserInfo } from '_utils/localData'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import DeliveryInfo from './DeliveryInfo'
import DeliveryList from './DeliveryList'
import NavBar from './NavBar'

export default function ManageDelivery() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const params = useQuery()
  const history = useHistory()

  const deliveryId = params.get('id')
  const deliveryShop = params.get('deliveryShop')

  const userInfo = getUserInfo()
  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }
  const { id: userId } = userInfo

  const { currentDeliveryAndDeliveryBooking, errorDeliveryAndDeliveryBooking, loadingDelivery } =
    useSelector((state) => state.delivery)

  const {
    deliveryBookings = [],
    shopEventDeliveryBookings = [],
    deliveryTime,
    deliveryDuration,
  } = currentDeliveryAndDeliveryBooking
  const combinedDeliveryBookings = [...deliveryBookings, ...shopEventDeliveryBookings]

  const [timeReRender, setTimeReRender] = useState(0)
  const [totalPurchase, setTotalPurchase] = useState(0)
  const [deliveryBookingCount, setDeliveryBookingCount] = useState(0)

  const isMounted = useRef(true)

  const initialDelivery = async () => {
    try {
      const isDesktop = getDeviceUser() === DESKTOP
      await dispatch(getListDeliveryAndDeliveryBooking(deliveryId, deliveryShop, isDesktop))
    } catch (error) {
      if (isMounted.current) {
        addToast('Failed to load delivery data', { appearance: 'error', autoDismiss: true })
      }
    }
  }

  useEffect(() => {
    if (deliveryId) {
      initialDelivery()
    }
    return () => {
      isMounted.current = false
    }
  }, [deliveryId, deliveryShop])

  useEffect(() => {
    let price = 0
    let count = 0
    if (combinedDeliveryBookings && combinedDeliveryBookings.length) {
      combinedDeliveryBookings.forEach((item) => {
        if (
          item.status !== DELIVERY_HOST_CANCELLED &&
          item.bookerStatus !== DELIVERY_BUYER_CANCELLED
        ) {
          price += item.dzPrice
          count += 1
        }
      })
    }
    setTotalPurchase(price)
    setDeliveryBookingCount(count)
  }, [combinedDeliveryBookings])

  useEffect(() => {
    const isDesktop = getDeviceUser() === DESKTOP
    dispatch(getListDeliveryAndDeliveryBooking(deliveryId, deliveryShop, isDesktop))
  }, [timeReRender])

  const handelBroadcast = (mess) => {
    if (combinedDeliveryBookings && combinedDeliveryBookings.length > 0) {
      const listDeliveryExcludeCancelled = combinedDeliveryBookings.filter(
        (item) =>
          item.status !== DELIVERY_HOST_CANCELLED && item.bookerStatus !== DELIVERY_BUYER_CANCELLED
      )
      try {
        listDeliveryExcludeCancelled.forEach(async (e) => {
          await userApi.createNotifications({
            receiverId: e.uid,
            title: `Broadcast Delivery date: ${convertTimeStringWithDuration(
              deliveryTime,
              deliveryDuration
            )}`,
            message: mess,
          })
        })
        addToast('Message sent!', { appearance: 'success', autoDismiss: true })
      } catch (error) {
        addToast(error, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const handleBackMangeOrder = (e) => {
    e.preventDefault()
    history.push('/event/list-my-events')
  }

  const onDeleteClicked = async () => {
    const confirmed = confirm('Are you sure?')
    if (!confirmed) return

    try {
      await deliveryApi.deleteDelivery(deliveryId, deliveryShop)
      dispatch(deleteDelivery(deliveryId, userId))

      const redirectPath =
        getDeviceUser() === DESKTOP ? '/event/delivery-list' : '/event/list-my-events'
      history.push(redirectPath)

      addToast('Delivery deleted!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <>
      {loadingDelivery ? <LoadingComponent className='mt-5' /> : null}
      {!loadingDelivery && errorDeliveryAndDeliveryBooking ? <div>No Delivery Found</div> : null}
      {!loadingDelivery && !errorDeliveryAndDeliveryBooking ? (
        <>
          <div className='row-top'>
            <DeliveryInfo
              deliveryTime={convertTimeStringWithDuration(deliveryTime, deliveryDuration)}
              deliveryBookingCount={deliveryBookingCount}
            />
            <NavBar
              handelBroadcast={handelBroadcast}
              deliveryInfo={currentDeliveryAndDeliveryBooking}
              deliveryShop={deliveryShop}
            />
            <div className='list-event-note'>
              Click on <strong>Names</strong> for Delivery Details and on{' '}
              <i className='icon-link'></i>
              for Payment Details.
            </div>
            {combinedDeliveryBookings && combinedDeliveryBookings.length > 0 ? (
              <DeliveryList
                listDelivery={combinedDeliveryBookings}
                setTimeReRender={setTimeReRender}
                deliveryTime={deliveryTime}
                deliveryId={deliveryId}
                deliveryShop={deliveryShop}
              />
            ) : null}
          </div>
          <div className='row-bot'>
            <div className='text-right price havebg'>
              <p>
                Total Purchase: $<span id='totalAmount'>{totalPurchase.toFixed(2)}</span>
              </p>
            </div>
            <div className='row align-items-center'>
              <div className='col-6 6 m-auto'>
                <a className='btn btn--solid btn--solid03' href='' onClick={handleBackMangeOrder}>
                  Exit
                </a>
              </div>
            </div>
          </div>
          <p className='link-share' style={{ cursor: 'pointer' }} onClick={onDeleteClicked}>
            DELETE
          </p>
        </>
      ) : null}
    </>
  )
}
