import React, { useEffect, useState } from 'react'
import { FaRegClock } from 'react-icons/fa'

export default function InputTime({ value, onChange, disableMinutes = false }) {
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [ampm, setAmpm] = useState('AM')

  useEffect(() => {
    if (value) {
      const [time, period] = value.split(' ')
      const [h, m] = time.split(':')

      setHours(h.padStart(2, '0'))
      setMinutes(m.padStart(2, '0'))
      setAmpm(period || (parseInt(h, 10) >= 12 ? 'PM' : 'AM'))
    }
  }, [value])

  const handleChange = (type, newValue) => {
    let updatedHours = hours
    let updatedMinutes = minutes
    let updatedAmpm = ampm

    if (type === 'hours') {
      updatedHours = newValue.padStart(2, '0')
      setHours(updatedHours)
      // Tự động cập nhật AM/PM dựa trên giờ
      updatedAmpm = parseInt(updatedHours, 10) >= 12 ? 'PM' : 'AM'
      setAmpm(updatedAmpm)
    } else if (type === 'minutes') {
      updatedMinutes = newValue.padStart(2, '0')
      setMinutes(updatedMinutes)
    } else if (type === 'ampm') {
      updatedAmpm = newValue
      setAmpm(updatedAmpm)
    }

    const newTime = `${updatedHours}:${updatedMinutes} ${updatedAmpm}`
    onChange(newTime)
  }

  const containerStyle = {
    display: 'flex',
    alignItems: 'end',
    border: '1px solid #ced4da',
    borderRadius: '5px',
    padding: '0.5rem',
    background: 'white',
    margin: '20px',
    justifyContent: 'center',
    color: '#495057',
    position: 'relative',
  }

  const selectStyle = {
    border: 'none',
    background: 'transparent',
    appearance: 'none',
    outline: 'none',
    fontSize: '20px',
    padding: '0 5px',
    color: '#495057',
    cursor: 'pointer',
  }

  const separatorStyle = {
    padding: '0 5px',
  }

  const iconStyle = {
    marginBottom: '3px',
    position: 'absolute',
    right: '5px',
  }

  return (
    <div style={containerStyle}>
      <select
        style={selectStyle}
        value={hours}
        onChange={(e) => handleChange('hours', e.target.value)}
      >
        {[...Array(12)].map((_, i) => (
          <option key={i} value={String(i + 1).padStart(2, '0')}>
            {String(i + 1).padStart(2, '0')}
          </option>
        ))}
      </select>
      {!disableMinutes && (
        <>
          <span style={separatorStyle}>:</span>
          <select
            style={selectStyle}
            value={minutes}
            onChange={(e) => handleChange('minutes', e.target.value)}
          >
            {[...Array(60)].map((_, i) => (
              <option key={i} value={String(i).padStart(2, '0')}>
                {String(i).padStart(2, '0')}
              </option>
            ))}
          </select>
        </>
      )}
      <select
        style={selectStyle}
        value={ampm}
        onChange={(e) => handleChange('ampm', e.target.value)}
      >
        <option value='AM'>AM</option>
        <option value='PM'>PM</option>
      </select>
      <FaRegClock style={iconStyle} color='#8f8c8d' />
    </div>
  )
}
