import PropTypes from 'prop-types'
import React from 'react'
import { FormGroup, Label } from 'reactstrap'

const InputField = (props) => {
  const { register, type, label, placeholder, disabled, className, ...rest } = props
  const { name } = register
  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}

      <input
        className={className}
        id={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        {...register}
        {...rest}
      />
    </FormGroup>
  )
}

export default InputField

InputField.propTypes = {
  register: PropTypes.object.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
