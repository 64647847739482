import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function ModalNotifyExpired({ isOpen, toggleModal, onRenewGroup, isProcessingRenew, ...args }) {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} {...args}>
      <ModalHeader toggle={toggleModal} className='pt-0'>
        Group Not Available For Subscription Plan
      </ModalHeader>
      <ModalBody className='pb-0'>
        <p>
          Currently this group is in <b>private</b> mode, please create a new payment to turn it
          back on. Thank you!
        </p>
        <button
          type='button'
          onClick={onRenewGroup}
          className='btn btn--main'
          disabled={isProcessingRenew}
        >
          {isProcessingRenew ? 'Processing...' : 'Renew'}
        </button>
      </ModalBody>
    </Modal>
  )
}

export default ModalNotifyExpired
