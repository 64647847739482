import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import { removeValuesFromArray } from '_utils/function'
import { PIN_MIN_VALUE, PIN_MAX_VALUE } from '_utils/constant'

const Password = ({ isFirstPin = false, pinLength, pin, onPinChanged }) => {
  const inputRefs = useRef([])

  const changePinFocus = (pinIndex) => {
    const ref = inputRefs.current[pinIndex]
    if (ref) {
      ref.focus()
    }
  }

  const onChange = (event, index) => {
    const previousValue = event.target.defaultValue
    const valuesArray = event.target.value.split('')
    removeValuesFromArray(valuesArray, previousValue)
    const value = valuesArray.pop()
    if (!value) {
      return
    }
    const pinNumber = Number(value.trim())
    if (isNaN(pinNumber) || value.length === 0) {
      return
    }

    if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
      onPinChanged(pinNumber, index)
      if (index < pinLength - 1) {
        changePinFocus(index + 1)
      }
    }
  }

  const onKeyDown = (event, index) => {
    const keyboardKeyCode = event.nativeEvent.code
    if (keyboardKeyCode !== 'Backspace') {
      return
    }
    onPinChanged(undefined, index)
    changePinFocus(index - 1)

    if ((pin && pin[index]) === undefined) {
      changePinFocus(index - 1)
    } else {
      onPinChanged(undefined, index)
    }
  }

  return (
    <div className={`box-pin ${isFirstPin && 'box-pin--first'}`}>
      {Array.from({ length: pinLength }, (_, index) => (
        <div className='box-pin__col password' key={index}>
          <input
            className='form-control'
            placeholder=''
            maxLength='1'
            type='number'
            id='digit1'
            onKeyDown={(event) => onKeyDown(event, index)}
            ref={(el) => {
              if (el) {
                inputRefs.current[index] = el
              }
            }}
            onChange={(event) => onChange(event, index)}
            value={(pin && pin[index] === 0 ? '0' : pin && pin[index]) || ''}
          />
        </div>
      ))}
    </div>
  )
}

export default Password

Password.propTypes = {
  isFirstPin: PropTypes.bool,
  onPinChanged: PropTypes.func,
  pin: PropTypes.any,
  pinLength: PropTypes.number.isRequired,
}
