import axiosClient from './axiosClient'

const packageApi = {
  getList: ({ packageType }) => {
    const url = '/list-co-bee-package'
    return axiosClient.get(url, {
      params: {
        packageType,
      },
    })
  },
}

export default packageApi
