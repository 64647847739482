import axiosClient from './axiosClient'

const uploadApi = {
  uploadPhoto: (files) => {
    const url = '/upload-photo'
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      formData.append('type', 1)
      formData.append('file', files[i])
    }
    return axiosClient.post(url, formData)
  },
}

export default uploadApi
