import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { getDateRemainFromNow, validateDate, convertToCurrentGMT } from '_utils/function'
import { removeCreateEventData, getUserInfo } from '_utils/localData'
import { EVENT_CLOSE } from '_utils/constant'

const GroupEventItem = ({ eventInfo }) => {
  const history = useHistory()

  const userInfo = getUserInfo()

  const eventClosed = eventInfo?.status === EVENT_CLOSE ||
    !validateDate(convertToCurrentGMT(eventInfo?.closingTime))

  const onClickToEditEvent = () => {
    removeCreateEventData()
    history.push(`/event/update?id=${eventInfo.id}`)
  }

  return (
    <div className='col-6'>
      <div className={`news-group__item ${eventClosed && 'is-closed'}`}>
        <div className='news-group__ttl'>
          <Link to={`/event/detail?eid=${eventInfo.id}`}>
            <h3>{eventInfo?.title}</h3>
          </Link>
          <p>{getDateRemainFromNow(convertToCurrentGMT(eventInfo?.closingTime))} left</p>
          <span className='txt-number txt-numbe--org'>{eventInfo?.totalOrderCount} BUY</span>
        </div>
        <div className='text-info'>
          <p>{eventInfo?.createdUserName}</p>
          <div className='admin-edit-button' style={{ display: 'block' }}>
            <br />
            {userInfo?.id === eventInfo?.createdUserId && (
              <span onClick={onClickToEditEvent} className='badge-light-link'>
                EDIT
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupEventItem
