import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalNotify = ({ modal, toggle }) => (
  <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Upgraded</ModalHeader>
    <ModalBody>
      <p className={'text-wrap text-center'}>
        We have improved Co-Bee to enable hosts to share their contact information, allowing anyone
        within the Co-Bee community who wishes to join the group to reach out to you directly.
      </p>
      <p className={'text-wrap text-center'}>
        Please provide your contact information in the text box provided. If you do not want to be
        contacted, please put “NA” in text.
      </p>
    </ModalBody>
    <ModalFooter>
      <button className='btn btn--main' onClick={toggle}>
        Close
      </button>
    </ModalFooter>
  </Modal>
)

export default ModalNotify
