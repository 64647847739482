import React from 'react'
import { FaTruck } from 'react-icons/fa'

export default function DeliveryInfo({ deliveryTime, deliveryBookingCount }) {
  return (
    <div className='group-list group-list--event'>
      <div className='group-list__item group-list__item--nobackground  align-items-center'>
        <div className='group-list__info'>
          <span className='delivery-info'>
            <div style={{ display: 'inline-block' }}>Delivery</div>
            <div style={{ display: 'inline-block', fontSize: '1rem', marginLeft: '0.2rem' }}><FaTruck /></div>
          </span>
          <p style={{ lineHeight: 1, fontSize: '0.7rem' }}>
            {deliveryTime}
          </p>
        </div>
        <div className='group-list__info text-right'>
          <span className='txt-number'>
            <span id='totalBuy'>{deliveryBookingCount}</span> BUY
          </span>
        </div>
      </div>
    </div>
  )
}
