import axiosClient from './axiosClient'

const serviceApi = {
  updatePaymentScheduleEvent: (id, data) => {
    const url = `/update-payment-schedule-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  updatePaymentSubdomain: (id, data) => {
    const url = `/update-payment-domain-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  updatePaymentPremium: (id, data) => {
    const url = `/update-payment-premium-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  purchasePaymentScheduleEvent: (userId, data) => {
    const url = `/create-schedule-feature?userId=${userId}`
    return axiosClient.post(url, data)
  },
  purchasePaymentPremium: (userId, data) => {
    const url = `/create-premium-feature?userId=${userId}`
    return axiosClient.post(url, data)
  },
  searchDomainName: (groupId, data) => {
    const url = `/search-domain?groupId=${groupId}`
    return axiosClient.post(url, data)
  },
  purchasePaymentSubdomain: (groupId, data) => {
    const url = `/create-domain?groupId=${groupId}`
    return axiosClient.post(url, data)
  },
  getGroupIdByDomainName: (domainName, domainStatus) => {
    const url = `/get-group-id-by-domain?domainName=${domainName}&domainStatus=${domainStatus}`
    return axiosClient.get(url)
  }
}

export default serviceApi
