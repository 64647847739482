import React from 'react'

export default function ButtonDayCollection({ isDisable }) {
  return (
    <div>
      <div
        style={isDisable ? { backgroundColor: 'gray' } : {}}
        className='btn-purchase btn-purchase-03'
      >
        <span>Payment Confirmed</span>
      </div>
    </div>
  )
}
