import { DeviceUserContext } from '_context/DeviceUserContext'
import { PHONE } from '_utils/constant'
import { setDeviceUser } from '_utils/localData'
import { useContext, useEffect } from 'react'

function SwitchMobileModel() {
  const { onSwitchDevice } = useContext(DeviceUserContext)
  useEffect(() => {
    setDeviceUser(PHONE)
    onSwitchDevice(PHONE)
    return () => {
      setDeviceUser()
      // setCheckDeviceUser()
    }
  }, [])

  return <></>
}

export default SwitchMobileModel
