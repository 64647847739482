import axiosClient from './axiosClient'

const reportApi = {
  report: ({ type = 'error-report', message = '' }) => {
    const url = '/create-feedback'
    return axiosClient.post(url, {
      type,
      message: `${message}`
    })
  },
}

export default reportApi
