import packageApi from '_api/package'

const initialState = {
  listPackage: [],
}

const GET_LIST_PACKAGE = 'GET_LIST_PACKAGE'

export const getListPackage =
  ({ packageType }) =>
  async (dispatch) => {
    try {
      const { msgResp } = await packageApi.getList({ packageType })
      dispatch({
        type: GET_LIST_PACKAGE,
        payload: { listPackage: msgResp },
      })
    } catch (error) {
      throw new Error(error?.msgResp || error.message)
    }
  }

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PACKAGE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default packageReducer
