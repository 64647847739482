import PropTypes from 'prop-types'
import React from 'react'
import { FaTruck } from 'react-icons/fa'

export default function BoxAddDeliveryByBuyer(props) {
  const {
    createdUserPhotoUrl,
    createdUserName,
    groupName,
    addDelivery,
    hostId,
    groupId,
    shopId,
    shopName,
    hideHeader,
  } = props

  const styleBackground = {
    backgroundColor: groupId ? '#B8F0EF' : '#E67E224F',
  }

  return (
    <>
      {!hideHeader && (
        <p className='mb-2' style={{ fontSize: 16, fontWeight: 600 }}>
          {groupId ? 'Delivery Group' : 'Delivery Shop'}
        </p>
      )}
      <div className='group-list__item' style={styleBackground}>
        <div className='group-list__img'>
          <div className='c-image'>
            <a href=''>
              <img
                src={createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName}
                alt=''
              />
            </a>
          </div>
        </div>
        <div className='group-list__info'>
          <h3>
            <a href=''>{createdUserName}</a>
          </h3>
          <p>{groupName || shopName}</p>
        </div>
        <div
          className='text-right d-flex align-items-center text-add-delivery'
          onClick={() => addDelivery(hostId, groupId || shopId)}
        >
          <span>
            Add delivery <FaTruck />
          </span>
        </div>
      </div>
    </>
  )
}

BoxAddDeliveryByBuyer.propTypes = {
  addDelivery: PropTypes.func,
  createdUserPhotoUrl: PropTypes.string,
  createdUserName: PropTypes.string,
  groupName: PropTypes.string,
}
