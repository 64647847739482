/* eslint-disable max-len */
import axiosClient from './axiosClient'

const dashBoardApi = {
  getListTotalSales: (data) => {
    const url = `/list-total-sales-per-day-by-host?startDate=${data.startDate}&endDate=${data.endDate}&groupId=${data.groupId}`
    return axiosClient.get(url)
  },
  getListTotalOrders: (data) => {
    const url = `/list-total-orders-per-day-by-host?startDate=${data.startDate}&endDate=${data.endDate}&groupId=${data.groupId}`
    return axiosClient.get(url)
  },
  getListTotalAverageOrder: (data) => {
    const url = `/list-average-order-size-per-day-by-host?startDate=${data.startDate}&endDate=${data.endDate}&groupId=${data.groupId}`
    return axiosClient.get(url)
  },
  getTopProductByHost: (data) => {
    const url = `/get-top-product-by-host?startDate=${data.startDate}&endDate=${data.endDate}&groupId=${data.groupId}`
    return axiosClient.get(url)
  },
  getSummary: (data) => {
    const url = `/get-summary-and-top-report?startDate=${data.startDate}&endDate=${data.endDate}&groupId=${data.groupId}`
    return axiosClient.get(url)
  },
}

export default dashBoardApi
