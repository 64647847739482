import React from 'react'
import './styles.scss'

function FallBack() {
    return (
        <p className='mainText'>Something went wrong!</p>
    )
}

export default FallBack
