/* eslint-disable array-callback-return */
import LoadingComponent from '_components/LoadingComponent'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import GroupMember from '_pages/Group/components/GroupMember'
import {
  approveJoinGroup,
  getGroupByIdWithMembers,
  getMyGroups,
  removeMember,
} from '_redux/modules/group'
import { getUserInfo } from '_utils/localData'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ModalApprove from '../Components/ModalApprove'

function MemberList() {
  const { infoGroupSelected } = useContext(SelectIdGroupContext)
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { myGroups } = useSelector((state) => state.group)
  const userInfo = getUserInfo()

  const [loading, setLoading] = useState(false)
  const [memberSelected, setMemberSelected] = useState(null)
  const [modal, setModal] = useState(false)
  const [sorting, setSorting] = useState({ field: 'createdAt', order: 'desc', type: 'number' })
  const [groupInfo, setGroupInfo] = useState({})
  const [searchString, setSearchString] = useState('')

  const toggle = () => setModal((prev) => !prev)

  const headers = [
    { name: 'Join date', field: 'createdAt', type: 'number', className: 'col-3', sortable: true },
    { name: 'Name', field: 'uName', className: 'col-3', type: 'string', sortable: true },
    { name: 'Events', field: 'totalEvents', type: 'number', className: 'col-2', sortable: true },
    { name: 'Purchase', field: 'orderCount', type: 'number', className: 'col-2', sortable: true },
    { name: 'Permission', field: 'role', type: 'string', className: 'col-2', sortable: true },
  ]

  const isAdminOfGroup = groupInfo?.members?.some((x) => x.uid === userInfo.id && x.isAdmin)

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      setLoading(true)
      try {
        if (!myGroups) {
          await dispatch(getMyGroups)
        }

        if (infoGroupSelected?.groupId) {
          await dispatch(getGroupByIdWithMembers({ id: infoGroupSelected.groupId }))
        }
      } catch (error) {
        if (isMounted) {
          addToast(`Failed to fetch data: ${error.message}`, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [dispatch, infoGroupSelected?.groupId, myGroups])

  useEffect(() => {
    if (myGroups && infoGroupSelected?.groupId) {
      const selectedGroup = myGroups.find(
        (group) => group.gid === infoGroupSelected.groupId || group.id === infoGroupSelected.groupId
      )
      setGroupInfo(selectedGroup || null)
    }
  }, [myGroups, infoGroupSelected?.groupId])

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  const onClickPending = (member) => {
    toggle()
    setMemberSelected(member)
  }

  const onReject = async () => {
    try {
      const postData = {
        groupId: memberSelected.gid,
        userId: memberSelected.uid,
      }
      dispatch(removeMember(postData))
      addToast(`${memberSelected?.name} was rejected `, {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error, { appearance: 'error', autoDismiss: true })
    }
    toggle()
  }

  const onAccept = async () => {
    try {
      const postData = {
        groupId: memberSelected.gid,
        userId: memberSelected.uid,
      }
      dispatch(approveJoinGroup(postData))
      addToast(`${memberSelected?.name} was accepted `, {
        appearance: 'info',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error, { appearance: 'error', autoDismiss: true })
    }
    toggle()
  }

  const listMembers = useMemo(() => {
    if (groupInfo && groupInfo.members) {
      let sortListMembers = groupInfo.members

      sortListMembers = sortListMembers.map((item) => {
        if (item.approval === 1) {
          return { ...item, role: 'PENDING' }
        }
        return { ...item, role: item?.isAdmin ? 'ADMIN' : item?.isHost ? 'HOST' : 'MEMBER' }
      })

      sortListMembers = sortListMembers.map((item) => ({
        ...item,
        totalEvents: item.totalEvents ? item.totalEvents : 0,
      }))

      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          sortListMembers = sortListMembers.sort(
            (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
          )
        } else {
          sortListMembers = sortListMembers.sort(
            (a, b) => reversed * (a[sorting.field] - b[sorting.field])
          )
        }
      }

      if (searchString) {
        sortListMembers = sortListMembers.filter((val) => {
          if (val.uName.toLowerCase().includes(searchString.toLowerCase())) {
            return val
          }
        })
      }
      sortListMembers = sortListMembers.map((item, index = 0) => ({ ...item, no: index + 1 }))
      return sortListMembers
    }
  }, [sorting, groupInfo?.members, searchString])

  return (
    <div className='size-width-desktop-col-1'>
      <div className='list-user list-user--havelink mx-0 rounded-3 shadow-sm px-3 mb-3 mt-0'>
        <h3 className='c-txt-14 text-center c-ttl-blue text-underline'>Members</h3>
        <div className='search'>
          <input
            className='search-input form-control'
            type='text'
            placeholder='Search by name'
            value={searchString}
            onChange={onChangeSearch}
          />
        </div>

        <div>
          <div className='list-user__item'>
            <TableHeaderWithSort
              headers={headers}
              onSorting={(field, order, type) => setSorting({ field, order, type })}
              defaultSortingField='createdAt'
              defaultSortingOrder='desc'
              className='row mt-2 font-weight-bold'
            />

            {loading && <LoadingComponent className='my-5' />}

            {!loading && listMembers && listMembers.length === 0 && (
              <p className='text-center pt-5'>No members</p>
            )}

            {!loading && listMembers
              ? listMembers.map((item, index) => (
                  <GroupMember
                    userInfo={item}
                    key={index}
                    role={isAdminOfGroup ? 'admin' : 'member'}
                    onClickPending={() => onClickPending(item)}
                    to={`/members/member-detail?gid=${item?.gid}&uid=${item?.uid}`}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
      <ModalApprove
        modal={modal}
        toggle={toggle}
        memberSelected={memberSelected}
        onReject={onReject}
        onAccept={onAccept}
      />
    </div>
  )
}

export default MemberList
