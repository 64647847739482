import { LIMIT_TEXT } from '_utils/constant'
import { convertToCurrentGMT, shortDescription } from '_utils/function'
import { truncate } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'reactstrap'
import HeaderEventInfo from '../components/HeaderEventInfo'
import EventTag from './EventTag'

export default function EventItem({ event }) {
  const history = useHistory()
  const [isShowMore, setIsShowMore] = useState(false)
  const [isShowTooltip, setIsShowTooltip] = useState(false)

  const toggleShowTooltip = () => {
    if (event?.title?.length > 100) {
      setIsShowTooltip(!isShowTooltip)
    }
  }

  const showMore = () => {
    setIsShowMore(true)
  }
  const listImages = event?.photoUrls?.map((item) => ({ original: item })) || []

  const directToEventDetail = (selectedEvent) => {
    if (selectedEvent?.shopId) {
      history.push(`/event/detail?eid=${selectedEvent.id}&eventShop=true`)
    } else {
      history.push(`/event/detail?eid=${selectedEvent.id}`)
    }
  }

  return (
    <div className='group-list__item position-relative'>
      <EventTag
        isShop={!!event?.shopId}
        size={20}
        className='w-100 text-end mb-3 gap-2 justify-content-end'
        color={'#51aaaa'}
        style={{ fontSize: 14 }}
      />
      <HeaderEventInfo
        createdUserPhotoUrl={event?.createdUserPhotoUrl}
        createdUserName={event?.createdUserName}
        eClosingTime={convertToCurrentGMT(event?.closingTime)}
        eTotalOrderCount={event?.totalOrderCount}
        groupName={event?.groupName}
        shopName={event?.shopId ? event?.shopName : null}
      />
      <div className='group-list__des'>
        {listImages?.length > 0 && (
          <ReactImageGallery
            items={listImages}
            lazyLoad
            autoPlay
            showPlayButton={false}
            showNav={false}
            showBullets={true}
            showThumbnails={false}
            onClick={() => directToEventDetail(event)}
          />
        )}

        <h4 id={`event-${event?.id}`}>
          <button
            className={'bg-transparent border-0 text-blue fw-semibold text-start'}
            onClick={() => directToEventDetail(event)}
          >
            {truncate(event?.title, { length: 100 })}
          </button>
        </h4>
        <Tooltip
          isOpen={isShowTooltip}
          toggle={toggleShowTooltip}
          autohide={false}
          target={`event-${event?.id}`}
          className='tooltip-event-item'
        >
          {event?.title}
        </Tooltip>
        <p className='paragraph-pre-wrap'>
          <button className={'bg-transparent border-0'} onClick={() => directToEventDetail(event)}>
            {isShowMore
              ? event?.description
              : shortDescription({ description: event?.description })}
          </button>
          {!isShowMore &&
          event?.description &&
          event?.description.split(' ').length > LIMIT_TEXT ? (
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }}
              onClick={showMore}
            >
              More
            </span>
          ) : null}
        </p>
      </div>
    </div>
  )
}

EventItem.propTypes = {
  event: PropTypes.shape({
    createdUserId: PropTypes.string,
    groupId: PropTypes.string,
  }),
}
