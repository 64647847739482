/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Pagination from '../components/Pagination'
import InvoiceItem from './InvoiceItem'
import ModalDetail from './ModalDetail'

// const FilterOption = ({ filterServiceType, setFilterServiceType }) => {
//   const serviceType = [
//     { label: 'Group', packageType: PACKAGE_TYPE_COBEE },
//     {
//       label: 'Schedule Event',
//       packageType: PACKAGE_TYPE_SCHEDULE_EVENT,
//     },
//     {
//       label: 'Co-Bee Desktop',
//       packageType: PACKAGE_TYPE_COBEE_DESKTOP,
//     },
//   ]

//   return (
//     <div className='select-filter'>
//       <select
//         defaultValue={filterServiceType}
//         className='form-select-sm border shadow-sm rounded pe-1'
//         onChange={(e) => setFilterServiceType(e.target.value)}
//       >
//         <option value=''>All</option>
//         {serviceType.map((item, index) => (
//           <option key={index} value={JSON.stringify(item)}>
//             {item.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   )
// }

export default function Invoices() {
  // const dispatch = useDispatch()

  // const [filterServiceType, setFilterServiceType] = useState('')
  const [selectedInvoice, setSelectedInvoice] = useState({})
  const [modalSeeDetail, setModalSeeDetail] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [invoicesPerPage] = useState(10)
  const { listInvoiceGroup, listInvoiceScheduleEvent, listInvoicePremiumAccount } = useSelector(
    (state) => state.invoice
  )

  const combinedListInvoiceGroup = [
    ...listInvoiceGroup,
    ...listInvoiceScheduleEvent,
    ...listInvoicePremiumAccount,
  ]

  const indexOfLastInvoice = currentPage * invoicesPerPage
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage

  useEffect(() => {
    if (!modalSeeDetail) setSelectedInvoice({})
  }, [modalSeeDetail])

  const pageNumbers = useMemo(() => {
    const totalInvoices = combinedListInvoiceGroup?.length || 0
    return Array.from({ length: Math.ceil(totalInvoices / invoicesPerPage) }, (_, i) => i + 1)
  }, [combinedListInvoiceGroup?.length, invoicesPerPage])

  const currentInvoices = useMemo(
    () =>
      combinedListInvoiceGroup
        .sort((a, b) => b.createdAt - a.createdAt)
        .slice(indexOfFirstInvoice, indexOfLastInvoice),
    [combinedListInvoiceGroup, indexOfFirstInvoice, indexOfLastInvoice]
  )

  useEffect(() => {
    if (currentPage > pageNumbers.length) {
      setCurrentPage(1)
    }
  }, [currentPage, pageNumbers.length])

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageNumbers.length))
  }

  const toggleModalShowDetail = () => setModalSeeDetail(!modalSeeDetail)

  const onShowDetail = (invoice) => {
    toggleModalShowDetail()
    setSelectedInvoice(invoice)
  }

  return (
    <>
      {!currentInvoices ? <div>No invoice</div> : null}
      {currentInvoices ? (
        <div className='user-invoice'>
          <div className='box-filter mb-3 justify-content-between flex-row-reverse'>
            <Pagination
              currentInvoices={currentInvoices}
              currentPage={currentPage}
              onHandlePrev={handlePrev}
              onHandleNext={handleNext}
            />
          </div>
          <div className='invoice-list'>
            {currentInvoices.length ? (
              currentInvoices?.map((item) => (
                <InvoiceItem key={item.id} item={item} onShowDetail={onShowDetail} />
              ))
            ) : (
              <div className='invoice-item justify-content-between row text-center align-items-center fw-bold'>
                <p className='mb-0'>Not Found Data</p>
              </div>
            )}
          </div>
        </div>
      ) : null}

      <ModalDetail
        isOpen={modalSeeDetail}
        toggleModalShowDetail={toggleModalShowDetail}
        selectedInvoice={selectedInvoice}
      />
    </>
  )
}
