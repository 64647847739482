import { SelectIdGroupContext } from '_context/SelectGroupContext'
import { DESKTOP, PHONE } from '_utils/constant'
import {
  getCreateEventData,
  getDeviceUser,
  getUserInfo,
  removeCreateEventData,
  setDeviceUser,
} from '_utils/localData'
import React, { useContext, useEffect, useState } from 'react'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Link, useHistory, withRouter } from 'react-router-dom'

import './style.scss'

const Footer = ({ location }) => {
  const history = useHistory()
  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const { eventQuantity } = useContext(SelectIdGroupContext)
  const currentUser = getUserInfo()
  const deviceUser = getDeviceUser()

  const isAllowHost =
    currentUser?.groups?.length && currentUser?.groups?.some((g) => g?.isHost || g?.isAdmin)

  const checkHighLight = () => {
    const paths = [
      'create',
      'create-items',
      'created-confirm',
      'update',
      'update-items',
      'update-confirm',
    ]
    if (pathname[1] === 'event' && paths.includes(pathname[2].split('?')[0])) {
      return true
    }
    return false
  }

  const handleOnClickToCreateEvent = () => {
    const eventData = getCreateEventData()
    if (eventData?.id) {
      removeCreateEventData()
    }
    history.push('/event/create')
  }

  const handleSwitchToMobile = () => {
    if (deviceUser === DESKTOP) {
      setDeviceUser(PHONE)
      window.location.href = '/'
    }
  }

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])

  const positionSticky = {
    position: 'sticky',
    flex: '0 0 auto',
  }

  return (
    <>
      {isAllowHost && (
        <footer className={'footer-admin-desktop mt-auto'} style={positionSticky}>
          <div className='container-fluid footer-admin-desktop-box'>
            <ul className='menu-desktop footer-admin w-100'>
              <li className='border-end cursor-pointer'>
                <a
                  className={checkHighLight() ? 'active' : ''}
                  onClick={handleOnClickToCreateEvent}
                >
                  <i className='icon-host'></i>
                  <span>Host</span>
                </a>
              </li>
              <li className='border-start border-end cursor-pointer'>
                <a className={''} onClick={handleSwitchToMobile}>
                  <RiArrowGoBackLine size={28} />
                  <span>Switch View</span>
                </a>
              </li>
              <li className='border-start'>
                <Link
                  to='/event/list-hosted-by-group'
                  className={
                    pathname[1] === 'event' && pathname[2] === 'list-hosted-by-group'
                      ? 'active'
                      : ''
                  }
                >
                  <i className='icon-host-event'></i>
                  <span>Hosted Event {eventQuantity ? <span>({eventQuantity})</span> : ''}</span>
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      )}
    </>
  )
}

export default withRouter(Footer)
