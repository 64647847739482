import React, { useEffect, useState, memo } from 'react'
import { Bar } from 'react-chartjs-2'

function OrderChart({ data }) {
  const [date, setDate] = useState()
  const [value, setValue] = useState()

  useEffect(() => {
    if (data?.length > 0) {
      if (data[0].typeShowData === 'weeks') {
        const listDate = data?.map((element) => {
          const getDate = element.date.slice(0, 5)
          return `${getDate}`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else if (data[0].typeShowData === 'months') {
        const listDate = data?.map((element) => {
          const getMonth = element.monthInhYear.slice(0, 2)
          return `${getMonth} M`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else {
        const listDate = data?.map((element) => {
          const getYear = element.year
          return `${getYear} Y`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      }
    }
  }, [data])

  return (
    <div>
      <Bar
        data={{
          labels: date,
          datasets: [
            {
              label: 'TOTAL ORDERS',
              backgroundColor: ['#FFDC88'],
              data: value,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          title: {
            display: true,
            text: 'Predicted world population (millions) in 2050',
          },
        }}
      />
    </div>
  )
}

export default memo(OrderChart)
