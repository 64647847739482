import React from 'react'
import InputField from '_components/Input'

const GroupContact = ({ register, error, contactValue }) => (
  <React.Fragment>
    <p className='c-txt-blue c-txt-14 mb-0 mt-3'>
      If someone wants to join the group, they can contact me.
    </p>
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <small>Put "NA" If you do not want to be contacted</small>
    <div className='form-group'>
      <InputField
        className='form-control'
        id='contact'
        name='contact'
        type='text'
        defaultValue={contactValue || ''}
        placeholder='Whatsapp No / E-mail / Social Media Link'
        register={register}
      />
      <small className='errorMessage'>{error?.message}</small>
    </div>
  </React.Fragment>
)

export default GroupContact
