/* eslint-disable no-cond-assign */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react'
import MyGallery from './gallery'

const ProductConfirm = (props) => {
  const { product, register, setValue, productIndex } = props
  const handleOnChangeDefaultQuantity = (type = 'add') => {
    if (type === 'add') {
      if (parseInt(product.defaultQuantity + 1, 10) <= product.limitPerOrder) {
        setValue(
          `products.${productIndex}.defaultQuantity`,
          parseInt((product.defaultQuantity += 1), 10)
        )
      }
    } else if (type === 'sub') {
      if (parseInt(product.defaultQuantity - 1, 10) >= 0) {
        setValue(
          `products.${productIndex}.defaultQuantity`,
          parseInt((product.defaultQuantity -= 1), 10)
        )
      }
    }
  }

  const getImage = () => {
    if (product?.photoUrls && typeof product?.photoUrls === 'string') {
      return product?.photoUrls
    }

    if (Array.isArray(product?.photoUrls)) {
      return product?.photoUrls[0]
    }

    return ''
  }

  return (
    <div className='detail-list-news__item'>
      <div className='row'>
        <div className='col-3'>
          <MyGallery image={getImage()} />
        </div>
        <div className='col-6'>
          <h3>{product?.name}</h3>
          <p className='mb-0'>${product?.price}</p>
          <div style={{ marginLeft: '25px' }}>
            {product?.subItems?.map((subItem, i) => (
              <div className='mb-1' key={i}>
                {subItem?.type} :
                {subItem?.list?.map((option, k) => (
                  <p className='mb-0 ml-3' key={k}>
                    {option?.name}: +${option?.price}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className='col-3'>
          <div className='quanlity'>
            <span className='icon-mim' onClick={() => handleOnChangeDefaultQuantity('sub')}>
              -
            </span>
            <span className='icon-input'>
              <input
                type='number'
                step='1'
                min='0'
                max={product.limitPerOrder}
                className='form-control'
                {...register}
              />
            </span>
            <span className='icon-plugs' onClick={() => handleOnChangeDefaultQuantity('add')}>
              +
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductConfirm
