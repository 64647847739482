import React from 'react'
import PropTypes from 'prop-types'

import InputField from '_components/Input'

const GroupQuestion = ({ register, error }) => (
  <React.Fragment>
    <p className='c-txt-blue c-txt-14'>Question before joining group</p>
    <div className='form-group'>
      <InputField
        className='form-control'
        id='groupQuestion'
        name='groupQuestion'
        type='text'
        placeholder='Question?'
        register={register}
      />
      <small className='errorMessage'>{error?.message}</small>
    </div>
  </React.Fragment>
)

GroupQuestion.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  register: PropTypes.object,
}

export default GroupQuestion
