import { useEffect } from 'react'
import { setDebugMode, setDebugScope } from '_utils/localData'
import { db } from '../firebase/config'

const useData = () => {
  useEffect(() => {
    const subscriber = db
      .collection('ClientConfig')
      // .orderBy('createdAt', 'desc')
      // .limit(1)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // const firebase = getDataFirebase()
          // const newFirebase = doc.data()
          setDebugMode(doc.data().debugMode)
          setDebugScope(doc.data().debugScope)
          // if (!_.isEqual(firebase, newFirebase)) {
          //   setDataFirebase(newFirebase)
          //   const { action, collection, message } = newFirebase
          //   const { id: itemId } = message
          //   if (action === 'create') {
          //     removeListGroup()
          //     // dispatch(getGroups)
          //     const data = localStorage.getItem(collection) || []
          //     const findItem = data.find((item) => item.id === itemId)
          //     if (!findItem) {
          //       // Implement localStorage
          //       //  localStorage.setItem(collection, JSON.stringify(value))
          //     }
          //   }
          // }
        })
      })

    return () => {
      subscriber()
    }
  }, [])
}

export default useData
