import React from 'react'

export default function ButtonCollectionTomorrow({ collectionTimeStart,
  collectionTimeEnd }) {
  return (
    <div>
      <a href="" className="btn-purchase btn-purchase-03">
        <span>{`Collection tomorrow ${collectionTimeStart && collectionTimeEnd ? `(${collectionTimeStart} - ${collectionTimeEnd})` : ''}`}</span>
      </a>
    </div>
  )
}
