import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'

export default function ModalConfirm(props) {
  const {
    modal,
    toggleModal,
    handleConfirm,
    title = ''
  } = props

  return (
    <Modal isOpen={modal} toggle={toggleModal} className='modal-cancel' centered>
      <ModalHeader toggle={toggleModal} className='pt-1 pb-1 text-left'>
        Cancel this {title}
      </ModalHeader>

      <ModalBody>
        Do you want to cancel this {title} ?
      </ModalBody>

      <ModalFooter>
        <button type="button" className="btn-no btn-secondary" onClick={toggleModal}>No</button>
        <button type="button" className="btn-yes btn-primary" onClick={handleConfirm}>Yes</button>
      </ModalFooter>
    </Modal>
  )
}

ModalConfirm.propTypes = {
  modal: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleConfirm: PropTypes.func
}
