import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import groupIcon from '_images/group-icon.png'

const ShareLinkModal = ({ modal, toggle }) => {
  const { newGroup } = useSelector((state) => state.group)

  const [textBtn, setTextBtn] = useState('COPY')
  const shareLink = `${process.env.REACT_APP_APP_URL}/group/join/${newGroup?.id}`

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader className='modal-header-created'>
        <span className='c-txt-gray'>Share Link</span>
      </ModalHeader>
      <ModalBody>
        <div className='c-form'>
          <div className='form-group'>
            <input
              readOnly
              type='text'
              className='form-control form-control--radius shareLink'
              value={shareLink}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='modal-footer-created'>
        <div className='col-12'>
          <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
            {textBtn}
          </button>
        </div>
        <div className='col-12 mt-2'>
          <button className='btn btn--solid btn--solid04' onClick={toggle}>
            CLOSE
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

const CreatedModal = () => {
  const { newGroup } = useSelector((state) => state.group)

  const [hidden, setHidden] = useState(true)
  const [showShareLinkModal, setShowShareLinkModal] = useState(false)

  const toggleCreatedModal = () => setHidden(!hidden)
  const toggleShareLinkModal = () => setShowShareLinkModal(!showShareLinkModal)

  useEffect(() => {
    setHidden(!newGroup)
  }, [newGroup])

  if (!newGroup) return null
  return (
    <React.Fragment>
      <Modal isOpen={!hidden} toggle={toggleCreatedModal}>
        <ModalBody>
          <div className='welcome-group'>
            <div className='welcome-group__img'>
              <div className='c-image'>
                <img src={newGroup?.photoUrl?.url || newGroup?.photoUrl || groupIcon} alt='' />
              </div>
            </div>
            <h4>{newGroup.name || 'Trilliant Group'}</h4>
            <p>
              Congrats! You have created a new group. Do you want to share this to your social
              circle?
            </p>
            <button
              className='link-circle'
              style={{ color: 'grey', border: 'none' }}
              onClick={() => {
                setShowShareLinkModal(true)
              }}
            >
              SHARE
            </button>
            <div className='row justify-content-center'>
              <div className='col-8'>
                <Link
                to={`/group/list-members?groupId=${newGroup.id}`}
                  className='btn btn--org btn--org02'
                >
                  DONE
                </Link>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ShareLinkModal modal={showShareLinkModal} toggle={toggleShareLinkModal} />
    </React.Fragment>
  )
}

export default CreatedModal
