import deliveryApi from '_api/delivery'
import { clearDeliveryByBuyer, clearListDeliveryAndDeliveryBooking } from '_redux/modules/delivery'
import { getRememberedDeliveryAddress, updateRememberedDeliveryAddress } from '_redux/modules/user'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody } from 'reactstrap'
import DeliveryForm from './DeliveryForm'

export default function ModalDelivery(props) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()

  const { deliveryInfo, isShowModal, toggle } = props

  const { addressDeliveryBooking } = useSelector((state) => state.user)

  const { deliveryZones, listDeliveryCreated, eCreatedUserName, eCreatedUserPhotoUrl } =
    deliveryInfo

  const [location, setLocation] = useState(deliveryZones?.[0]?.name)
  const [deliveryTime, setDeliveryTime] = useState(listDeliveryCreated?.[0]?.id)
  const [address, setAddress] = useState('')
  const [comment, setComment] = useState('')
  const [errorMessage, setErrorMessage] = useState({ deliveryZone: '', deliveryAddress: '' })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    dispatch(getRememberedDeliveryAddress())

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setAddress(addressDeliveryBooking)
  }, [addressDeliveryBooking])

  useEffect(() => {
    setLocation(deliveryZones?.[0]?.name)
    setDeliveryTime(listDeliveryCreated?.[0]?.id)
  }, [deliveryZones, listDeliveryCreated])

  const onChangeDZone = (e) => {
    setLocation(e.target.value)
  }

  const onChangeDTime = (e) => {
    setDeliveryTime(e.target.value)
  }

  const onChangeDAddress = (e) => {
    setAddress(e.target.value)
    setErrorMessage({ ...errorMessage, deliveryAddress: '' })
  }
  const onChangeDComment = (e) => {
    setComment(e.target.value)
  }

  const onSubmit = async () => {
    if (!address) {
      setErrorMessage((prev) => ({ ...prev, deliveryAddress: 'Delivery address cannot be blank' }))
      return
    }

    const deliveryZoneSelected = deliveryZones.find((item) => item.name === location)

    setIsSubmitting(true)

    try {
      if (address !== addressDeliveryBooking) {
        const dynamicKey =
          deliveryZoneSelected.type === 'shop'
            ? 'shopEventRememberedDeliveryAddress'
            : 'rememberedDeliveryAddress'
        const updateData = { [dynamicKey]: address }

        await deliveryApi.updateRememberedDeliveryAddress(updateData)
        dispatch(updateRememberedDeliveryAddress(address))
      }

      const payload = {
        deliveryId: deliveryTime,
        deliveryZone: JSON.parse(JSON.stringify(deliveryZoneSelected)),
        address,
        comment,
      }

      const { msgResp } = await deliveryApi.createDeliveryBooking(payload)

      if (isMounted.current) {
        addToast('Booking delivery successfully.', { appearance: 'success', autoDismiss: true })
        toggle()
        dispatch(clearDeliveryByBuyer())
        dispatch(clearListDeliveryAndDeliveryBooking())

        let url = `/event/delivery-detail?ref=${msgResp.ref}`

        if (deliveryZoneSelected.type === 'shop') {
          url += '&deliveryShop=true'
        }

        history.push(url)
      }
    } catch (e) {
      if (isMounted.current) {
        addToast(e.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } finally {
      if (isMounted.current) {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Modal isOpen={isShowModal} centered toggle={toggle}>
      <ModalBody>
        <DeliveryForm
          dZone={location}
          deliveryTime={deliveryTime}
          dAddress={address}
          dComment={comment}
          onChangeDZone={onChangeDZone}
          onChangeDTime={onChangeDTime}
          onChangeDAddress={onChangeDAddress}
          onChangeDComment={onChangeDComment}
          onSubmit={onSubmit}
          deliveryZones={deliveryZones}
          createdUserName={eCreatedUserName}
          createdUserPhotoUrl={eCreatedUserPhotoUrl}
          errorMessage={errorMessage}
          listDeliveryTime={listDeliveryCreated}
          isSubmitting={isSubmitting}
        />
      </ModalBody>
    </Modal>
  )
}
