import React from 'react'
import ShopList from '_pages/Shop/ShopList'

const CoHootShop = () => (
  <div className='user__profile-desktop'>
    <div className='mx-auto pb-3 container'>
      <ShopList />
    </div>
  </div>
)

export default CoHootShop
