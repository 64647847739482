import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { getAllScheduleEventByGroupId, getEventGroupIdByHost } from '_redux/modules/event'
import { getMyGroups } from '_redux/modules/group'

import WithErrorBoundary from '_components/WithErrorBoundary'
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import MyEventItem from '_pages/Event/components/MyEventItem'
import MyScheduledEvent from '_pages/Event/components/MyScheduledEvent'
import { sortListEventsByPickupTimestamp } from '_utils/function'
import { getGroupIdSelected } from '_utils/localData'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

const now = moment().unix()

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const SpinnerLoading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20%',
    }}
  >
    <div
      className='spinner-border text-info'
      style={{ display: 'flex', justifyContent: 'center' }}
      role='status'
    >
      <span className='sr-only'>Loading...</span>
    </div>
  </div>
)

const MyEventList = () => {
  const { infoGroupSelected, setEventQuantity } = useContext(SelectIdGroupContext)
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const query = useQuery()
  const { listEventGroupIdByHost, allScheduleEventByGroupId } = useSelector((state) => state.event)
  const [isLoading, setIsLoading] = useState(false)

  const [textBtn, setTextBtn] = useState('COPY')
  const [shareLink, setShareLink] = useState('')
  const [modal, setModal] = useState(false)
  const [timeGetScheduleEvent, setTimeGetScheduleEvent] = useState(0)
  const toggle = () => setModal(!modal)

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  useEffect(() => {
    setEventQuantity(listEventGroupIdByHost?.length)
  }, [listEventGroupIdByHost])

  useEffect(() => {
    let isMounted = true

    const getEvents = async () => {
      if (isMounted) setIsLoading(true)
      try {
        await dispatch(
          getEventGroupIdByHost(
            infoGroupSelected?.groupId || getGroupIdSelected(),
            query.get('date')
          )
        )
        await dispatch(getMyGroups)
        await dispatch(
          getAllScheduleEventByGroupId(infoGroupSelected?.groupId || getGroupIdSelected())
        )
      } catch (error) {
        if (isMounted) addToast(error?.message, { appearance: 'error', autoDismiss: true }) // Kiểm tra trước khi cập nhật state
      }
      if (isMounted) setIsLoading(false)
    }

    getEvents()

    return () => {
      isMounted = false
    }
  }, [infoGroupSelected])

  useEffect(() => {
    dispatch(getAllScheduleEventByGroupId(infoGroupSelected?.groupId || getGroupIdSelected()))
  }, [timeGetScheduleEvent])

  return (
    <div className='row-top my-events-page'>
      {isLoading && <SpinnerLoading />}
      {!isLoading ? (
        <>
          <div className='hosted__event-list-desktop size-width-desktop-col-2'>
            <h3 className='hosted__event-list-desktop-title'>Manage Events</h3>
            <div
              style={{ width: '100px', marginLeft: '89%' }}
              className='text-add-delivery d-flex flex-row-reverse mt-2 mb-2'
            >
              <Link to='/event/create' style={{ color: '#079d9a' }}>
                Add event
              </Link>
            </div>
            <div className='hosted__event-list-desktop-item-grid'>
              {sortListEventsByPickupTimestamp(
                listEventGroupIdByHost?.filter((ele) => ele?.pickupTimestamp >= now)
              )?.map((event, index) => (
                <MyEventItem
                  event={event}
                  openModal={toggle}
                  setShareLink={setShareLink}
                  key={index}
                />
              ))}

              {sortListEventsByPickupTimestamp(
                listEventGroupIdByHost?.filter((ele) => ele?.pickupTimestamp < now)
              )?.map((event, index) => (
                <MyEventItem
                  event={event}
                  openModal={toggle}
                  setShareLink={setShareLink}
                  key={index}
                />
              ))}
              {allScheduleEventByGroupId?.map((event, index) => (
                <MyScheduledEvent
                  event={event}
                  openModal={toggle}
                  setShareLink={setShareLink}
                  isScheduledEvent={true}
                  key={index}
                  setTimeGetScheduleEvent={setTimeGetScheduleEvent}
                />
              ))}
            </div>

            {listEventGroupIdByHost?.length === 0 && !isLoading && (
              <div>You don&apos;t have any events!!!</div>
            )}

            <Modal isOpen={modal} toggle={toggle} centered>
              <ModalHeader className='modal-header-created'>
                <span className='c-txt-gray'>Share Link</span>
              </ModalHeader>
              <ModalBody>
                <div className='c-form'>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control form-control--radius shareLink'
                      value={shareLink}
                      readOnly
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className='modal-footer-created'>
                <div className='col-12'>
                  <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
                    {textBtn}
                  </button>
                </div>
                <div className='col-12 mt-2'>
                  <button className='btn btn--solid btn--solid04' onClick={toggle}>
                    CLOSE
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default WithErrorBoundary(MyEventList)
