import React from 'react'

export default function PendingConfirm() {
  return (
    <div>
      <div className="row-bot">
        <div className="text-border">
          pending confirmation
        </div>
      </div>
    </div>
  )
}
