import React, { useEffect, useState } from 'react'
import groupApi from '_api/group'
import { useDispatch } from 'react-redux'
import { joinGroupWithInvitation } from '_redux/modules/group'
import { useToasts } from 'react-toast-notifications'
import { useHistory, useParams } from 'react-router-dom'
import ModalJoin from '../components/ModalJoin'

function JoinForm() {
  const [modal, setModal] = useState(true)
  const [group, setGroup] = useState(null)
  const [passCodeValue, setPassCodeValue] = useState('')
  const [questionValue, setQuestionValue] = useState('')

  const history = useHistory()
  const { gid } = useParams()

  const { addToast } = useToasts()

  const dispatch = useDispatch()
  const toggle = () => setModal(!modal)

  const onSubmitJoin = async () => {
    try {
      const postData = {
        groupId: group.id,
        question: group.question,
        answer: questionValue,
        passcode: passCodeValue,
      }

      await dispatch(joinGroupWithInvitation(postData))
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    let isMounted = true
    const getGroup = async () => {
      const groupInfo = await groupApi.getById(gid)

      setGroup(groupInfo.msgResp)
    }

    if (isMounted) {
      getGroup()
    }

    return () => {
      isMounted = false
    }
  }, [gid])

  useEffect(() => {
    if (!modal) history.push('/group/list-my-groups')
  }, [modal])

  if (!group) return null
  return (
    <ModalJoin
      groupInfo={group}
      modal={modal}
      toggle={toggle}
      onSubmitJoin={onSubmitJoin}
      passCodeValue={passCodeValue}
      setPassCodeValue={setPassCodeValue}
      questionValue={questionValue}
      setQuestionValue={setQuestionValue}
    />
  )
}

export default JoinForm
