import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import useQuery from '_hooks/useQuery'
import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import { convertToCurrentGMT } from '_utils/function'

import { useDispatch, useSelector } from 'react-redux'
import {
  getGroupByIdWithMembers,
  banMember,
  removeMember,
  changeMemberRole,
  getPastPurchasedMember,
} from '_redux/modules/group'

import { useToasts } from 'react-toast-notifications'

import './style.scss'

const MemberDetail = () => {
  const dispatch = useDispatch()
  const params = useQuery()
  const history = useHistory()
  const { addToast } = useToasts()
  const idGroupInParam = params.get('gid')
  const uIdInParam = params.get('uid')

  const { myGroups, pastPurchasedMember } = useSelector((state) => state.group)
  const pastPurchasedCurrentMember = pastPurchasedMember.find(
    (item) => item.userId === uIdInParam && item.groupId === idGroupInParam
  )
  const [groupInfo, setGroupInfo] = useState(
    myGroups?.filter((group) => group.gid === idGroupInParam || group.id === idGroupInParam)[0]
  )
  const [memberInfo, setMemberInfo] = useState(null)

  const [role, setRole] = useState('')
  const [textBtnBan, setTextBtnBan] = useState(memberInfo?.banned === 0 ? 'Ban' : 'Unban')

  const onBanMember = async () => {
    try {
      const postData = {
        groupId: idGroupInParam,
        userId: uIdInParam,
        banned: memberInfo?.banned,
      }
      await dispatch(banMember(postData))
      if (memberInfo?.banned === 1) {
        setTextBtnBan('Banned')
        addToast(`${memberInfo?.name} has been banned from the ${groupInfo?.name}`, {
          appearance: 'success',
          autoDismiss: true,
        })
      } else {
        setTextBtnBan('Ban')
        addToast(`${memberInfo?.name} has been unban from the ${groupInfo?.name}`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onRemoveMember = async () => {
    try {
      const postData = {
        groupId: idGroupInParam,
        userId: uIdInParam,
      }
      const r = confirm('Are you sure?')
      if (r === true) {
        await dispatch(removeMember(postData))
        addToast('Remove member success', { appearance: 'success', autoDismiss: true })
        history.push(`/group/list-members?groupId=${idGroupInParam}`)
      }
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onChangeRole = async (_role) => {
    try {
      setRole(_role)
      const postData = {
        groupId: idGroupInParam,
        userId: uIdInParam,
        role: _role.toUpperCase(),
      }
      await dispatch(changeMemberRole(postData))
      addToast('Change role success', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }
  useEffect(() => {
    dispatch(getGroupByIdWithMembers({ id: idGroupInParam }))
  }, [])
  useEffect(() => {
    dispatch(getPastPurchasedMember(uIdInParam, idGroupInParam))
  }, [idGroupInParam, uIdInParam])

  useEffect(() => {
    setGroupInfo(
      myGroups?.filter((group) => group.gid === idGroupInParam || group.id === idGroupInParam)[0]
    )
  }, [myGroups])

  useEffect(() => {
    setMemberInfo(groupInfo?.members?.filter((member) => member.uid === uIdInParam)[0])
  }, [groupInfo])

  useEffect(() => {
    if (memberInfo?.isAdmin === 1) {
      setRole('admin')
    } else if (memberInfo?.isHost === 1) {
      setRole('host')
    } else {
      setRole('member')
    }
    setTextBtnBan(memberInfo?.banned === 0 ? 'Ban' : 'Banned')
  }, [memberInfo])
  return (
    <div className='row-top member-detail-page'>
      <div className='welcome-group welcome-group--white'>
        <div className='welcome-group__img'>
          <div className='c-image-edit'>
            <div className='c-image'>
              <img
                src={
                  memberInfo?.photoUrl || `${process.env.REACT_APP_AVATAR_URL}${memberInfo?.name}`
                }
                alt=''
              />
            </div>
            <a
              className='icon-whatapp'
              target='_blank'
              rel='noreferrer'
              href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${memberInfo?.phone}`}
            ></a>
          </div>
        </div>
      </div>
      <p className='c-txt-14 text-center'>
        <b>{memberInfo?.name}</b>
        <small>{`${memberInfo?.phone} (${memberInfo?.email})`} </small>
      </p>
      <div className='c-form mygroup'>
        <div className='row justify-content-center btn-small'>
          <div className='col-6'>
            <button className='btn btn--solid btn--solid03' onClick={onBanMember}>
              {textBtnBan}
            </button>
          </div>
          <div className='col-6'>
            <button className='btn btn--solid btn--solid03' onClick={onRemoveMember}>
              Remove
            </button>
          </div>
        </div>
        <div className='form-group form-group--select'>
          <label>User Rights</label>
          <div className='row'>
            <div className='col-4'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  id='roleAdmin'
                  value={role}
                  onChange={() => onChangeRole('admin')}
                  checked={role === 'admin'}
                />
                <label className='custom-control-label c-txt-gray' htmlFor='roleAdmin'>
                  ADMIN
                </label>
              </div>
            </div>
            <div className='col-4'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  id='roleHost'
                  value={role}
                  onChange={() => onChangeRole('host')}
                  checked={role === 'host'}
                />
                <label className='custom-control-label c-txt-gray' htmlFor='roleHost'>
                  HOST
                </label>
              </div>
            </div>
            <div className='col-4'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  value={role}
                  id='roleMember'
                  onChange={() => onChangeRole('member')}
                  checked={role === 'member'}
                />
                <label className='custom-control-label c-txt-gray' htmlFor='roleMember'>
                  MEMBER
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='form-group form-group--select'>
          <label>User Verification Response</label>
          <input
            type='text'
            className='form-control'
            readOnly='1'
            defaultValue={memberInfo?.answer}
          />
        </div>
        <h3 className=' c-txt-14 c-ttl-blue text-underline mt-3 mb-0'>Past Purchased</h3>
        {pastPurchasedCurrentMember &&
        pastPurchasedCurrentMember.listOrders &&
        pastPurchasedCurrentMember.listOrders.length > 0 ? (
          <>
            {pastPurchasedCurrentMember.listOrders.map((item, index) => (
              <div
                className='detail-list-news'
                style={
                  index === 0
                    ? {
                        borderTop: '1px solid rgba(7, 157, 154, 0.3)',
                        borderBottom: '1px solid rgba(7, 157, 154, 0.3)',
                      }
                    : { borderBottom: '1px solid rgba(7, 157, 154, 0.3)' }
                }
                key={index}
              >
                <div
                  className='detail-list-news__item'
                  style={{ paddingLeft: 0, color: '#51aaaa', paddingRight: 0 }}
                >
                  <div className='row'>
                    <div className='col-7'>{item?.eTitle}</div>
                    <div className='col-3'>
                      {moment(convertToCurrentGMT(item?.ePickupTime), 'DD-MM-YYYY').format(
                        'DD-MM-YYYY'
                      )}
                    </div>
                    <div className='col-2'>
                      {item && item.pPrice && item.pQuantity
                        ? `$${item.pPrice * item.pQuantity}`
                        : '$0'}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          'No Purchase'
        )}
      </div>
    </div>
  )
}

export default MemberDetail
