const useLogout = () => {
  for (const key in localStorage) {
    if (key !== 'isShareGroup') {
      localStorage.removeItem(key)
    }
  }

  // localStorage.clear()
  window.location.assign('/')
}

export default useLogout
