import React, { useEffect, useState, memo } from 'react'
import { Bar } from 'react-chartjs-2'

function TopProductChart({ data }) {
  const [labels, setLabels] = useState()
  const [value, setValue] = useState()

  useEffect(() => {
    if (data?.length > 0) {
      const listLabels = data?.map((element) => element.pName)
      const listValue = data?.map((element) => element.pQuantity)
      setValue(listValue)
      setLabels(listLabels)
    }
  }, [data])

  return (
    <div>
      <Bar
        options={{
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              display: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: 'TOTAL VALUE',
              data: value,
              borderColor: ['#5DC6C6', '#FFD166', '#FFAE5C', '#FF7894'],
              backgroundColor: ['#6CCBCB', '#FFDC88', '#FFBC79', '#FF91A9'],
            },
          ],
        }}
      />
    </div>
  )
}

export default memo(TopProductChart)
