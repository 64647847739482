// eslint-disable-next-line max-len
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import { DOMAIN } from '_utils/constant'

import Logo01 from '_images/copyright.svg'
import Logo02 from '_images/logo_02.svg'
import { uniqueEvents } from '_utils/function'
import { useSelector } from 'react-redux'

import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import './style.scss'
import { MENU_ITEMS } from '../../utils/constant'

const Header = ({ location }) => {
  const { pathname } = location
  // const history = useHistory()
  // const userInfo = getUserInfo()

  const { allEvents, eventsPublic } = useSelector((state) => state.event)
  const { user } = useSelector((state) => state.user)
  // const { list: listShop } = useSelector((state) => state.shop)
  // const { myOrders } = useSelector((state) => state.order)

  const [totalEvents, setTotalEvents] = useState(0)

  // const [totalMyPurchases, setTotalMyPurchases] = useState(0)

  const myGroups = user?.groups || []

  const currDomainFeature = user?.domainFeatures?.find((item) => item.domainName === DOMAIN) || {}
  // for user can see all events from group private and public
  // allEvents: list of all events from group private and public and from shop
  // eventsPublic: list of all events from group public
  // if events from group public, group private is the same
  // then it will be filtered to unique events
  const combinedListEvents = uniqueEvents(allEvents, eventsPublic)

  // Hàm kiểm tra xem user có phải là thành viên của group hay không
  const isUserMemberOfGroup = (groupId) => myGroups.some((g) => g.gid === groupId)

  // Lọc sự kiện
  const filteredEvents = combinedListEvents.filter((event) => {
    if (event?.shopId) {
      return true
    }

    if (isUserMemberOfGroup(event.groupId)) {
      return true
    }
    return false
  })

  const group =
    user?.groups?.length && user?.groups?.find((g) => currDomainFeature.groupId === g.gid)

  useEffect(() => {
    if (filteredEvents?.length) {
      setTotalEvents(filteredEvents.length)
    }
  }, [filteredEvents?.length])

  // useEffect(() => {
  //   const userHasGroup = userInfo?.groups?.length > 0
  //   const hasEvents = allEvents?.length > 0
  //   const hasShops = listShop?.length > 0

  //   let path
  //   if (!userHasGroup) {
  //     path = '/group/create'
  //   } else if (hasEvents && hasShops) {
  //     path = '/'
  //   } else if (hasShops) {
  //     path = '/co-hoot'
  //   } else {
  //     path = '/group/my-group-buy'
  //   }

  //   history.push(path)
  // }, [userInfo?.groups?.length])

  // useEffect(() => {
  //   const listOrderUnClosed = myOrders.filter((item) => {
  //     const isDisable = isDisableEvent(
  //       item?.status,
  //       item?.buyerStatus,
  //       convertToCurrentGMT(item?.ePickupTime),
  //       item?.ePickupDuration
  //     )
  //     if (!isDisable) {
  //       return item
  //     }
  //     return null
  //   })
  //   setTotalMyPurchases(listOrderUnClosed.length)
  // }, [myOrders])

  return (
    <>
      <div className='header align-self-start'>
        <div className='container'>
          {user?.groups?.length === 1 && !group ? (
            <div className='main-logo text-left'>
              <h4 className='ttl-grp'>{user?.groups?.[0]?.name}</h4>
              <p className='ttl-company'>
                <img src={Logo01} alt='' />
              </p>
            </div>
          ) : group ? (
            <div className='main-logo'>
              <Link to='/'>
                <img src={Logo02} alt={'main-logo'} />
                <span>{group?.name}</span>
              </Link>
            </div>
          ) : (
            <div className='main-logo '>
              <Link to='/'>
                <img src={Logo02} alt={'main-logo'} />
                <span>Good things must share!</span>
              </Link>
            </div>
          )}

          <Link to='/user/profile' className='avatar-top'>
            <div className='avatar-top__email'>
              <span id='totalNotififications'></span> <i className='icon-email'></i>
            </div>
            <div className='avatar-top__img'>
              <img
                id='userAvatar'
                src={user?.photoUrl || process.env.REACT_APP_AVATAR_URL + user?.name}
                alt=''
              />
            </div>
          </Link>
        </div>
        <div className='nav-menu'>
          <div className='container'>
            <div className='row'>
              <NavLink
                to={'/'}
                className={'col-4 step-1'}
                isActive={() => pathname === '/'}
                style={{ color: pathname === '/' ? '#f06a0f' : '' }}
              >
                All{' '}
                {pathname === '/' && allEvents?.length ? (
                  <span id='totalEvents'>({totalEvents})</span>
                ) : null}
              </NavLink>
              {MENU_ITEMS.map((item, index) => (
                <NavLink
                  to={item.path}
                  activeClassName={'active'}
                  className={`col-4 ${item.className}`}
                  key={index}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Header)
