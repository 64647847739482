/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-comment-textnodes */
import orderApi from '_api/order'
import useQuery from '_hooks/useQuery'
import {
  clearDeliveryByBuyer,
  clearDeliveryDetail,
  clearListDeliveryAndDeliveryBooking,
  getDeliveryByBuyer,
} from '_redux/modules/delivery'
import {
  cancelOrder,
  getListPurchaseDetail,
  getMyOrders,
  getPurchaseDetail,
  updateCommentPurchaseDetail,
  updateDataPurchaseDetail,
  updateMyOrder,
  updateMyOrderWithDelivery,
} from '_redux/modules/order'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import {
  clearEventAndOrder,
  getListCombinedPayment,
  updateTotalOrderCountWhenCancelOrder,
} from '_redux/modules/event'
import { updateTotalOrderCountInGroupWhenOrderWhenCancelOrder } from '_redux/modules/group'

import {
  BUYER_CANCELED,
  BUYER_COLLECTED,
  BUYER_PAID,
  BUYER_UNPAID,
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_HOST_CANCELLED,
  EVENT_CLOSE,
  GMT_0,
  HOST_ORDER_CANCELED,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
  IS_PICKUP_DAY,
  LIST_TIME,
  NOT_PAYNOW,
  PAY_TOGETHER,
  PAYABLE,
  PAYNOW,
  SHORT_DATE,
} from '_utils/constant'
import {
  convertTimestampToCurrentGMT,
  convertTimeStringWithDuration,
  convertToCurrentGMT,
  handleCombinedPayment,
  numDayFromNow,
} from '_utils/function'
import { getUserInfo } from '_utils/localData'

import LongText from '_components/LongText'
import { updateTotalOrderCountInShopWhenOrderWhenCancelOrder } from '_redux/modules/shop'
import Cancel from '../components/Cancel'
import Collected from '../components/Collected'
import CollectionConfirm from '../components/CollectionConfirm'
import MyGallery from '../components/gallery'
import HeaderEventInfo from '../components/HeaderEventInfo'
import OrderConfirmed from '../components/OrderConfirmed'
import OrderConfirmedAfterClose from '../components/OrderConfirmedAfterClose'
import Payment from '../components/Payment'
import PendingConfirm from '../components/PendingConfirm'
import './style.scss'

const ProductOrderDetail = ({ productOrder, event, handleUpdateNote, orderRef, comment }) => (
  <div className='detail-list-news__item' style={{ borderBottom: 'none', fontSize: '0.6875rem' }}>
    <table id='itemList' style={{ color: '#939091' }}>
      <tbody>
        {productOrder &&
        productOrder.allProductPerOrder &&
        productOrder.allProductPerOrder.length > 0
          ? productOrder.allProductPerOrder.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '147px' }}>
                  <MyGallery
                    image={
                      Array.isArray(item.pPhotoUrls) && item.pPhotoUrls.length > 0
                        ? item.pPhotoUrls[0]
                        : ''
                    }
                  />
                </td>
                <td>
                  <h3 style={{ color: 'rgb(147, 144, 145)' }}>{item.pName}</h3>
                  {item.pSubItems && item.pSubItems.length > 0
                    ? item.pSubItems.map((item1, index1) => (
                        <div key={index1}>
                          <p className='pl-1 pr-1 mb-0'>{item1.name}</p>
                        </div>
                      ))
                    : null}
                </td>
                <td className='text-right' style={{ minWidth: '64px' }}>
                  <span>QTY: {item.pQuantity}</span>
                </td>
                <td className='text-right' style={{ width: '20%' }}>
                  ${item?.pPrice?.toFixed(2)}
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
    <br />
    <table style={{ color: '#939091' }}>
      <tbody>
        <tr>
          <td colSpan={2} className='text-right'>
            <span>Total: </span>
          </td>
          <td className='text-right' style={{ width: '20%' }}>
            ${Number(productOrder?.totalPricePerOrder).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className='text-right'>
            <span>Discount: </span>
          </td>
          <td className='text-right' style={{ width: '20%' }}>
            <span id='sharedDiscount'>
              $
              {event?.status === EVENT_CLOSE ||
              (event?.status !== EVENT_CLOSE &&
                numDayFromNow(convertToCurrentGMT(event?.closingTime)) < 1)
                ? Number(productOrder?.discountPerOrder).toFixed(2)
                : `${Number(productOrder?.discountPerOrder).toFixed(2)} (TBC)`}
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className='text-right'>
            <span>Delivery: </span>
          </td>
          <td className='text-right'>
            <span id='sharedDeliveryCost'>
              $
              {event?.status === EVENT_CLOSE ||
              (event?.status !== EVENT_CLOSE &&
                numDayFromNow(convertToCurrentGMT(event?.closingTime)) < 1)
                ? Number(productOrder?.deliveryPerOrder).toFixed(2)
                : `${Number(productOrder?.deliveryPerOrder).toFixed(2)} (TBC)`}
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className='text-right'>
            <span>Admin fee: </span>
          </td>
          <td className='text-right'>
            <span id='sharedAdminCost'>${Number(productOrder?.adminCostPerOrder).toFixed(2)}</span>
          </td>
        </tr>
        <tr className='c-ttl-blue'>
          <td colSpan={2} className='text-right'>
            <span>
              <strong>Final: </strong>
            </span>
          </td>
          <td className='text-right'>
            <strong>${Number(productOrder?.totalFee).toFixed(2)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div className='c-form'>
      <label className='c-ttl-blue c-txt-9'>NOTES TO THE HOST</label>
      <textarea
        rows={3}
        className='form-control'
        onBlur={(e) => handleUpdateNote(e, orderRef)}
        defaultValue={comment}
      />
    </div>
  </div>
)

const BoxOrder = ({
  order,
  listCheckedOrder,
  handleCheckOrder,
  showDetailProduct,
  hideDetailProduct,
  orderDetail,
  listShowDetail,
  handleUpdateNote,
  showConsolidatedBill,
}) => (
  <div className='box-order-with-delivery'>
    <div className='d-flex justify-content-between' style={{ padding: '0px 10px 0px 0px' }}>
      <div className='custom-control custom-checkbox box-same-delivery-current-order__item-order'>
        <input
          id={order?.ref}
          type='checkbox'
          onChange={(e) => handleCheckOrder(e, order?.ref)}
          checked={listCheckedOrder.includes(order?.ref)}
        />
        <label className='custom-control-label' style={{ display: 'inline' }} htmlFor={order?.ref}>
          <LongText
            content={order.eTitle}
            limit={100}
            style={{ transform: 'translateY(-12px)', paddingLeft: '2rem' }}
          />
        </label>
      </div>
      {orderDetail?.find((e) => e.ref === order?.ref) && listShowDetail.includes(order?.ref) ? (
        <i
          onClick={() => hideDetailProduct(order?.ref)}
          style={{ cursor: 'pointer', color: '#15cdca' }}
          className='arrow_carrot-up d-flex align-items-center'
        ></i>
      ) : null}
      {orderDetail?.find((e) => e.ref === order?.ref) && !listShowDetail.includes(order?.ref) ? (
        <i
          onClick={() => showDetailProduct(order?.ref)}
          style={{
            cursor: 'pointer',
            color: '#15cdca',
          }}
          className='arrow_carrot-down d-flex align-items-center'
        ></i>
      ) : null}
    </div>
    {listShowDetail &&
    listShowDetail.includes(order?.ref) &&
    orderDetail?.find((e) => e.ref === order?.ref) ? (
      <ProductOrderDetail
        productOrder={orderDetail?.find((e) => e.ref === order?.ref)?.productOrder}
        event={orderDetail?.find((p) => p.ref === order?.ref)?.event}
        orderRef={order?.ref}
        eid={order?.eid}
        comment={orderDetail?.find((p) => p.ref === order?.ref)?.comment}
        handleUpdateNote={handleUpdateNote}
        deliveryIncludedCurrOrder={order?.deliveryBooking}
        showConsolidatedBill={showConsolidatedBill}
      />
    ) : null}
  </div>
)

const BoxDelivery = ({ delivery, listCheckedDelivery, handleCheckDelivery }) => (
  <div className='box-order-with-delivery'>
    <div className='custom-control custom-checkbox box-order-with-delivery__item-order'>
      <input
        id={delivery?.ref}
        type='checkbox'
        onChange={(e) => handleCheckDelivery(e, delivery?.ref)}
        checked={listCheckedDelivery.includes(delivery?.ref)}
      />
      <label className='custom-control-label' style={{ display: 'inline' }} htmlFor={delivery?.ref}>
        {`Delivery for (${convertTimeStringWithDuration(
          delivery.dDeliveryTime,
          delivery.dDeliveryDuration
        )}) ($${delivery.dzPrice.toFixed(2)})`}
      </label>
    </div>
  </div>
)

const PurchaseDetail = () => {
  const params = useQuery()
  const orderRef = params.get('ref')
  const shopIdParam = params.get('shopId')
  const groupIdParam = params.get('groupId')
  const isEventShop = params.get('eventShop')
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const userInfo = getUserInfo()
  const photoRef = useRef()

  const { id: buyerId } = userInfo

  const { orderDetail, myOrders = [], errorOrderDetail } = useSelector((state) => state.order)
  const { listDeliveryByBuyer = [] } = useSelector((state) => state.delivery)
  const { listCombinedPayments = [] } = useSelector((state) => state.event)

  const [myOrdersFiltered, setMyOrdersFiltered] = useState([])

  const { event, productOrder, orderNo, buyerStatus, status, comment, eid, combinedPaymentRef } =
    orderDetail.find((item) => item.ref === orderRef) || {}

  const { paymentScreenshot } = productOrder || {}

  useEffect(() => {
    if (shopIdParam) {
      setMyOrdersFiltered(myOrders.filter((item) => item.eShopId === shopIdParam))
    } else if (groupIdParam) {
      setMyOrdersFiltered(myOrders.filter((item) => item.eGroupId === groupIdParam))
    } else {
      setMyOrdersFiltered(myOrders)
    }
  }, [shopIdParam, groupIdParam])

  const listConsolidatedBillingOrder = useMemo(() => {
    let orderReturn = []
    if (myOrdersFiltered.length) {
      const orderSameHost = myOrdersFiltered.filter(
        (item) => item.eCreatedUserId === event?.createdUserId
      )
      const orderPayable = orderSameHost.filter((item) => item.ePayable === PAYABLE)
      const excludedCurrOrder = orderPayable.filter((item) => item.ref !== orderRef)
      const excludedPaidOrder = excludedCurrOrder.filter((item) => item.buyerStatus !== BUYER_PAID)
      const excludedCancelOrder = excludedPaidOrder.filter(
        (item) => item.buyerStatus !== BUYER_CANCELED && item.status !== HOST_ORDER_CANCELED
      )
      const allowPayTogetherOrder = excludedCancelOrder.filter(
        (item) => item.ePayTogether === PAY_TOGETHER
      )
      orderReturn = allowPayTogetherOrder.sort((a, b) => a.ePickupTimestamp - b.ePickupTimestamp)
    }
    return orderReturn
  }, [myOrdersFiltered, event])

  const listConsolidatedBillingDelivery = useMemo(() => {
    let deliveryReturn = []
    if (listDeliveryByBuyer.length) {
      const deliverySameHost = listDeliveryByBuyer.filter(
        (item) => item.dCreatedUserId === event?.createdUserId
      )
      const deliveryPaidAble = deliverySameHost.filter(
        (item) =>
          item.bookerStatus !== DELIVERY_BUYER_PAID &&
          item.bookerStatus !== DELIVERY_BUYER_CANCELLED &&
          item.status !== DELIVERY_HOST_CANCELLED
      )

      deliveryReturn = deliveryPaidAble.sort((a, b) => a.dDeliveryTimestamp - b.dDeliveryTimestamp)
    }
    return deliveryReturn
  }, [listDeliveryByBuyer, event])

  const [messageNoteUpdate, setMessageNoteUpdate] = useState('')
  const [paymentFileUrl, setPaymentFileUrl] = useState(paymentScreenshot)
  const [buyerStatusState, setBuyerStatusState] = useState('')
  const [showCancel, setShowCancel] = useState(false)
  const [showPending, setShowPending] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [showPaymentLater, setShowPaymentLater] = useState(false)
  const [showConfirmedPickup, setShowConfirmedPickup] = useState(false)
  const [showConfirmedCollect, setShowConfirmedCollect] = useState(false)
  const [showCollected, setShowCollected] = useState(false)
  const [showConsolidatedBill, setShowConsolidatedBill] = useState(false)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [isSubmittedCollect, setIsSubmittedCollect] = useState(false)
  const [collectionTimeStart, setCollectionTimeStart] = useState('')
  const [collectionTimeEnd, setCollectionTimeEnd] = useState('')

  const [listCheckedOrder, setListCheckedOrder] = useState([orderRef])
  const [listCheckedDelivery, setListCheckedDelivery] = useState([])
  const [listShowDetail, setListShowDetail] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [totalFeePay, setTotalFeePay] = useState(0)
  const [stringRefNumber, setStringRefNumber] = useState('')

  const [refCombinedPayment, setRefCombinedPayment] = useState(combinedPaymentRef)
  const [paymentTogether, setPaymentTogether] = useState('')

  useEffect(() => {
    setTotalFeePay(0)
    let fee = 0
    let stringRef = []
    listCheckedOrder.forEach((_ref) => {
      const order = orderDetail.find((item) => item.ref === _ref) || null
      if (order) {
        fee += order?.productOrder?.totalFee
        stringRef = [...stringRef, `${order?.ref?.substring(0, 4)}-no${order?.orderNo}`]
      }
    })
    listCheckedDelivery.forEach((_ref) => {
      const delivery = listConsolidatedBillingDelivery.find((item) => item.ref === _ref) || null
      if (delivery) {
        fee += delivery?.dzPrice
        stringRef = [...stringRef, `${delivery?.ref?.substring(0, 4)}`]
      }
    })
    setTotalFeePay(fee)
    setStringRefNumber(stringRef.join(', '))
  }, [listCheckedOrder, listCheckedDelivery, orderDetail])

  const listImages =
    event?.photoUrls?.map((item) => ({
      original: item,
    })) || []

  const handleCancel = async (e) => {
    e.preventDefault()
    try {
      const r = confirm('Are you sure?')
      if (r === true) {
        await orderApi.updateOrderBuyerStatus(
          orderRef,
          { buyerStatus: BUYER_CANCELED },
          isEventShop
        )
        dispatch(cancelOrder(orderRef))

        dispatch(
          updateTotalOrderCountWhenCancelOrder(orderDetail.find((item) => item.ref === orderRef))
        )
        if (!isEventShop) {
          dispatch(
            updateTotalOrderCountInGroupWhenOrderWhenCancelOrder(
              orderDetail.find((item) => item.ref === orderRef)
            )
          )
        } else {
          dispatch(
            updateTotalOrderCountInShopWhenOrderWhenCancelOrder(
              orderDetail.find((item) => item.ref === orderRef)
            )
          )
        }
        addToast('Cancel Successfully', { appearance: 'success', autoDismiss: true })
        history.push('/event/list-my-purchases')
      }
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()

    setIsSubmittedPayment(true)
    const photo = paymentFileUrl || photoRef.current

    if (photo) {
      try {
        if (
          (listCheckedOrder && listCheckedOrder.length > 1) ||
          (listCheckedDelivery && listCheckedDelivery.length > 0)
        ) {
          const data = {
            invoiceRefs: [...listCheckedOrder],
            deliveryBookingRefs: [...listCheckedDelivery],
            paymentScreenshot: photo,
            placerStatus: BUYER_PAID,
          }

          const { msgResp } = await orderApi.updateInvoicesPlacerStatus(data, isEventShop)

          dispatch(clearDeliveryDetail())
          dispatch(clearEventAndOrder())
          dispatch(clearDeliveryByBuyer())
          dispatch(clearListDeliveryAndDeliveryBooking())

          setRefCombinedPayment(msgResp.combinedPaymentRef)

          await dispatch(getListCombinedPayment([msgResp.combinedPaymentRef], isEventShop))

          listCheckedOrder.forEach((item) => {
            dispatch(updateDataPurchaseDetail(item, BUYER_PAID, msgResp.combinedPaymentRef))
            dispatch(updateMyOrder(item, BUYER_PAID, msgResp.combinedPaymentRef))
          })

          listCheckedDelivery.forEach((item) => {
            dispatch(updateMyOrderWithDelivery(item, BUYER_PAID))
          })

          setIsSubmittedPayment(false)
        } else {
          await orderApi.updateOrderBuyerStatusWithPaymentScreenshot(orderRef, {
            paymentScreenshot: photo,
            buyerStatus: BUYER_PAID,
            isEventShop,
          })
          dispatch(updateDataPurchaseDetail(orderRef, BUYER_PAID))
          dispatch(updateMyOrder(orderRef, BUYER_PAID))
          dispatch(clearEventAndOrder())
          setBuyerStatusState(BUYER_PAID)
          setIsSubmittedPayment(false)
        }
      } catch (error) {
        setIsSubmittedPayment(false)
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      setIsSubmittedPayment(false)
      alert('Please upload payment screenshot')
    }
  }

  const handleUpdateNote = async (e, _orderRef) => {
    const data = e.target.value
    try {
      await orderApi.updateOrderBuyerInfo(_orderRef, { comment: data }, isEventShop)
      dispatch(clearEventAndOrder())
      dispatch(updateCommentPurchaseDetail(_orderRef, data))
      addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    } catch (err) {
      addToast(err?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleConfirmCollection = async (e) => {
    e.preventDefault()
    try {
      setIsSubmittedCollect(true)
      dispatch(clearEventAndOrder())
      dispatch(updateDataPurchaseDetail(orderRef, BUYER_COLLECTED))
      dispatch(updateMyOrder(orderRef, BUYER_COLLECTED))
      setBuyerStatusState(BUYER_COLLECTED)
    } catch (error) {
      setIsSubmittedCollect(false)
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }
  const showPaymentStatus = (_buyerStatus, _status) => {
    setShowCancel(false)
    setShowPayment(false)
    setShowPaymentLater(false)
    setShowPending(false)
    setShowConfirmedCollect(false)
    setShowConfirmedPickup(false)
    setShowCollected(false)
    setShowConsolidatedBill(false)
    setCheckAll(false)

    if (_buyerStatus === BUYER_CANCELED || _status === HOST_ORDER_CANCELED) {
      setShowCancel(true)
    } else if (_buyerStatus === BUYER_UNPAID && _status === HOST_ORDER_UNPAID) {
      if (event?.paynow === PAYNOW) {
        setShowPayment(true)
        setShowConsolidatedBill(true)
      } else if (event?.payable === PAYABLE && event?.paynow !== PAYNOW) {
        setShowPayment(true)
        setShowConsolidatedBill(true)
      } else {
        setShowPaymentLater(true)
      }
    } else if (_buyerStatus === BUYER_PAID && _status === HOST_ORDER_UNPAID) {
      setShowPending(true)
    } else if (_buyerStatus === BUYER_UNPAID && _status === HOST_ORDER_VERIFIED) {
      if (numDayFromNow(convertToCurrentGMT(event?.pickupTime)) <= IS_PICKUP_DAY) {
        setShowConfirmedCollect(true)
      } else {
        setShowConfirmedPickup(true)
      }
    } else if (_buyerStatus === BUYER_PAID && _status === HOST_ORDER_VERIFIED) {
      if (numDayFromNow(convertToCurrentGMT(event?.pickupTime)) <= IS_PICKUP_DAY) {
        setShowConfirmedCollect(true)
      } else {
        setShowConfirmedPickup(true)
      }
    } else if (_buyerStatus > BUYER_PAID || _status > HOST_ORDER_VERIFIED) {
      setShowCollected(true)
    } else {
      // Unknown
      // console.log(_buyerStatus, _status)
    }
  }

  const [isLoading, setIsLoading] = useState(true)

  const initialOrders = async (_orderRef) => {
    setIsLoading(true)
    await dispatch(getPurchaseDetail(_orderRef, isEventShop))
    await dispatch(getMyOrders)
    await dispatch(getDeliveryByBuyer(buyerId, isEventShop))
    setIsLoading(false)
  }

  useEffect(() => {
    if (combinedPaymentRef) {
      setRefCombinedPayment(combinedPaymentRef)
      const getCombinedPayment = async () => {
        await dispatch(getListCombinedPayment([combinedPaymentRef]))
      }
      getCombinedPayment()
    }
  }, [combinedPaymentRef])

  useEffect(() => {
    if (refCombinedPayment && listCombinedPayments.length) {
      const combinedPayment =
        listCombinedPayments.find((item) => item.combinedPaymentRef === refCombinedPayment) || {}

      setPaymentTogether(handleCombinedPayment(combinedPayment, orderRef))
    }
  }, [refCombinedPayment, listCombinedPayments])

  useEffect(() => {
    initialOrders(orderRef)
    setListCheckedOrder([orderRef])
  }, [orderRef])

  useEffect(() => {
    dispatch(getPurchaseDetail(orderRef, isEventShop))
  }, [buyerStatusState])

  useEffect(() => {
    showPaymentStatus(buyerStatus, status)
  }, [buyerStatus, status, event?.payable, event?.paynow, event?.pickupTime])

  useEffect(() => {
    setPaymentFileUrl(paymentScreenshot)
  }, [paymentScreenshot])

  useEffect(() => {
    let isMounted = true

    setTimeout(() => {
      if (isMounted) setMessageNoteUpdate('')
    }, 3000)

    return () => {
      isMounted = false
    }
  }, [messageNoteUpdate])

  useEffect(() => {
    if (event?.pickupTime && event?.pickupDuration) {
      const pickupTimeCurrentGMT = convertToCurrentGMT(event?.pickupTime)
      const timeStart = Number(pickupTimeCurrentGMT.split(' ')[1].split(':')[0])
      const timeEnd = timeStart + event?.pickupDuration / 3600
      if (timeEnd <= 24) {
        const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
        const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
        setCollectionTimeStart(LIST_TIME[indexStart]?.text)
        setCollectionTimeEnd(LIST_TIME[indexEnd]?.text)
      } else {
        setCollectionTimeStart('')
        setCollectionTimeEnd('')
      }
    }
  }, [event?.pickupTime, event?.pickupDuration])

  const handleCheckOrder = async (e, _ref) => {
    const checked = e.target.checked
    if (checked) {
      setListCheckedOrder([...listCheckedOrder, _ref])
      await dispatch(getPurchaseDetail(_ref, isEventShop))
    } else {
      const newListChecked = listCheckedOrder.filter((item) => item !== _ref)
      setListCheckedOrder([...newListChecked])
    }
  }

  const handleCheckDelivery = async (e, _ref) => {
    const checked = e.target.checked
    if (checked) {
      setListCheckedDelivery([...listCheckedDelivery, _ref])
    } else {
      const newListChecked = listCheckedDelivery.filter((item) => item !== _ref)
      setListCheckedDelivery([...newListChecked])
    }
  }

  const handleCheckAll = (e) => {
    const checked = e.target.checked
    if (checked) {
      setCheckAll(true)
      const newListCheckOrder = [orderRef]
      const newListCheckDelivery = []
      listConsolidatedBillingOrder.forEach((item) => {
        newListCheckOrder.push(item.ref)
      })

      listConsolidatedBillingDelivery.forEach((item) => {
        newListCheckDelivery.push(item.ref)
      })

      dispatch(getListPurchaseDetail(newListCheckOrder, isEventShop))
      setListCheckedOrder([...newListCheckOrder])
      setListCheckedDelivery([...newListCheckDelivery])
    } else {
      setCheckAll(false)
      const newListCheckedOrder = [orderRef]
      setListCheckedOrder([...newListCheckedOrder])
      setListCheckedDelivery([])
    }
  }

  const showDetailProduct = (_ref) => {
    setListShowDetail([...listShowDetail, _ref])
  }

  const hideDetailProduct = (_ref) => {
    const newListShowDetail = listShowDetail.filter((item) => item !== _ref)
    setListShowDetail([...newListShowDetail])
  }

  const renderListBoxOrder = () => (
    <>
      {listConsolidatedBillingOrder && listConsolidatedBillingOrder.length ? (
        <div>
          {listConsolidatedBillingOrder.map((item, index) => (
            <BoxOrder
              key={index}
              order={item}
              listCheckedDelivery={listCheckedDelivery}
              listCheckedOrder={listCheckedOrder}
              handleCheckOrder={handleCheckOrder}
              handleCheckDelivery={handleCheckDelivery}
              showDetailProduct={showDetailProduct}
              hideDetailProduct={hideDetailProduct}
              orderDetail={orderDetail}
              listShowDetail={listShowDetail}
              handleUpdateNote={handleUpdateNote}
              showConsolidatedBill={showConsolidatedBill}
            />
          ))}
        </div>
      ) : null}
    </>
  )

  const renderBoxDelivery = () => (
    <>
      {listConsolidatedBillingDelivery && listConsolidatedBillingDelivery.length ? (
        <div>
          {listConsolidatedBillingDelivery.map((item, index) => (
            <BoxDelivery
              key={index}
              delivery={item}
              listCheckedDelivery={listCheckedDelivery}
              listCheckedOrder={listCheckedOrder}
              handleCheckOrder={handleCheckOrder}
              handleCheckDelivery={handleCheckDelivery}
              showDetailProduct={showDetailProduct}
              hideDetailProduct={hideDetailProduct}
              orderDetail={orderDetail}
              listShowDetail={listShowDetail}
              handleUpdateNote={handleUpdateNote}
              showConsolidatedBill={showConsolidatedBill}
            />
          ))}
        </div>
      ) : null}
    </>
  )

  return (
    <>
      <section
        id='purchase-detail-page'
        className='section-content section-content--top'
        style={{ paddingTop: '0px' }}
      >
        {isLoading ? <div>Loading...</div> : null}
        {!isLoading && errorOrderDetail ? <div>No order found</div> : null}
        {!isLoading && !errorOrderDetail ? (
          <div className='container'>
            <div className='row-top'>
              <div className='group-list group-list--event detail-event group-list--event02'>
                <div className='group-list__item'>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <span>
                      ORDER NO: <b id='eventBOrderNo'>{orderNo}</b>
                    </span>
                  </div>
                  <HeaderEventInfo
                    createdUserPhotoUrl={event?.createdUserPhotoUrl}
                    createdUserName={event?.createdUserName}
                    eClosingTime={convertToCurrentGMT(event?.closingTime)}
                    eTotalOrderCount={event?.totalOrderCount}
                    groupName={event?.groupName}
                    collectionTimeStart={collectionTimeStart}
                    collectionTimeEnd={collectionTimeEnd}
                    ePickupTime={convertToCurrentGMT(event?.pickupTime)}
                  />
                  <div className='group-list__des'>
                    {listImages?.length > 0 && (
                      <ReactImageGallery
                        items={listImages}
                        lazyLoad
                        autoPlay
                        showPlayButton={false}
                        showNav={false}
                        showBullets={true}
                        showThumbnails={false}
                      />
                    )}
                    <h4>
                      <a href='' onClick={(e) => e.preventDefault()}>
                        {event?.title}
                      </a>
                    </h4>
                    <p className='paragraph-pre-wrap'>{event?.description}</p>
                  </div>
                  <div className='group-list__detail group-list__detail--ogr'>
                    <div className='row'>
                      <div className='col-4'>Closing: </div>
                      <div className='col-8'>
                        {moment
                          .unix(convertTimestampToCurrentGMT(event?.closingTimestamp))
                          .utcOffset(GMT_0)
                          .format(SHORT_DATE)}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-4'>Collection: </div>
                      <div className='col-8' style={{ color: '#51aaaa' }}>
                        {moment
                          .unix(convertTimestampToCurrentGMT(event?.pickupTimestamp))
                          .utcOffset(GMT_0)
                          .format(SHORT_DATE)}
                      </div>
                    </div>
                    {event?.pickupAddress ? (
                      <div className='row'>
                        <div className='col-4'>Address: </div>
                        <div className='col-8 text-blue'>{event?.pickupAddress}</div>
                      </div>
                    ) : null}
                  </div>
                  {moment().isAfter(
                    moment(convertToCurrentGMT(event?.closingTime), 'DD-MM-YYYY HH:mm')
                  ) ||
                  buyerStatus !== BUYER_UNPAID ||
                  status !== HOST_ORDER_UNPAID ||
                  (event?.paynow === NOT_PAYNOW && event?.status === EVENT_CLOSE) ? null : (
                    <div className='group-buttom'>
                      <a className='group-buttom__Cancel' href='#' onClick={handleCancel}>
                        CANCEL
                      </a>
                      <Link
                        to={
                          !isEventShop
                            ? `/event/detail?eid=${event?.id}&&oid=${orderRef}`
                            : `/event/detail?eventShop=true&eid=${event?.id}&&oid=${orderRef}`
                        }
                        className='group-buttom__Edit'
                      >
                        EDIT
                      </Link>
                    </div>
                  )}
                </div>
                <div className='detail-list-news detail-list-news--org' style={{ marginTop: 10 }}>
                  <ProductOrderDetail
                    productOrder={productOrder}
                    event={event}
                    orderRef={orderRef}
                    eid={eid}
                    handleUpdateNote={handleUpdateNote}
                    comment={comment}
                    handleCheckDelivery={handleCheckDelivery}
                    listCheckedDelivery={listCheckedDelivery}
                    showConsolidatedBill={showConsolidatedBill}
                  />
                </div>
              </div>
            </div>

            {showConsolidatedBill &&
            ((listConsolidatedBillingDelivery && !!listConsolidatedBillingDelivery.length) ||
              (listConsolidatedBillingOrder && !!listConsolidatedBillingOrder.length)) &&
            event?.payTogether === PAY_TOGETHER ? (
              <>
                <div className='list-check-box-container'>
                  <div
                    className='d-flex justify-content-between'
                    style={{ fontWeight: '500', marginBottom: '1rem' }}
                  >
                    <div style={{ display: 'inline', color: '#51aaaa', fontWeight: 600 }}>
                      {' '}
                      Pay together:{' '}
                    </div>
                    <div className='custom-control custom-checkbox' style={{ display: 'inline' }}>
                      <input
                        id='CheckAll'
                        type='checkbox'
                        onChange={(e) => handleCheckAll(e)}
                        checked={checkAll}
                      />
                      <label
                        className='custom-control-label'
                        style={{ display: 'inline', fontSize: '16px' }}
                        htmlFor='CheckAll'
                      >
                        All
                      </label>
                    </div>
                  </div>

                  {renderListBoxOrder()}

                  {renderBoxDelivery()}
                </div>
              </>
            ) : null}

            {paymentTogether ? (
              <div className='list-check-box-container'>
                <div className='pay-together'>Pay together with other order: {paymentTogether}</div>
              </div>
            ) : null}

            {showPayment ? (
              <Payment
                paymentFileUrl={paymentFileUrl}
                confirmPayment={confirmPayment}
                final={totalFeePay}
                phone={`${event?.createdUserPhone}`}
                pickupTime={convertToCurrentGMT(event?.pickupTime)}
                orderRef={orderRef}
                orderNo={orderNo}
                isSubmittedPayment={isSubmittedPayment}
                stringRefNumber={stringRefNumber}
                ref={photoRef}
                setPaymentFileUrl={setPaymentFileUrl}
              />
            ) : null}

            {showPaymentLater ? <OrderConfirmedAfterClose /> : null}

            {showPending ? <PendingConfirm /> : null}

            {showConfirmedPickup ? (
              <OrderConfirmed day={numDayFromNow(convertToCurrentGMT(event?.pickupTime))} />
            ) : null}

            {showConfirmedCollect ? (
              <CollectionConfirm
                isSubmittedCollect={isSubmittedCollect}
                day={moment
                  .unix(convertTimestampToCurrentGMT(event?.pickupTimestamp))
                  .utcOffset(GMT_0)
                  .format(SHORT_DATE)}
                host={event?.createdUserName}
                handleConfirmCollection={handleConfirmCollection}
              />
            ) : null}

            {showCollected ? <Collected /> : null}

            {showCancel ? <Cancel /> : null}
          </div>
        ) : null}
      </section>
    </>
  )
}
export default PurchaseDetail
