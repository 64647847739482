import React from 'react'
import InputField from '_components/Input'

const EventTitle = (props) => {
  const { register, error } = props
  return (
    <div className='form-group'>
      <label>
        Title<span className='sys'>*</span>
      </label>
      <div>
        <div className='form-group'>
          <InputField
            className='form-control textareaext-left'
            name={register.name}
            type='text'
            register={register}
          />
          <small className='errorMessage'>{error}</small>
        </div>
      </div>
    </div>
  )
}

export default EventTitle
