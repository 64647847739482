import React from 'react'
import PropTypes from 'prop-types'
import { FaTruck } from 'react-icons/fa'
import { Spinner } from 'reactstrap'

export default function DeliveryForm({
  deliveryZones,
  createdUserName,
  createdUserPhotoUrl,
  dAddress = '', dComment = '', dZone = '',
  onChangeDZone, onChangeDAddress, onChangeDComment,
  onBlurDAddress = () => { },
  onBlurDComment = () => { },
  onSubmit = null,
  errorMessage,
  disabledInput = false,
  isSubmitting = false,
  deliveryTime = '',
  onChangeDTime,
  listDeliveryTime = [],
}) {
  return (
    <div>
      <div className='icon-truct'>
        <FaTruck />
      </div>
      <form className='delivery-form'>
        <div className='delivery-form__header'>
          <div className='header-left'>
            <div className='header-left--text'>Delivery</div>
          </div>
          <div className='header-right'>
            <div className='header-right--text'>
              <div style={{ fontWeight: 700 }}>By Host</div>
              <div>{createdUserName}</div>
            </div>
            <div className='header-right--img'>
              <img
                src={createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName}
                alt=''
              />
            </div>
          </div>
        </div>
        <div>
          <div className='form-input'>
            <label>Delivery Location:</label>
            <select className="form-input-size"
              value={dZone}
              onChange={onChangeDZone}
              disabled={disabledInput}
            >
              {deliveryZones.map((item, index) => (
                <option key={index} value={item.name}>{item.name} (${item.price})</option>
              ))}
            </select>
            <span style={{ color: 'red', fontSize: '0.9rem' }}>{errorMessage
              && errorMessage.deliveryZone ? errorMessage.deliveryZone : ''}</span>
          </div>
          {listDeliveryTime && listDeliveryTime.length ? <div className='form-input'>
            <label>Delivery Date:</label>
            <select className="form-input-size"
              value={deliveryTime}
              onChange={onChangeDTime}
              disabled={disabledInput}
            >
              {listDeliveryTime.map((item, index) => (
                <option key={index} value={item.id}>{item.deliveryTime}</option>
              ))}
            </select>
            <span style={{ color: 'red', fontSize: '0.9rem' }}>{errorMessage
              && errorMessage.deliveryZone ? errorMessage.deliveryZone : ''}</span>
          </div> : null}
          <div className='form-input'>
            <label>Address:</label>
            <input type="text" placeholder="Enter your address" className="form-input-size"
              value={dAddress || ''}
              onChange={onChangeDAddress}
              onBlur={onBlurDAddress}
              disabled={disabledInput}
            />
            <span style={{ color: 'red', fontSize: '0.9rem' }}>{errorMessage
              && errorMessage.deliveryAddress ? errorMessage.deliveryAddress : ''}</span>
          </div>
          <div className='form-input'>
            <label>Note:</label>
            <input type="text" placeholder="Enter your note" className="form-input-size"
              value={dComment || ''}
              onChange={onChangeDComment}
              onBlur={onBlurDComment}
            />
          </div>
          {onSubmit ? <div className="button-submit">
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'Add Delivery'}
            </button>
          </div> : null}
        </div>
      </form>
    </div>
  )
}

DeliveryForm.propTypes = {
  deliveryZones: PropTypes.array,
  listDeliveryTime: PropTypes.array,
  createdUserName: PropTypes.string,
  createdUserPhotoUrl: PropTypes.string,
  dAddress: PropTypes.string,
  dComment: PropTypes.string,
  dZone: PropTypes.string,
  deliveryTime: PropTypes.string,
  onChangeDZone: PropTypes.func,
  onChangeDTime: PropTypes.func,
  onChangeDAddress: PropTypes.func,
  onChangeDComment: PropTypes.func,
  onBlurDZoneId: PropTypes.func,
  onBlurDAddress: PropTypes.func,
  onBlurDComment: PropTypes.func,
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.object,
  disabledInput: PropTypes.bool,
  handleCancel: PropTypes.func
}
