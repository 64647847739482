import { useState } from 'react'

const LongText = ({ content, limit, className, style }) => {
  const [showAll, setShowAll] = useState(false)

  const showMore = () => setShowAll(true)
  const showLess = () => setShowAll(false)

  if (content) {
    if (content.length <= limit) {
      // there is nothing more to show
      return <>{content}</>
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <div className={className} style={style}>
          {content}
          <button className='bg-transparent border-0 text--primary ps-0 fw-bold' onClick={showLess}>
            Read less
          </button>
        </div>
      )
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = `${content.substring(0, limit)}...`
    return (
      <div className={className} style={style}>
        {toShow}
        <button className='bg-transparent border-0 text--primary ps-0 fw-bold' onClick={showMore}>
          Read more
        </button>
      </div>
    )
  }

  return null
}

export default LongText
