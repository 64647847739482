/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import * as Yup from 'yup'

import serviceApi from '_api/service'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import ModalGeneralPayment from '_components/ModalGeneralPayment'
import groupIcon from '_images/group-icon.png'
import {
  // PAYMENT_SERVICE_SUBDOMAIN_REF,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_VERIFY,
  STATUS_PAID,
  STATUS_PENDING,
} from '_utils/constant'
import { setUserInfo } from '_utils/localData'

const validationSchema = Yup.object().shape({
  domainName: Yup.string('Invalid domain name')
    .typeError('Invalid domain name')
    .required('Please enter Domain name')
    .nullable(),
})

const Subdomain = ({ groupId, domainName, domain, setDomain }) => {
  const { addToast } = useToasts()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { domainName },
  })

  const [showButtonPay, setShowButtonPay] = useState(false)
  const [showButtonSearch, setShowButtonSearch] = useState(false)
  const [showPendingDomain, setShowPendingDomain] = useState(false)
  const [showDeployedDomain, setShowDeployedDomain] = useState(false)
  const [showButtonCancel, setShowButtonCancel] = useState(false)
  const [disableInput, setDisableInput] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [domainNameInput, setDomainNameInput] = useState('')

  const [price, setPrice] = useState(0)

  const [paymentFileUpload, setPaymentFileUpload] = useState('')
  const [paymentFileUrl, setPaymentFileUrl] = useState('')

  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)

  const toggle = () => setIsShowModal(!isShowModal)

  useEffect(() => {
    setShowButtonPay(false)
    setShowButtonSearch(false)
    setShowDeployedDomain(false)
    setShowPendingDomain(false)
    setShowButtonCancel(false)

    setDomainNameInput(domainName)

    const { domainStatus = null, domainPaymentStatus = null } = domain

    if (domainName) {
      setDisableInput(true)
    }

    if (domainStatus === STATUS_PENDING && domainPaymentStatus === PAYMENT_STATUS_PAID) {
      setShowPendingDomain(true)
    } else if (domainStatus === STATUS_PAID && domainPaymentStatus === PAYMENT_STATUS_VERIFY) {
      setShowDeployedDomain(true)
    } else {
      setShowButtonSearch(true)
    }
  }, [domain, domainName])

  const onSubmitSearch = handleSubmit(async (data) => {
    try {
      setDomainNameInput(data?.domainName)
      const { msgCode, msgResp } = await serviceApi.searchDomainName(groupId, data)

      const { available } = msgResp
      // eslint-disable-next-line no-shadow
      const { price } = msgResp
      setPrice(price)
      if (Number(msgCode) % 100 === 0 && available) {
        setShowButtonPay(true)
        setDisableInput(true)
        setShowButtonSearch(false)
        setShowButtonCancel(true)
      } else {
        addToast(msgResp.message, { appearance: 'error', autoDismiss: true })
      }
    } catch ({ msgResp, message }) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  })

  const onSubmitPayment = handleSubmit(async () => {
    toggle()
  })
  const onSubmitCancel = async (e) => {
    e.preventDefault()
    setShowButtonPay(false)
    setShowButtonSearch(true)
    setShowButtonCancel(false)
    setDisableInput(false)
  }

  const onUserImageUploaded = (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPaymentFileUrl(URL.createObjectURL(img))
      setPaymentFileUpload(files)
    }
  }
  const confirmPayment = async (e) => {
    e.preventDefault()
    setIsSubmittedPayment(true)
    let url = paymentFileUrl
    if (paymentFileUpload) {
      const { msgResp: res } = await uploadApi.uploadPhoto(paymentFileUpload)
      // eslint-disable-next-line prefer-destructuring
      url = res.url
    }
    if (url) {
      try {
        await serviceApi.purchasePaymentSubdomain(groupId, {
          domainName: domainNameInput,
          domainPaymentScreenshot: url,
          domainPrice: price,
        })

        userApi
          .getUser()
          .then(({ msgResp }) => {
            const { domainFeatures = [] } = msgResp
            if (domainFeatures.length) {
              const domainFeaturePaid = domainFeatures.filter(
                (item) =>
                  item.domainPaymentStatus >= PAYMENT_STATUS_PAID &&
                  item.domainStatus >= STATUS_PENDING &&
                  item.groupId === groupId
              )
              if (domainFeaturePaid.length) {
                setDomain(domainFeaturePaid[0])
                const { domainStatus = null, domainPaymentStatus = null } = domainFeaturePaid[0]

                setShowButtonPay(false)
                setShowButtonSearch(false)
                setShowDeployedDomain(false)
                setShowPendingDomain(false)
                setShowButtonCancel(false)

                if (
                  domainStatus === STATUS_PENDING &&
                  domainPaymentStatus === PAYMENT_STATUS_PAID
                ) {
                  setShowPendingDomain(true)
                } else if (
                  domainStatus === STATUS_PAID &&
                  domainPaymentStatus === PAYMENT_STATUS_VERIFY
                ) {
                  setShowDeployedDomain(true)
                } else {
                  setShowButtonSearch(true)
                }
              } else {
                setDomain({})
              }
            } else {
              setDomain({})
            }
            setUserInfo(msgResp)
          })
          .catch(({ msgResp }) => {
            addToast(msgResp, { appearance: 'error', autoDismiss: true })
          })

        setIsSubmittedPayment(false)
        toggle()
      } catch (error) {
        setIsSubmittedPayment(false)
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      setIsSubmittedPayment(false)
      alert('Please upload payment screenshot')
    }
  }

  return (
    <div className='ml-2 mr-2 mb-2'>
      {showButtonSearch || showButtonPay ? (
        <form
          className='row'
          style={{
            justifyContent: 'space-between',
          }}
        >
          <input
            className='form-control'
            placeholder='Domain Name'
            name='domainName'
            disabled={disableInput}
            type='text'
            {...register('domainName')}
            style={{
              padding: '0 0 0 10px',
              flexBasis: '100%',
            }}
          />
          {errors?.domainName && (
            <small className='errorMessage' style={{ textAlign: 'left', padding: 0 }}>
              {errors.domainName.message}
            </small>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingRight: 0,
            }}
            className='mt-2'
          >
            {showButtonSearch ? (
              <button
                className='btn btn-primary'
                type='submit'
                disabled={isSubmitting}
                style={{
                  borderRadius: 3,
                  flexBasis: '15%',
                  marginTop: 0,
                }}
                onClick={onSubmitSearch}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'SEARCH'}
              </button>
            ) : null}
            {showButtonPay ? (
              <button
                className='btn btn--main'
                type='submit'
                disabled={isSubmitting}
                style={{
                  borderRadius: 3,
                  flexBasis: '15%',
                  marginTop: 0,
                }}
                onClick={onSubmitPayment}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'PAYMENT'}
              </button>
            ) : null}
            {showButtonCancel ? (
              <button
                className='btn btn-secondary ml-2'
                type='submit'
                disabled={isSubmitting}
                style={{
                  borderRadius: 3,
                  flexBasis: '15%',
                  marginTop: 0,
                }}
                onClick={(e) => onSubmitCancel(e)}
              >
                CANCEL
              </button>
            ) : null}
          </div>
        </form>
      ) : null}

      {showPendingDomain ? (
        <div className='bs-tooltip-right' title='Wait for confirmation'>
          <span style={{ color: '#e0a800', textDecoration: 'underline' }}>{domainNameInput}</span>
        </div>
      ) : null}

      {showDeployedDomain ? (
        <div className='bs-tooltip-right' title='Confirmed and deployed'>
          <a
            href={`https://${domainNameInput}`}
            target={'_blank'}
            style={{ color: '#51aaaa', textDecoration: 'underline' }}
            rel='noreferrer'
          >
            {domainNameInput}
          </a>
        </div>
      ) : null}

      {isShowModal && (
        <ModalGeneralPayment
          isShow={isShowModal}
          toggle={toggle}
          onUserImageUploaded={onUserImageUploaded}
          paymentFileUrl={paymentFileUrl}
          isSubmittedPayment={isSubmittedPayment}
          confirmPayment={confirmPayment}
          service={{ type: 'Subdomain', price }}
        />
      )}
    </div>
  )
}

const GroupDetail = ({ idGroupInParam, groupById, domain, setDomain }) => {
  const shareLink = `${process.env.REACT_APP_APP_URL}/group/join/${idGroupInParam}`
  const [textBtn, setTextBtn] = useState('COPY')

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const { domainName = '' } = domain

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  return (
    <div>
      <h2 className='text-center c-ttl c-ttl--01'>{groupById?.name}</h2>
      <div className='text-center'>
        <span className='link-share text-underline' onClick={toggle}>
          SHARE
        </span>
      </div>
      <div className='welcome-group welcome-group--white'>
        <div className='welcome-group__img'>
          <div className='c-image-edit'>
            <div className='c-image'>
              <img src={groupById?.photoUrl?.url || groupById?.photoUrl || groupIcon} alt='' />
            </div>
            {groupById?.isAdmin === 1 && (
              <Link className='icon-edit-image' to={`/group/update?id=${idGroupInParam}`} />
            )}
          </div>
        </div>
        <p>{groupById?.description}</p>
        {/* <Subdomain
          groupId={idGroupInParam}
          domainName={domainName}
          domain={domain}
          setDomain={setDomain}
        /> */}
        <div className='welcome-number'>
          <ul>
            <li>
              <Link className='event-hosted' to={`/group/list-events?groupId=${idGroupInParam}`}>
                <span>{groupById?.totalEvents || 0}</span>
                <span>EVENT HOSTED</span>
              </Link>
            </li>
            <li>
              <Link to={`/group/list-members?groupId=${groupById?.id}`} className='total-members'>
                <span>{groupById?.totalMembers || 0}</span>
                <span>MEMBERS</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader className='modal-header-created'>
          <span className='c-txt-gray'>Share Link</span>
        </ModalHeader>
        <ModalBody>
          <div className='c-form'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control form-control--radius shareLink'
                defaultValue={shareLink}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='modal-footer-created'>
          <div className='col-12'>
            <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
              {textBtn}
            </button>
          </div>
          <div className='col-12 mt-2'>
            <button className='btn btn--solid btn--solid04' onClick={toggle}>
              CLOSE
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default GroupDetail
