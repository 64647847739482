import React from 'react'
import logoLeft from '_images/logo_left.svg'
import imgTop from '_images/img_top.svg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Form } from 'reactstrap'
import InputField from '_components/Input'
import authApi from '_api/auth'
import reportApi from '_api/report'
import './style.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Invalid email'),
})

const Forgot = () => {
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = ({ email }) => {
    authApi
      .searchUser(email.toLowerCase().trim())
      .then((res) => {
        if (res.msgResp.uid !== null) {
          const patchData = {
            step: 1,
            email: email.toLowerCase().trim(),
          }
          authApi
            .resetPassword(patchData)
            .then(() => {
              setError('email', { type: 'invalid', message: 'Email sent' })
            })
            .catch((error) => {
              reportApi.report({
                message: `[Forgot password 01]${error.msgCode} - ${error.msgResp}`,
              })
              if (error.msgCode === 10605) {
                setError('email', { type: 'invalid', message: 'Email sent' })
              } else {
                setError('email', { type: 'invalid', message: error.msgResp })
              }
            })
        } else {
          setError('email', { type: 'invalid', message: 'Account not exist' })
        }
      })
      .catch((error) => {
        reportApi.report({ message: `[Forgot password 02]${error.msgCode} - ${error.msgResp}` })
        setError('email', { type: 'invalid', message: error.msgResp })
      })
  }

  return (
    <body className='p-login min-vh-100'>
      <div className='page-login page-login--screen'>
        <div className='main-top align-self-start'>
          <div className='container'>
            <div className='main-logo text-left'>
              <img src={logoLeft} />
            </div>
            <div className='main-img text-center'>
              <img src={imgTop} />
            </div>
          </div>
        </div>
        <div className='main-content'>
          <Form onSubmit={handleSubmit(onSubmit)} className='container'>
            <h2 className='c-ttl text-center'>Forget PIN?</h2>
            <div className='c-form c-form--email'>
              <div className='c-txt-org text-center'>
                Enter your email and we’ll send your PIN to your registered e-mail
              </div>
              <InputField
                className='form-control'
                id='email'
                name='email'
                type='text'
                placeholder='Email address'
                register={register('email')}
              />
              {errors?.email && <small className='errorMessage'>{errors.email.message}</small>}
              <div className='form-group'>
                <button className='btn btn--main'>SUBMIT</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </body>
  )
}

export default Forgot
