import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'

function MySlider({ listImage, className }) {
  if (listImage.length === 0) {
    return (
      <div
        className={'d-flex h-100 align-items-center justify-content-center fw-semibold'}
        style={{
          backgroundColor: '#f5f5f5',
          // height: 250,
        }}
      >
        No Image
      </div>
    )
  }

  if (listImage.length === 1) {
    return <img src={listImage[0]} className={className} alt={listImage[0]} />
  }

  // map follow format list of "react-image-gallery"
  const imagesMapped = listImage.map((img) => ({
    original: img,
  }))

  return (
    <ImageGallery
      items={imagesMapped}
      autoPlay
      showPlayButton={false}
      showFullscreenButton={false}
    />
  )
}

MySlider.propTypes = {
  listImage: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default MySlider
