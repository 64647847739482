import UserPage from '_pages/User'
import React from 'react'
import './style.scss'

function UserProfile() {
  return (
    <div className='user__profile-desktop'>
      <div className='mx-auto pb-3 container'>
        <UserPage />
      </div>
    </div>
  )
}

export default UserProfile
