import React from 'react'

export default function ButtonCollectionToday({
  collectionTimeStart,
  collectionTimeEnd,
  isDisable,
}) {
  return (
    <div>
      <a
        href=''
        className='btn-purchase btn-purchase-04'
        style={isDisable ? { backgroundColor: 'gray' } : {}}
      >
        <span>
          {`Collection today ${
            collectionTimeStart && collectionTimeEnd
              ? `(${collectionTimeStart} - ${collectionTimeEnd})`
              : ''
          }`}
          <small>Click to acknowledge collection</small>
        </span>
      </a>
    </div>
  )
}
