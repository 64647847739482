/* eslint-disable no-lonely-if */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import ModalHitPayPayment from '_components/ModalHitPayPayment'
import useQuery from '_hooks/useQuery'
import { getGroupById, getGroupDetailById, updateGroup } from '_redux/modules/group'
import { getListInvoicePremiumAccount } from '_redux/modules/invoice'
import { getListPackage } from '_redux/modules/package'
import {
  CO_BEE_FEATURE_CANCEL_STATUS,
  GROUP_IS_PRIVATE,
  GROUP_IS_PUBLIC,
  PACKAGE_TYPE_PREMIUM_ACCOUNT,
  RELEASE_DATE_EXAMPLE,
} from '_utils/constant'
import { getUserInfo, setUserInfo } from '_utils/localData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import GroupDescription from '../components/GroupDescription'
import GroupName from '../components/GroupName'
import GroupQuestion from '../components/GroupQuestion'
import GroupSetting from '../components/GroupSetting'

import GroupContact from '../components/GroupContact'
import './style.scss'

const UpdateGroup = () => {
  const history = useHistory()
  const params = useQuery()
  const dispatch = useDispatch()
  const userInfo = getUserInfo()
  const { addToast } = useToasts()

  const idGroupInParam = params.get('id')
  const { myGroups } = useSelector((state) => state.group)
  const { listInvoicePremiumAccount } = useSelector((state) => state.invoice)

  const [groupInfo, setGroupInfo] = useState(
    myGroups?.filter((group) => group.gid === idGroupInParam || group.id === idGroupInParam)[0]
  )

  const validationSchema = Yup.object().shape({
    groupName: Yup.string('Invalid name').required('Invalid name'),
    groupDescription: Yup.string('Invalid description').required('Invalid description'),
    groupQuestion: Yup.string().nullable().notRequired(),
    passCode: Yup.string().nullable().notRequired(),
    contact: Yup.string()
      .required('Please enter contact')
      .test('is-valid-contact', 'Invalid contact format', (value) => {
        if (/^\d{8}$/.test(value)) {
          return true;
        }

        if (value.includes('@')) {
          return true;
        }

        // Optimize WhatsApp link validation
        if (/^(https?:\/\/)?(www\.)?wa\.me\//.test(value)) {
          return true;
        }

        // Optimize website validation
        if (/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|org|net|com\.sg|org\.sg)$/.test(value)) {
          return true;
        }

        if (value.toUpperCase() === 'NA') {
          return true;
        }

        return false;
      }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      groupName: groupInfo?.name,
      groupDescription: groupInfo?.description,
      groupQuestion: groupInfo?.question,
      passCode: groupInfo?.passcode,
      contact: groupInfo?.contact,
    },
  })

  const contactValue = watch('contact')

  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [groupImg, setGroupImg] = useState('')
  const [groupImgUrl, setGroupUrl] = useState(groupInfo?.photoUrl)
  const [groupType, setGroupType] = useState(groupInfo?.public)
  const [defaultRole, setDefaultRole] = useState(groupInfo?.defaultRole)
  const [autoApproval, setAutoApproval] = useState(groupInfo?.autoApproval)
  const [accessMemberList, setAccessMemberList] = useState(groupInfo?.accessMemberList)
  const [isPaymentGroup, setIsPaymentGroup] = useState(false)
  const [currentPlanPremiumAccount, setCurrentPlanPremiumAccount] = useState()
  const [newestPackage, setNewestPackage] = useState(null)

  const { listPackage } = useSelector((state) => state.package)

  useEffect(() => {
    Promise.all([
      dispatch(getListPackage({ packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT })),
      dispatch(getListInvoicePremiumAccount()),
      dispatch(getGroupDetailById(idGroupInParam)),
    ])
  }, [])

  useEffect(() => {
    if (listPackage?.length) {
      setNewestPackage(listPackage[0])
    }
  }, [JSON.stringify(listPackage)])

  useEffect(() => {
    if (listInvoicePremiumAccount?.length) {
      setCurrentPlanPremiumAccount(
        listInvoicePremiumAccount.sort((a, b) => b.createdAt - a.createdAt)[0]
      )
    }
  }, [JSON.stringify(listInvoicePremiumAccount)])

  useEffect(() => {
    if (userInfo?.id) {
      const isOldGroup = false
      const isPremiumAccount = userInfo?.features?.premiumAccount
      const isNotAvailableSubscription =
        currentPlanPremiumAccount?.status === CO_BEE_FEATURE_CANCEL_STATUS

      let isOpen = false
      const isEnabledOpen = !isOldGroup && !isPremiumAccount && groupType !== GROUP_IS_PRIVATE
      const isDisabledOpen = !isOldGroup && isPremiumAccount && !isNotAvailableSubscription

      switch (true) {
        case isEnabledOpen:
          isOpen = true
          break
        case isDisabledOpen:
          isOpen = false
          break
        case isOldGroup:
        case groupType === GROUP_IS_PRIVATE:
          isOpen = false
          break
        default:
          isOpen = false
      }

      setIsPaymentGroup(isOpen)
    }
  }, [groupType, userInfo?.id])

  const toggleModalPaymentGroup = () => setIsPaymentGroup(!isPaymentGroup)

  const onGroupImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setGroupUrl(URL.createObjectURL(img))
      setGroupImg(files)
    }
  }
  const handleContactChange = (event) => {
    const { value } = event.target

    if (/^\d{8}$/.test(value)) {
      setValue('contact', `https://wa.me/${value}`)
    } else if (value.includes('@')) {
      setValue('contact', value)
    } else if (/^(https?:\/\/)?(www\.)?wa\.me\//.test(value)) {
      setValue('contact', value.startsWith('http') ? value : `https://${value}`)
    } else if (/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|org|net|com\.sg|org\.sg)$/.test(value)) {
      setValue('contact', value.startsWith('http') ? value : `https://${value}`)
    } else if (value.toUpperCase() === 'NA') {
      setValue('contact', value.toUpperCase())
    } else {
      setValue('contact', value)
    }
  }

  const onChangeRadio = (state, value) => {
    if (state === 'groupType') setGroupType(value)
    else if (state === 'defaultRole') setDefaultRole(value)
    else if (state === 'accessMemberList') setAccessMemberList(value)
    else setAutoApproval(value)
  }

  const onSubmit = async (data) => {
    const postData = {
      name: data.groupName,
      description: data.groupDescription,
      photoUrl: groupImgUrl,
      passcode: data.passCode,
      question: data.groupQuestion,
      public: groupType,
      defaultRole,
      autoApproval,
      accessMemberList,
      contact: contactValue,
    }

    const isPremiumAccount = userInfo?.features?.premiumAccount
    if (!isPremiumAccount && postData.public === GROUP_IS_PUBLIC) {
      setIsPaymentGroup(true)
      return
    }

    if (groupImg) {
      const { msgResp: res } = await uploadApi.uploadPhoto(groupImg)
      postData.photoUrl = res.url
    }

    try {
      await dispatch(
        updateGroup({
          id: idGroupInParam,
          postData,
          groupType,
          packageId: newestPackage?.id,
          startDate: moment().format('YYYY-MM-DD'),
          packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
          releaseDate: RELEASE_DATE_EXAMPLE,
        })
      )
      addToast('Updated group successfully', { appearance: 'success', autoDismiss: true })
      history.push(`/group/list-members?groupId=${idGroupInParam}`)
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    dispatch(getGroupById(idGroupInParam))

    const groupNeed = myGroups?.filter(
      (group) => group.gid === idGroupInParam || group.id === idGroupInParam
    )[0]

    setGroupInfo(groupNeed)
    setValue('groupName', groupNeed?.name)
    setValue('groupDescription', groupNeed?.description)
    setValue('groupQuestion', groupNeed?.question)
    setValue('passCode', groupNeed?.passcode)
    setValue('contact', groupNeed?.contact)
    setGroupUrl(groupNeed?.photoUrl)
    setGroupType(groupNeed?.public)
    setDefaultRole(groupNeed?.defaultRole)
    setAutoApproval(groupNeed?.autoApproval)
    setAccessMemberList(groupNeed?.accessMemberList)
  }, [JSON.stringify(myGroups)])

  const confirmPayment = async (e) => {
    e.preventDefault()

    setIsSubmittedPayment(true)

    try {
      const { msgResp } = await userApi.upgradeToPremium({
        packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
        packageId: newestPackage?.id,
        startDate: moment().format('YYYY-MM-DD'),
      })

      localStorage.setItem('newestTransaction', JSON.stringify(msgResp))

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }

      await dispatch(getListInvoicePremiumAccount())
      userApi
        .getUser()
        .then((res) => {
          setUserInfo(res.msgResp)
        })
        .catch((error) => {
          addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
        })

      addToast('Payment success', { appearance: 'success', autoDismiss: true })
      setIsSubmittedPayment(false)

      toggleModalPaymentGroup()
    } catch (error) {
      setIsSubmittedPayment(false)
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      toggleModalPaymentGroup()
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='update-group-page'>
      <div className='c-form c-form--group mt-2'>
        <div className='row'>
          <div className='col-4'>
            <div className='upload-image'>
              <img
                src={groupImgUrl?.url || groupImgUrl}
                alt=''
                className='w-100 h-100'
                style={{ zIndex: 3, objectFit: 'cover' }}
              />
              <div className='upload-image-txt'>
                <i className='icon-capture'></i>
                <span>
                  Add Profile
                  <br />
                  Picture
                </span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      type='file'
                      accept='image/*'
                      name='file_source_01'
                      size='40'
                      className='inputFile'
                      data-error='#error-file_source_01'
                      onChange={onGroupImageUploaded}
                      style={{ zIndex: '999' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <GroupName register={register('groupName')} error={errors?.groupName} />
            <GroupDescription
              register={register('groupDescription')}
              error={errors?.groupDescription}
            />
          </div>
        </div>
        <div className='box-question'>
          <GroupQuestion register={register('groupQuestion')} error={errors?.groupQuestion} />
          <GroupContact
            contactValue={groupInfo?.contact}
            register={{
              ...register('contact', {
                onChange: handleContactChange,
              }),
            }}
            error={errors?.contact}
          />
          <GroupSetting
            groupType={groupType}
            defaultRole={defaultRole}
            autoApproval={autoApproval}
            accessMemberList={accessMemberList}
            onChangeRadio={onChangeRadio}
            register={register('passCode')}
          />
        </div>
      </div>
      <div className='row row--bot align-self-end mt-3'>
        <div className='col-6 m-auto'>
          <button className='btn btn--main' type='submit' disabled={isSubmitting}>
            {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save'}
          </button>
        </div>
      </div>
      <ModalHitPayPayment
        isShow={isPaymentGroup}
        toggle={toggleModalPaymentGroup}
        service={{
          type: 'Upgrade premium account',
          price: newestPackage?.discountPrice || newestPackage?.price || 0,
        }}
        isSubmittedPayment={isSubmittedPayment}
        confirmPayment={confirmPayment}
      />
    </Form>
  )
}

export default UpdateGroup
