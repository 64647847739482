import React from 'react'

const EventDescription = (props) => {
  const { register, error } = props
  return (
    <div className='form-group'>
      <label className='align-self-center'>Description</label>
      <textarea name={register.name} className='form-control' {...register}></textarea>
      <small className='error'>{error}</small>
    </div>
  )
}

export default EventDescription
