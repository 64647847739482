import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ModalMessage({ data, modalMessage, toggleMessage }) {
  return (
    <div>
      <Modal isOpen={modalMessage} toggle={toggleMessage} centered>
        <ModalHeader className='m-0 p-0 border-0'>
          <span className='c-txt-gra'>Comment by {data.uName}</span>
        </ModalHeader>
        <ModalBody className='m-0 p-0 border-0 text-center my-3'>{data.comment}</ModalBody>
        <ModalFooter className='d-flex justify-content-center flex-row border-0'>
          <div className='col-8 px-0 m-0' style={{ flexBasis: '70%' }}>
            <button className='btn btn--org btn--org02' onClick={toggleMessage}>
              OK
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
