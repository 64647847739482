/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import orderApi from '_api/order'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { updateDeliveryCombinedPayment } from '_redux/modules/delivery'
import {
  getListCombinedPayment,
  updateOrderCombinedPayment,
  updateOrderStatus,
} from '_redux/modules/event'
import { handleDataOrder } from '_utils/function'

import useQuery from '_hooks/useQuery'
import {
  BUYER_CANCELED,
  BUYER_COLLECTED,
  BUYER_PAID,
  BUYER_REFUNDED,
  BUYER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_COLLECTED,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import ModalDetailOrder from '../components/ModalDetailOrder'
import ModalMessage from '../components/ModalMessage'
import ModalPaymentScreen from '../components/ModalPaymentScreen'
import ModalWarning from '../components/ModalWarning'

export default function OrderList({ listOrder, setSorting, id, setTimeRender }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const params = useQuery()
  const isEventShop = params.get('eventShop')

  const query = useQuery()
  const isByAdmin = query.get('isByAdmin') === 'true'

  const { eventAndOrderById, listCombinedPayments } = useSelector((state) => state.event)

  const eventIncludeDelivery = false
  const headers = [
    { name: 'No', field: 'eOrderNo', className: 'col-1', type: 'number', sortable: true },
    { name: 'Name', field: 'uName', className: 'col-3', type: 'string', sortable: true },
    {
      name: 'Price',
      field: 'totalFee',
      type: 'number',
      className: 'col-2 text-center',
      sortable: true,
    },
    {
      name: 'Status',
      field: 'buyerStatus',
      type: 'number',
      className: eventIncludeDelivery ? 'col-2 text-center' : 'col-3 text-center',
      sortable: true,
    },
    {
      name: 'Updates',
      field: 'status',
      type: 'number',
      className: 'col-3 text-center',
      sortable: true,
    },
    {
      name: '',
      field: '',
      type: '',
      className: eventIncludeDelivery ? 'col-1' : '',
      sortable: false,
    },
  ]

  const [modalMessage, setModalMessage] = useState(false)
  const [modalPaymentScreen, setModalPaymentScreen] = useState(false)
  const [modalDetailOrder, setModalDetailOrder] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetailOrder, setDataModalDetailOrder] = useState({})

  const [loading, setLoading] = useState(true)

  const initListOrder = async (_data) => {
    try {
      const listCombinedPaymentRef = [
        ...new Set(_data.map(({ combinedPaymentRef = '' }) => combinedPaymentRef).filter(Boolean)),
      ]

      if (listCombinedPaymentRef.length) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef, isEventShop))
      }
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (listOrder) {
      initListOrder(listOrder)
    } else {
      setLoading(false)
    }
  }, [JSON.stringify(listOrder)])

  const toggleMessage = () => setModalMessage(!modalMessage)

  const togglePaymentScreen = () => setModalPaymentScreen(!modalPaymentScreen)

  const toggleDetailOrder = () => {
    setModalDetailOrder(!modalDetailOrder)
  }

  const toggleWarning = () => setModalWarning(!modalWarning)

  const updateOrderStatusAPI = async (_ref, _data) => {
    dispatch(updateOrderStatus(id, _ref, _data.status, _data.buyerStatus))
    await orderApi.updateOrderStatusAndBuyerStatus(_ref, { status: _data.status }, isEventShop)
    addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    setTimeRender((prevState) => prevState + 1)
  }

  const updateOrderStatusAndBuyerStatusAPI = async (_ref, _data) => {
    dispatch(updateOrderStatus(id, _ref, _data.status, _data.buyerStatus))
    await orderApi.updateOrderStatusAndBuyerStatus(_ref, _data, isEventShop)
    addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    setTimeRender((prevState) => prevState + 1)
  }

  const updateOrderCombinedPaymentAPI = async (_data) => {
    dispatch(updateOrderCombinedPayment(_data.combinedPaymentRef, _data.status, _data.placerStatus))
    dispatch(
      updateDeliveryCombinedPayment(_data.combinedPaymentRef, _data.status, _data.placerStatus)
    )
    await orderApi.updateInvoicesStatus(_data, isEventShop)
    addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    setTimeRender((prevState) => prevState + 1)
  }

  const onChangeSelect = async (e, ref, _status, _buyerStatus, _combinedPaymentRef) => {
    try {
      if (
        _status === HOST_ORDER_UNPAID &&
        (Number(e.target.value) === HOST_ORDER_VERIFIED ||
          Number(e.target.value) === HOST_ORDER_COLLECTED)
      ) {
        const _data = { status: Number(e.target.value), buyerStatus: BUYER_PAID }
        await updateOrderStatusAndBuyerStatusAPI(ref, _data)
      } else if (
        _status !== HOST_ORDER_CANCELED &&
        Number(e.target.value) === HOST_ORDER_CANCELED &&
        _buyerStatus > BUYER_UNPAID
      ) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: HOST_ORDER_CANCELED,
            placerStatus: BUYER_REFUNDED,
          }
          await updateOrderCombinedPaymentAPI(_data)
        } else {
          const _data = { status: Number(e.target.value), buyerStatus: BUYER_REFUNDED }
          await updateOrderStatusAndBuyerStatusAPI(ref, _data)
        }
      } else if (
        _status === HOST_ORDER_CANCELED &&
        Number(e.target.value) !== HOST_ORDER_CANCELED &&
        _buyerStatus === BUYER_REFUNDED
      ) {
        const _data = { status: Number(e.target.value), buyerStatus: BUYER_PAID }
        await updateOrderStatusAndBuyerStatusAPI(ref, _data)
      } else {
        const _data = { status: Number(e.target.value), buyerStatus: _buyerStatus }
        await updateOrderStatusAPI(ref, _data)
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      setTimeRender((prevState) => prevState + 1)
    }
  }

  const openMessageModal = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleMessage()
  }

  const openPaymentScreen = (e, order) => {
    e.preventDefault()

    const { combinedPaymentRef = '' } = order
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef) {
      const exitCombinedPayment = listCombinedPayments?.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment
          // exclude current order
          .forEach((element) => {
            const { eid, ref: elementRef } = element
            const { orders, adminCost, discount, deliveryCost, productIdList } =
              eventAndOrderById.find((item) => item.id === eid) || {}
            const { listOrder: otherListOrder } = handleDataOrder(
              orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList
            )
            const otherOrder = otherListOrder.find((item) => item.ref === elementRef)
            listCombinedOrder = [...listCombinedOrder, otherOrder]
          })
        listCombinedDelivery = [...deliveryCombinedPayment]
      }
    }
    setDataModal({ ...order, listCombinedOrder, listCombinedDelivery })
    togglePaymentScreen()
  }

  const handleInvalidPayment = async (ref, _status, _combinedPaymentRef) => {
    if (isByAdmin) {
      addToast('You are not allowed to cancel order', { appearance: 'error', autoDismiss: true })
      return
    }

    try {
      const r = confirm('Are you sure this is invalid payment?')
      if (r === true) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: HOST_ORDER_UNPAID,
            placerStatus: BUYER_UNPAID,
          }
          await updateOrderCombinedPaymentAPI(_data)
        } else {
          dispatch(updateOrderStatus(id, ref, _status, BUYER_UNPAID))
          await orderApi.updateOrderBuyerStatus(ref, { buyerStatus: BUYER_UNPAID }, isEventShop)
          setTimeRender((prevState) => prevState + 1)
        }
        togglePaymentScreen()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      setTimeRender((prevState) => prevState + 1)
      togglePaymentScreen()
    }
  }

  const handleShowDetail = (e, order) => {
    e.preventDefault()
    setDataModalDetailOrder(order)
    toggleDetailOrder()
  }

  const handleCancelOrder = async (e, ref, _status, _buyerStatus, _combinedPaymentRef) => {
    e.preventDefault()

    if (isByAdmin) {
      addToast('You are not allowed to cancel order', { appearance: 'error', autoDismiss: true })
      return
    }

    try {
      if (_status !== HOST_ORDER_CANCELED && _buyerStatus > BUYER_UNPAID) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: HOST_ORDER_CANCELED,
            placerStatus: BUYER_REFUNDED,
          }
          await updateOrderCombinedPaymentAPI(_data)

          toggleDetailOrder()
          toggleWarning()
        } else {
          const _data = { status: HOST_ORDER_CANCELED, buyerStatus: BUYER_REFUNDED }
          await updateOrderStatusAndBuyerStatusAPI(ref, _data)

          toggleDetailOrder()
          toggleWarning()
        }
      } else {
        const _data = { status: HOST_ORDER_CANCELED, _buyerStatus }
        await updateOrderStatusAPI(ref, _data)

        toggleDetailOrder()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetailOrder()
      toggleWarning()
    }
  }

  const handleOpenWarning = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleWarning()
  }

  return (
    <div className='list-event'>
      <>
        <TableHeaderWithSort
          headers={headers}
          onSorting={(field, order, type) => setSorting({ field, order, type })}
          defaultSortingField='eOrderNo'
          defaultSortingOrder='asc'
          className={'row'}
          style={{ fontSize: '0.8rem', color: 'rgb(81, 170, 170)', fontWeight: 500 }}
        />
        {loading ? <div>Loading...</div> : null}
        {!loading && listOrder && listOrder.length > 0
          ? listOrder.map((order, index) => (
              <div key={index}>
                <div
                  className='list-event__item'
                  style={index === 0 ? { borderTop: '1px solid rgba(134, 131, 132, 0.9)' } : {}}
                >
                  <div className='row'>
                    <div className='col-1' style={{ fontWeight: 'bold' }}>
                      {order.eOrderNo}
                    </div>
                    <div className='col-3'>
                      <h4>
                        <a href='#' onClick={(e) => handleShowDetail(e, order)}>
                          {order.uName}
                        </a>
                      </h4>
                      {order.comment && (
                        <a href='#' onClick={(e) => openMessageModal(e, order)}>
                          <i className='icon-new'></i>
                        </a>
                      )}
                    </div>
                    <div className='col-2 text-center'>
                      ${order.totalFee.toFixed(2)}
                      {order.buyerStatus === BUYER_REFUNDED ? ' (Refunded)' : ''}
                    </div>
                    <div className={`${eventIncludeDelivery ? 'col-2' : 'col-3'} text-center`}>
                      {order.buyerStatus === BUYER_UNPAID && (
                        <span className='n-paid' style={{ paddingRight: 0 }}>
                          Not Paid
                        </span>
                      )}
                      {order.buyerStatus === BUYER_PAID && (
                        <a
                          href='#'
                          className={order?.combinedPaymentRef ? 'paid-combined' : 'paid'}
                          data-toggle='modal'
                          data-target='#modal-paid'
                          onClick={(e) => openPaymentScreen(e, order)}
                        >
                          Paid
                        </a>
                      )}
                      {order.buyerStatus === BUYER_CANCELED && <span className=''>Cancelled</span>}
                      {order.buyerStatus === BUYER_COLLECTED && <span className=''>Collected</span>}
                      {order.buyerStatus === BUYER_REFUNDED && <span className=''>Refunded</span>}
                    </div>
                    <div className='col-3'>
                      <select
                        id='paymentStatus_0'
                        className='form-control-payment'
                        value={order.status}
                        disabled={isByAdmin}
                        onChange={(e) =>
                          onChangeSelect(
                            e,
                            order.ref,
                            order.status,
                            order.buyerStatus,
                            order.combinedPaymentRef
                          )
                        }
                        style={{
                          ...(isByAdmin
                            ? {
                                opacity: '0.5',
                                pointerEvents: 'none',
                                filter: 'grayscale(1)',
                              }
                            : {}),
                          color:
                            order.status === HOST_ORDER_UNPAID
                              ? '#ff6903'
                              : order.status === HOST_ORDER_VERIFIED
                              ? '#51aaaa'
                              : '#7c797a',
                        }}
                      >
                        <option value={HOST_ORDER_CANCELED}>Cancelled</option>
                        <option value={HOST_ORDER_UNPAID}>Unpaid</option>
                        <option value={HOST_ORDER_VERIFIED}>Verified</option>
                        <option value={HOST_ORDER_COLLECTED}>Collected</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </>
      {Object.keys(dataModal).length !== 0 && (
        <ModalMessage data={dataModal} modalMessage={modalMessage} toggleMessage={toggleMessage} />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalPaymentScreen
          data={dataModal}
          modalPaymentScreen={modalPaymentScreen}
          togglePaymentScreen={togglePaymentScreen}
          handleInvalidPayment={handleInvalidPayment}
        />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalWarning
          data={dataModal}
          modalWarning={modalWarning}
          toggleWarning={toggleWarning}
          handleCancel={handleCancelOrder}
        />
      )}
      {Object.keys(dataModalDetailOrder).length !== 0 && (
        <ModalDetailOrder
          id={id}
          order={dataModalDetailOrder}
          modalDetailOrder={modalDetailOrder}
          toggleDetailOrder={toggleDetailOrder}
          handleOpenWarning={handleOpenWarning}
          toggleWarning={toggleWarning}
          setTimeRender={setTimeRender}
          isEventShop={isEventShop}
        />
      )}
    </div>
  )
}
