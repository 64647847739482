import React from 'react'
import InputField from '_components/Input'

const EventPickupLocation = (props) => {
  const { register, error } = props
  return (
    <div className='form-group'>
      <label>Pick-up Location</label>
      <div>
        <InputField
          className='form-control text-left'
          name={register.name}
          type='text'
          register={register}
        />
        <small className='errorMessage'>{error}</small>
      </div>
    </div>
  )
}

export default EventPickupLocation
