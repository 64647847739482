import React from 'react'
import MyGroupBuy from '_pages/Group/MyGroupBuy'

const MyGroupBuyDesktop = () => (
  <div className='user__profile-desktop'>
    <div className='mx-auto pb-3 container'>
      <MyGroupBuy />
    </div>
  </div>
)

export default MyGroupBuyDesktop
