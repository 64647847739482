/* eslint-disable function-paren-newline */
import React, { useContext, useMemo } from 'react'

import Pagination from '_components/Pagination'
import { EVENT_STATUS_OPEN, ITEMS_PER_PAGE } from '_utils/constant'
import { flexibleSearch, sortListEvents } from '_utils/function'
import moment from 'moment/moment'
import { AllPageContext } from '../../AllPage'
import EventItem from '../components/EventItem'

function RenderList() {
  const {
    event: { listEvent, numberOfPagesEvent, onPageChangeEvent, currentPageEvent, searchEvent },
  } = useContext(AllPageContext)

  const filteredListEvent = useMemo(() => {
    const filteredList = searchEvent
      ? listEvent.filter((eventItem) => flexibleSearch(eventItem.title, searchEvent))
      : listEvent

    const paginatedList = filteredList.slice(
      (currentPageEvent - 1) * ITEMS_PER_PAGE,
      currentPageEvent * ITEMS_PER_PAGE
    )

    return sortListEvents(paginatedList)
  }, [searchEvent, currentPageEvent, JSON.stringify(listEvent)])

  const renderList = () => {
    if (filteredListEvent.length) {
      return filteredListEvent
        ?.filter((item) => item.closingTimestamp > moment().unix())
        ?.filter((item) => item.status === EVENT_STATUS_OPEN)
        .map((event, index) => <EventItem event={event} key={index} />)
    }

    return <h6 className='d-inline-block w-100 text-center fw-semibold mt-5'>No Events Found</h6>
  }

  const renderPagination = () => {
    if (
      (searchEvent && filteredListEvent.length < ITEMS_PER_PAGE) ||
      listEvent.length < ITEMS_PER_PAGE
    ) {
      return null
    }

    return (
      <Pagination
        pages={numberOfPagesEvent}
        onPageChange={onPageChangeEvent}
        currentPage={currentPageEvent}
        className='mt-5'
      />
    )
  }

  return (
    <div className='group-list group-list--event group-list--event02 mt-0'>
      {renderList()}

      {renderPagination()}
    </div>
  )
}

export default RenderList
