import React from 'react'

export default function ButtonPendingConfirmation({ isDisable }) {
  return (
    <div>
      <a
        href='#'
        style={isDisable ? { backgroundColor: 'gray' } : {}}
        className='btn-purchase btn-purchase-05'
      >
        <span>Pending Confirmation</span>
      </a>
    </div>
  )
}
