import dashBoardApi from '_api/dashBoard'

const GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST = 'GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST'
const GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST = 'GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST'
const GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST = 'GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST'
const GET_TOP_PRODUCT_BY_HOST = 'GET_TOP_PRODUCT_BY_HOST'
const GET_SUMMARY_DATA_SUCCESS = 'GET_SUMMARY_DATA_SUCCESS'
const GET_SUMMARY_DATA_FAILURE = 'GET_SUMMARY_DATA_FAILURE'

const initialState = {
  listTotalSalesPerDatByHost: null,
  listTotalOrdersPerDatByHost: null,
  listTotalAverageOrderPerDatByHost: null,
  listTopProductByHost: null,
  summaryData: null,
  errorGetSummary: null,
}

export const getSummaryData = (data) => async (dispatch) => {
  try {
    const { msgResp } = await dashBoardApi.getSummary(data)

    if (msgResp) {
      dispatch({
        type: GET_SUMMARY_DATA_SUCCESS,
        payload: { summaryData: msgResp },
      })
    }
  } catch (error) {
    dispatch({
      type: GET_SUMMARY_DATA_FAILURE,
      payload: { errorGetSummary: error.msgResp },
    })
  }
}

export const getListTotalSalesPerDatByHost = (data) => async (dispatch) => {
  let listTotalSalesPerDatByHost = []
  try {
    const response = await dashBoardApi.getListTotalSales(data)
    if (response.msgResp) {
      listTotalSalesPerDatByHost = response.msgResp
    }
    dispatch({
      type: GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST,
      payload: { listTotalSalesPerDatByHost },
    })
  } catch (err) {}
}
export const getListTotalOrdersPerDatByHost = (data) => async (dispatch) => {
  let listTotalOrdersPerDatByHost = []
  try {
    const response = await dashBoardApi.getListTotalOrders(data)
    if (response.msgResp) {
      listTotalOrdersPerDatByHost = response.msgResp
    }
    dispatch({
      type: GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST,
      payload: { listTotalOrdersPerDatByHost },
    })
  } catch (err) {}
}
export const getListTotalAverageOrderPerDatByHost = (data) => async (dispatch) => {
  let listTotalAverageOrderPerDatByHost = []
  try {
    const response = await dashBoardApi.getListTotalAverageOrder(data)
    if (response.msgResp) {
      listTotalAverageOrderPerDatByHost = response.msgResp
    }
    dispatch({
      type: GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST,
      payload: { listTotalAverageOrderPerDatByHost },
    })
  } catch (err) {}
}
export const getListTopProductByHost = (data) => async (dispatch) => {
  let listTopProductByHost = []
  try {
    const response = await dashBoardApi.getTopProductByHost(data)
    if (response.msgResp) {
      listTopProductByHost = response.msgResp
    }
    dispatch({
      type: GET_TOP_PRODUCT_BY_HOST,
      payload: { listTopProductByHost },
    })
  } catch (err) {}
}

const dashBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_TOP_PRODUCT_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_SUMMARY_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_SUMMARY_DATA_FAILURE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default dashBoardReducer
