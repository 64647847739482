/* eslint-disable multiline-ternary */
import { GMT_0, SHORT_DATE } from '_utils/constant'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const GroupMember = ({ userInfo, role = 'member', onClickPending, to = '', ...props }) => {
  const { orderCount } = userInfo
  return (
    <div className='row mt-2' {...props}>
      <div className='col-3'>
        {moment.unix(userInfo?.createdAt).utcOffset(GMT_0).format(SHORT_DATE)}
      </div>{' '}
      <div className='col-3'>
        {role === 'admin' ? (
          <Link to={to}>{userInfo?.name}</Link>
        ) : (
          <a style={{ cursor: 'pointer' }}>{userInfo?.uName}</a>
        )}
      </div>
      <div className='col-2'>
        {userInfo.totalEvents ? userInfo.totalEvents : 0} <i className='icon-mute'></i>
      </div>
      <div className='col-2'>
        <span className='txt-number txt-number--org'>{orderCount} BUY</span>
      </div>
      <div className='col-2'>
        {role === 'member' ? (
          <a className='link-share'>
            {userInfo?.isAdmin ? 'ADMIN' : userInfo?.isHost ? 'HOST' : 'MEMBER'}
          </a>
        ) : role === 'admin' && userInfo.approval === 1 ? (
          <button className='btn btn--blue' onClick={onClickPending}>
            PENDING
          </button>
        ) : (
          <a className='link-share'>
            {userInfo?.isAdmin ? 'ADMIN' : userInfo?.isHost ? 'HOST' : 'MEMBER'}
          </a>
        )}
      </div>
    </div>
  )
}

GroupMember.propTypes = {
  events: PropTypes.array,
  onClickPending: PropTypes.func,
  role: PropTypes.string,
  userInfo: PropTypes.shape({
    approval: PropTypes.number,
    gid: PropTypes.string,
    isAdmin: PropTypes.number,
    isHost: PropTypes.number,
    name: PropTypes.string,
    uid: PropTypes.string,
  }),
}

export default GroupMember
