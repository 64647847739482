import React from 'react'
import { FormFeedback, FormGroup } from 'reactstrap'

const InputProduct = (props) => {
  const { register, type, label, placeholder, disabled, className, error, ...rest } = props
  const { name } = register
  return (
    <FormGroup>
      <input
        className={className}
        id={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        {...register}
        {...rest}
        onWheel={(event) => { event.target.blur() }}
      />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}

export default InputProduct
