/* eslint-disable function-paren-newline */
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import {
  getListTopProductByHost,
  getListTotalAverageOrderPerDatByHost,
  getListTotalOrdersPerDatByHost,
  getListTotalSalesPerDatByHost,
  getSummaryData,
} from '_redux/modules/dashboard'
import { getGroupIdSelected } from '_utils/localData'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import 'react-datepicker/dist/react-datepicker.css'
import Dashboard1 from './Dashboard1'
import Dashboard2 from './Dashboard2'
import './style.scss'

const nameChart1 = 'sales'
const nameChart2 = 'orders'
const nameChart3 = 'averageOrder'

function Dashboard() {
  const dispatch = useDispatch()
  const { infoGroupSelected, dataWhenSync, setDataWhenSync } = useContext(SelectIdGroupContext)
  const [listTotalSales, setListTotalSales] = useState([])
  const [listTotalOrders, setListTotalOrders] = useState([])
  const [listTotalAverageOrder, setListTotalAverageOrder] = useState([])
  const [listTopProduct, setListTopProduct] = useState([])
  const {
    listTotalSalesPerDatByHost,
    listTotalOrdersPerDatByHost,
    listTotalAverageOrderPerDatByHost,
    listTopProductByHost,
  } = useSelector((state) => state.dashBoard)

  const [show, setShow] = useState(false)
  const [checkInDate, setCheckInDate] = useState(null)
  const [checkOutDate, setCheckOutDate] = useState(null)
  const [typeShowData, SetTypeShowData] = useState('weeks')
  const selectedTypeShow = useRef()

  const [listTotalSaleAfterSort, setListTotalSaleAfterSort] = useState()
  const [listTotalOrdersAfterSort, setListTotalOrdersAfterSort] = useState()
  const [listTotalAverageOrdersAfterSort, setListTotalAverageOrdersAfterSort] = useState()

  const toggleModal = () => {
    setShow(!show)
  }

  const handleCheckInDate = (date) => {
    setCheckInDate(date)
    // setCheckOutDate(null)
  }

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date)
  }

  const handleClearDuplicateInArray = (data, duplicateItem) => {
    if (duplicateItem === 'weeks') {
      const uniqueIDate = []
      const unique = data.filter((element) => {
        const isDuplicate = uniqueIDate.includes(element.weekInYear)

        if (!isDuplicate) {
          uniqueIDate.push(element.weekInYear)
          return true
        }

        return false
      })
      return unique
    }
    if (duplicateItem === 'months') {
      const uniqueIDate = []
      const unique = data.filter((element) => {
        const isDuplicate = uniqueIDate.includes(element.monthInhYear)

        if (!isDuplicate) {
          uniqueIDate.push(element.monthInhYear)
          return true
        }

        return false
      })
      return unique
    }
    const uniqueIDate = []
    const unique = data.filter((element) => {
      const isDuplicate = uniqueIDate.includes(element.year)

      if (!isDuplicate) {
        uniqueIDate.push(element.year)
        return true
      }

      return false
    })
    return unique
  }

  const handleSyncDate = () => {
    const startDate = moment(checkInDate).format('DD-MM-YYYY 00:00')
    const endDate = moment(checkOutDate).format('DD-MM-YYYY 00:00')
    if (startDate && endDate) {
      const data = {
        startDate,
        endDate,
        groupId: infoGroupSelected?.groupId,
      }
      dispatch(getListTotalSalesPerDatByHost(data))
      dispatch(getListTotalOrdersPerDatByHost(data))
      dispatch(getListTotalAverageOrderPerDatByHost(data))
      dispatch(getListTopProductByHost(data))
      dispatch(getSummaryData(data))
      setDataWhenSync({
        startDate: moment(checkInDate).format('DD-MM-YYYY'),
        endDate: moment(checkOutDate).format('DD-MM-YYYY'),
        groupId: infoGroupSelected?.groupId,
      })
      setShow(!show)
    }
  }

  const handleSortDataByWeek = (data, setData, dataReset, nameChart) => {
    const dataAfterFilter = []
    const dataByWeek = []
    for (let i = 0; i < data.length; i++) {
      dataAfterFilter.push(dataReset?.filter((item) => data[i]?.weekInYear === item?.weekInYear))
    }
    for (let k = 0; k < dataAfterFilter.length; k++) {
      let totalValue
      if (nameChart === nameChart1) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalPrice, 0)
      } else if (nameChart === nameChart2) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalOrders, 0)
      } else {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.averageOrderSize, 0)
      }
      dataByWeek.push({
        date: dataAfterFilter[k].flat()[0]?.date,
        weekInYear: dataAfterFilter[k].flat()[0]?.weekInYear,
        totalValue,
        typeShowData: 'weeks',
      })
    }
    setData(dataByWeek)
  }

  const handleSortDataByMonth = (data, setData, dataReset, nameChart) => {
    const dataAfterFilter = []
    const dataByMonth = []
    for (let i = 0; i < data.length; i++) {
      dataAfterFilter.push(dataReset.filter((item) => data[i].monthInhYear === item.monthInhYear))
    }
    for (let k = 0; k < dataAfterFilter.length; k++) {
      let totalValue
      if (nameChart === nameChart1) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalPrice, 0)
      } else if (nameChart === nameChart2) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalOrders, 0)
      } else {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.averageOrderSize, 0)
      }
      dataByMonth.push({
        date: dataAfterFilter[k].flat()[0]?.date,
        monthInhYear: dataAfterFilter[k].flat()[0].monthInhYear,
        totalValue,
        typeShowData: 'months',
      })
    }
    setData(dataByMonth)
  }

  const handleSortDataByYear = (data, setData, dataReset, nameChart) => {
    const dataAfterFilter = []
    const dataByMonth = []
    for (let i = 0; i < data.length; i++) {
      dataAfterFilter.push(dataReset.filter((item) => data[i].year === item.year))
    }
    for (let k = 0; k < dataAfterFilter.length; k++) {
      let totalValue
      if (nameChart === nameChart1) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalPrice, 0)
      } else if (nameChart === nameChart2) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalOrders, 0)
      } else {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.averageOrderSize, 0)
      }
      dataByMonth.push({
        date: dataAfterFilter[k].flat()[0]?.date,
        year: dataAfterFilter[k].flat()[0].year,
        totalValue,
        typeShowData: 'years',
      })
    }
    setData(dataByMonth)
  }

  const handleOnChangeSelect = (e) => {
    SetTypeShowData(e.target.value)
    if (
      listTotalSales.length > 0 &&
      listTotalOrders.length > 0 &&
      listTotalAverageOrder.length > 0
    ) {
      switch (e.target.value) {
        case 'weeks':
          handleSortDataByWeek(
            handleClearDuplicateInArray(listTotalSales, e.target.value),
            setListTotalSaleAfterSort,
            listTotalSales,
            nameChart1
          )
          handleSortDataByWeek(
            handleClearDuplicateInArray(listTotalOrders, e.target.value),
            setListTotalOrdersAfterSort,
            listTotalOrders,
            nameChart2
          )
          handleSortDataByWeek(
            handleClearDuplicateInArray(listTotalAverageOrder, e.target.value),
            setListTotalAverageOrdersAfterSort,
            listTotalAverageOrder,
            nameChart3
          )
          break
        case 'months':
          handleSortDataByMonth(
            handleClearDuplicateInArray(listTotalSales, e.target.value),
            setListTotalSaleAfterSort,
            listTotalSales,
            nameChart1
          )
          handleSortDataByMonth(
            handleClearDuplicateInArray(listTotalOrders, e.target.value),
            setListTotalOrdersAfterSort,
            listTotalOrders,
            nameChart2
          )
          handleSortDataByMonth(
            handleClearDuplicateInArray(listTotalAverageOrder, e.target.value),
            setListTotalAverageOrdersAfterSort,
            listTotalAverageOrder,
            nameChart3
          )
          break
        case 'years':
          handleSortDataByYear(
            handleClearDuplicateInArray(listTotalSales, e.target.value),
            setListTotalSaleAfterSort,
            listTotalSales,
            nameChart1
          )
          handleSortDataByYear(
            handleClearDuplicateInArray(listTotalOrders, e.target.value),
            setListTotalOrdersAfterSort,
            listTotalOrders,
            nameChart2
          )
          handleSortDataByYear(
            handleClearDuplicateInArray(listTotalAverageOrder, e.target.value),
            setListTotalAverageOrdersAfterSort,
            listTotalAverageOrder,
            nameChart3
          )
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    setCheckInDate(moment(dataWhenSync?.startDate, 'DD-MM-YYYY').toDate())
    setCheckOutDate(moment(dataWhenSync?.endDate, 'DD-MM-YYYY').toDate())
  }, [])

  useEffect(() => {
    const dataAfterReset = []
    if (listTotalSalesPerDatByHost) {
      listTotalSalesPerDatByHost?.map((item) =>
        dataAfterReset.push({
          ...item,
          monthInhYear: item.date.slice(3, 10),
          weekInYear: `${moment(item.date, 'DD-MM-YYYY').week()}-${item.date.slice(6, 10)}`,
          year: item.date.slice(6, 10),
        })
      )
      setListTotalSales(dataAfterReset)
    }
    if (dataAfterReset?.length > 0) {
      if (typeShowData === 'weeks') {
        handleSortDataByWeek(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalSaleAfterSort,
          dataAfterReset,
          nameChart1
        )
      } else if (typeShowData === 'months') {
        handleSortDataByMonth(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalSaleAfterSort,
          dataAfterReset,
          nameChart1
        )
      } else if (typeShowData === 'years') {
        handleSortDataByYear(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalSaleAfterSort,
          dataAfterReset,
          nameChart1
        )
      } else {
        handleSortDataByWeek(
          handleClearDuplicateInArray(dataAfterReset, 'weeks'),
          setListTotalSaleAfterSort,
          dataAfterReset,
          nameChart1
        )
      }
    }
  }, [listTotalSalesPerDatByHost])

  useEffect(() => {
    const dataAfterReset = []
    if (listTotalOrdersPerDatByHost) {
      listTotalOrdersPerDatByHost?.map((item) =>
        dataAfterReset.push({
          ...item,
          monthInhYear: item.date.slice(3, 10),
          weekInYear: `${moment(item.date, 'DD-MM-YYYY').week()}-${item.date.slice(6, 10)}`,
          year: item.date.slice(6, 10),
        })
      )
      setListTotalOrders(dataAfterReset)
    }
    if (dataAfterReset?.length > 0) {
      if (typeShowData === 'weeks') {
        handleSortDataByWeek(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalOrdersAfterSort,
          dataAfterReset,
          nameChart2
        )
      } else if (typeShowData === 'months') {
        handleSortDataByMonth(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalOrdersAfterSort,
          dataAfterReset,
          nameChart2
        )
      } else if (typeShowData === 'years') {
        handleSortDataByYear(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalOrdersAfterSort,
          dataAfterReset,
          nameChart2
        )
      } else {
        handleSortDataByWeek(
          handleClearDuplicateInArray(dataAfterReset, 'weeks'),
          setListTotalOrdersAfterSort,
          dataAfterReset,
          nameChart2
        )
      }
    }
  }, [listTotalOrdersPerDatByHost])

  useEffect(() => {
    const dataAfterReset = []
    if (listTotalAverageOrderPerDatByHost) {
      listTotalAverageOrderPerDatByHost?.map((item) =>
        dataAfterReset.push({
          ...item,
          monthInhYear: item.date.slice(3, 10),
          weekInYear: `${moment(item.date, 'DD-MM-YYYY').week()}-${item.date.slice(6, 10)}`,
          year: item.date.slice(6, 10),
        })
      )
      setListTotalAverageOrder(dataAfterReset)
    }
    if (dataAfterReset?.length > 0) {
      if (typeShowData === 'weeks') {
        handleSortDataByWeek(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalAverageOrdersAfterSort,
          dataAfterReset,
          nameChart3
        )
      } else if (typeShowData === 'months') {
        handleSortDataByMonth(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalAverageOrdersAfterSort,
          dataAfterReset,
          nameChart3
        )
      } else if (typeShowData === 'years') {
        handleSortDataByYear(
          handleClearDuplicateInArray(dataAfterReset, typeShowData),
          setListTotalAverageOrdersAfterSort,
          dataAfterReset,
          nameChart3
        )
      } else {
        handleSortDataByWeek(
          handleClearDuplicateInArray(dataAfterReset, 'weeks'),
          setListTotalAverageOrdersAfterSort,
          dataAfterReset,
          nameChart3
        )
      }
    }
  }, [listTotalAverageOrderPerDatByHost])

  useEffect(() => {
    setListTopProduct(listTopProductByHost)
  }, [listTopProductByHost])

  useEffect(() => {
    if (infoGroupSelected?.groupId) {
      const autoCallApiWhenComponentBeMount = () => {
        const lastMonth = moment()
          .subtract(1, 'months')
          .format('DD-MM-YYYY 00:00')
          .replace('01', moment().format('DD'))
        const currentDate = moment().format('DD-MM-YYYY 23:59')
        setDataWhenSync({
          startDate: moment()
            .subtract(1, 'months')
            .format('DD-MM-YYYY')
            .replace('01', moment().format('DD')),
          endDate: moment().format('DD-MM-YYYY'),
          groupId: infoGroupSelected?.groupId || getGroupIdSelected(),
        })
        const data = {
          startDate: lastMonth,
          endDate: currentDate,
          groupId: infoGroupSelected?.groupId || getGroupIdSelected(),
        }
        dispatch(getListTotalSalesPerDatByHost(data))
        dispatch(getListTotalOrdersPerDatByHost(data))
        dispatch(getListTotalAverageOrderPerDatByHost(data))
        dispatch(getListTopProductByHost(data))
        dispatch(getSummaryData(data))
      }
      autoCallApiWhenComponentBeMount()
      // if (!listTotalSalesPerDatByHost) {
      // }
      // if (dataWhenSync?.groupId !== infoGroupSelected?.groupId) {
      //   autoCallApiWhenComponentBeMount()
      // }
    }
  }, [infoGroupSelected?.groupId])

  return (
    <>
      <div className='dashboard__desktop px-5 pt-4'>
        <div className='dashboard__desktop-head'>
          <h3 className='dashboard__desktop-head-title fw-bold'>Dashboard</h3>
          <div className='dashboard__desktop-head-sync'>
            <div className='dashboard__desktop-head-sync-date' onClick={toggleModal}>
              <span className='dashboard__desktop-head-sync-date-start'>
                {dataWhenSync?.startDate}
              </span>{' '}
              -
              <span className='dashboard__desktop-head-sync-date-end'>{dataWhenSync?.endDate}</span>
            </div>
            <div className='dashboard__desktop-head-sync-week'>
              <select
                className='form-select dashBoard-desktop-select'
                ref={selectedTypeShow}
                defaultValue='weeks'
                onChange={(e) => handleOnChangeSelect(e)}
              >
                <option value='weeks'>Weeks</option>
                <option value='months'>Months</option>
                <option value='years'>Years</option>
              </select>
            </div>
          </div>
        </div>
        <Dashboard1
          listTotalOrdersAfterSort={listTotalOrdersAfterSort}
          listTotalAverageOrdersAfterSort={listTotalAverageOrdersAfterSort}
          listTopProduct={listTopProduct}
        />

        <Dashboard2 listTotalSaleAfterSort={listTotalSaleAfterSort} />
      </div>

      <Modal isOpen={show} toggle={toggleModal} centered className='modal-sync-data'>
        <ModalHeader>
          <span className='c-txt-gra sync-data-title'>Sync Data</span>
        </ModalHeader>
        <ModalBody>
          <div className='datePicker'>
            <div className='datePicker__startDay'>
              <div>
                <b>Start Day</b>
              </div>

              <DatePicker
                selected={checkInDate || moment(dataWhenSync?.startDate, 'DD-MM-YYYY').toDate()}
                onChange={handleCheckInDate}
                placeholderText='DD-MM-YYYY'
                dateFormat='dd/MM/yyyy'
                className='w-100'
              />
            </div>

            <div className='datePicker__endDay'>
              <div>
                <b>End Day</b>
              </div>
              <DatePicker
                selected={checkOutDate || moment(dataWhenSync?.endDate, 'DD-MM-YYYY').toDate()}
                minDate={checkInDate}
                onChange={handleCheckOutDate}
                placeholderText='DD-MM-YYYY'
                dateFormat='dd/MM/yyyy'
                className='w-100'
              />
            </div>
          </div>
          <div className='sync-data-btn'>
            <button onClick={handleSyncDate} className='btn btn--org btn--org02'>
              Sync data
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Dashboard
