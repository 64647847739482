import { CollapseContext } from '_context/CollapseMenuContext'
import AllPage from '_pages/AllPage'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import Dashboard from './Dashboard'
import DeliveryBooking from './DeliveryBooking'
import Event from './Event'
import ManageOrder from './ManageOrder'
import Members from './Members'
import Settings from './Settings'
import UserProfile from './UserProfile'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import SwitchMobileModel from './components/SwitchMobileModel'
import ListMyGroupsPage from './pages/ListMyGroups'
import ListMyPurchasesPage from './pages/ListMyPurchases'
import './style.scss'
import MyGroupBuyDesktop from './MyGroupBuy'
import CoHootShop from './CoHootShop'

const WrapAllPage = () => (
  <div className='custom-scrollbar'>
    <AllPage />
  </div>
)

function MainDesktop() {
  const { isCollapse } = useContext(CollapseContext)

  return (
    <React.Fragment>
      <div className='container-fluid main-desktop h-100' style={{ minWidth: '1200px' }}>
        <div className='row h-100'>
          <div
            className={`${isCollapse ? 'col-1' : 'col-2'} position-relative border-right pe-0`}
            style={{ zIndex: 10 }}
          >
            <Sidebar />
          </div>
          <div
            className={`${isCollapse ? 'col-11' : 'col-10'} d-flex flex-column h-100 px-0 `}
            style={{ overflow: 'hidden' }}
          >
            <Switch>
              <Route path='/' exact component={WrapAllPage} />
              <Route path='/settings' component={Settings} />
              <Route path='/user' component={UserProfile} />
              <Route path='/event' component={Event} />
              <Route path='/members' component={Members} />
              <Route path='/group/my-group-buy' component={MyGroupBuyDesktop} />
              <Route path='/co-hoot' component={CoHootShop} />
              <Route path='/manage-orders' component={ManageOrder} />
              <Route path='/list-my-purchases' component={ListMyPurchasesPage} />
              <Route path='/list-my-groups' component={ListMyGroupsPage} />
              <Route path='/group' component={() => <SwitchMobileModel />} />
              <Route path='/delivery-booking' component={DeliveryBooking} />
              <Route path='/dashboard' component={Dashboard} />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MainDesktop
