import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'

import reportApi from '_api/report'
import userApi from '_api/user'
import Password from '_components/Password'

const ChangePassword = () => {
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)

  const [firstPin, setFirstPin] = useState(new Array(4))
  const [secondPin, setSecondPin] = useState(new Array(4))
  const [pinErrorMessage, setPinErrorMessage] = useState('')

  const onPinChangedFirst = (pinEntry, index) => {
    const newPin = [...firstPin]
    newPin[index] = pinEntry
    setFirstPin(newPin)
  }

  const onPinChangedSePIN = (pinEntry, index) => {
    const newPin = [...secondPin]
    newPin[index] = pinEntry
    setSecondPin(newPin)
  }

  const clearState = () => {
    setFirstPin(new Array(4))
    setSecondPin(new Array(4))
    setPinErrorMessage('')
  }

  const validatePIN = (firstPIN, secondPIN) => {
    if ((firstPIN && firstPIN.some((item) => item === undefined)) || firstPIN[0] === undefined) {
      setPinErrorMessage('Invalid password')
      return false
    }
    if ((secondPIN && secondPIN.some((item) => item === undefined)) || secondPIN[0] === undefined) {
      setPinErrorMessage('Invalid re-type password')
      return false
    }
    const isValid =
      firstPin.length === secondPIN.length &&
      firstPin.every((value, index) => value === secondPIN[index])

    if (!isValid) setPinErrorMessage('Password and Confirm Password do not match  !!')
    return isValid
  }

  const onSubmit = () => {
    setIsLoading(true)
    const isValid = validatePIN(firstPin, secondPin)
    if (isValid) {
      userApi
        .changePassword({ newPassword: firstPin.join(''), oldPassword: 'aaaa' }) // oldPassword used to cheat API. However, the server will ignore this variable
        .then(({ msgCode }) => {
          if (msgCode % 100 === 0) {
            addToast('Saved Successfully', { appearance: 'success', autoDismiss: true })
            clearState()
          }
          setIsLoading(false)
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `[Change password 01]${msgCode} - ${msgResp}` })
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
          setIsLoading(false)
        })
    }
  }

  return (
    <React.Fragment>
      <div className='c-txt-14 text-center text-underline'>Update PIN</div>
      {pinErrorMessage && (
        <small className='errorMessage' style={{ color: '#ff9900' }}>
          {pinErrorMessage}
        </small>
      )}
      <div className='c-form c-form--email'>
        <div className='c-txt-org'>Change PIN</div>
        <Password isFirstPin={true} pinLength={4} onPinChanged={onPinChangedFirst} pin={firstPin} />
        <div className='c-txt-gray'>Re-confirm the PIN</div>
        <Password pinLength={4} onPinChanged={onPinChangedSePIN} pin={secondPin} />
      </div>
      <div className='row row--bot align-self-end mt-3'>
        <div className='col-6'>
          <button className='btn btn--solid btn--solid03' onClick={() => {}}>
            CANCEL
          </button>
        </div>
        <div className='col-6'>
          <button className='btn btn--main' onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Spinner color='light' size='sm' /> : 'SAVE'}
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
