import { EVENT_STATUS_OPEN, MENU_ITEMS } from '_utils/constant'
import { getUserInfo } from '_utils/localData'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'

import Logo01 from '_images/copyright.svg'
import Logo02 from '_images/logo_02.svg'

import { SelectIdGroupContext } from '_context/SelectGroupContext'
import './style.scss'

const Header = ({ location }) => {
  const { infoGroupSelected } = useContext(SelectIdGroupContext)

  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const { allEvents } = useSelector((state) => state.event)
  // const { myOrders } = useSelector((state) => state.order)

  const userInfo = getUserInfo()

  // const [totalMyPurchases, setTotalMyPurchases] = useState(0)

  const [totalEvents, setTotalEvents] = useState(0)

  useEffect(() => {
    if (allEvents.length) {
      setTotalEvents(allEvents?.filter((item) => item.status === EVENT_STATUS_OPEN)?.length)
    }
  }, [allEvents.length])

  // useEffect(() => {
  //   const listOrderUnClosed = myOrders.filter((item) => {
  //     const isDisable = isDisableEvent(
  //       item?.status,
  //       item?.buyerStatus,
  //       convertToCurrentGMT(item?.ePickupTime),
  //       item?.ePickupDuration
  //     )
  //     if (!isDisable) {
  //       return item
  //     }
  //     return null
  //   })
  //   setTotalMyPurchases(listOrderUnClosed.length)
  // }, [myOrders])

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])

  return (
    <div className='header align-self-start header-co-bee-desktop'>
      <div className='container-fluid header-co-bee-desktop-box' style={{ height: '56px' }}>
        {userInfo?.groups?.length > 0 ? (
          <div className='main-logo text-left d-flex flex-column'>
            <h4 className='ttl-grp pt-1'>{infoGroupSelected?.groupName}</h4>
            <img src={Logo01} alt='' />
          </div>
        ) : (
          <div id='logoCobee' className='main-logo '>
            <Link to='/'>
              <img src={Logo02} alt={'logo-02'} />
              <span>Good things must share!</span>
            </Link>
          </div>
        )}

        <Link to='/user/profile' className='avatar-top'>
          <div className='avatar-top__email'>
            <span id='totalNotififications'></span> <i className='icon-email'></i>
          </div>
          <div className='avatar-top__img'>
            <img
              id='userAvatar'
              src={
                userInfo?.photoUrl
                  ? userInfo.photoUrl
                  : process.env.REACT_APP_AVATAR_URL + userInfo?.name
              }
              alt=''
            />
          </div>
        </Link>
      </div>
      <div className='nav-menu'>
        <div className='container-fluid header-co-bee-desktop-box'>
          <div className='row'>
            <NavLink
              to={'/'}
              className={'col-4 step-1'}
              isActive={() => pathname === '/'}
              style={{ color: pathname === '/' ? '#f06a0f' : '' }}
            >
              All{' '}
              {pathname === '/' && allEvents?.length ? (
                <span id='totalEvents'>({totalEvents})</span>
              ) : null}
            </NavLink>
            {MENU_ITEMS.map((item, index) => (
              <NavLink
                to={item.path}
                activeClassName={'active'}
                className={`col-4 ${item.className}`}
                key={index}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header)
