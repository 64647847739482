/* eslint-disable array-callback-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import deliveryApi from '_api/delivery'
import { DEFAULT_DELIVERY_OPTION } from '_utils/constant'
import { getCreateEventData, getUserInfo, setCreateEventData } from '_utils/localData'

import DeliveryOptionsModal from './DeliveryOptionsModal'

export const AddDeliveryOption = (props) => {
  const { hiddenDelivery: defaultVal, setValue, getValues } = props
  const { addToast } = useToasts()

  const userInfo = getUserInfo()

  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }

  const { id: userId } = userInfo

  const eventData = getCreateEventData() || {}
  const { deliveryOption = {} } = eventData
  const isEventShop = !!eventData?.shopId

  const [checked, setChecked] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])
  const [deliveryTime, setDeliveryTime] = useState(
    new Date(new Date().getTime() + 24 * 8 * 60 * 60 * 1000)
  )
  const [errorMessageDeliveryZone, setErrorMessageDeliveryZone] = useState('')
  const [deliveryHourStart, setDeliveryHourStart] = useState('10AM')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('1PM')

  useEffect(() => {
    if (eventData?.shopId) {
      setChecked(false)
      setCreateEventData({
        ...eventData,
        addDelivery: false,
      })
    }
  }, [eventData?.shopId])

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleChange = (e) => {
    const isCheck = e.target.checked
    setChecked(isCheck)
    if (isCheck) {
      setOpenModal(true)

      setCreateEventData({
        ...eventData,
        addDelivery: true,
      })
    } else {
      setValue('addDelivery', false)
      setCreateEventData({
        ...eventData,
        addDelivery: false,
      })
    }
  }

  useEffect(() => {
    if (deliveryOption && Object.keys(deliveryOption).length) {
      // eslint-disable-next-line no-shadow
      const { deliveryZones, deliveryTime, deliveryHourStart, deliveryHourEnd } = deliveryOption

      setDeliveryZones([...deliveryZones])
      const deliveryTimeSplit = deliveryTime.split(' ')[0].split('-').reverse()
      setDeliveryTime(
        new Date(deliveryTimeSplit[0], deliveryTimeSplit[1] - 1, deliveryTimeSplit[2])
      )

      setDeliveryHourStart(deliveryHourStart)
      setDeliveryHourEnd(deliveryHourEnd)
    } else {
      const getRemembered = async () => {
        try {
          const { msgResp } = await deliveryApi.getRememberedDeliveryZones(
            eventData?.shopId,
            isEventShop
          )
          setDeliveryZones([...msgResp])
        } catch (error) {
          addToast('Failed to fetch remembered delivery zones', {
            appearance: 'error',
            autoDismiss: true,
          })

          setDeliveryZones([])
        }
      }
      getRemembered()
    }
  }, [Object.keys(deliveryOption).length, checked, isEventShop, eventData?.shopId])

  useEffect(() => {
    setChecked(defaultVal ?? false)
    if (!defaultVal) {
      setValue('deliveryOption', {})
      setValue('addDelivery', false)
      setCreateEventData({
        ...eventData,
        addDelivery: false,
      })
    }
  }, [defaultVal])

  useEffect(() => {
    if (
      getValues()?.collectionDate &&
      getValues()?.collectionTimeStart &&
      getValues()?.collectionTimeEnd
    ) {
      setDeliveryTime(getValues()?.collectionDate)
      setDeliveryHourStart(getValues()?.collectionTimeStart)
      setDeliveryHourEnd(getValues()?.collectionTimeEnd)
      setValue('collectionDate', null)
      setValue('collectionTimeStart', '')
      setValue('collectionTimeEnd', '')
    }
  }, [
    getValues()?.collectionDate,
    getValues()?.collectionTimeStart,
    getValues()?.collectionTimeEnd,
  ])

  const handleOnClickOk = () => {
    const data = deliveryZones.filter((item) => {
      if (item.name && item.price >= 0) {
        return item
      }
    })

    if (data.length === 0) {
      setErrorMessageDeliveryZone('Please add at least one delivery zone')
      return
    }

    setErrorMessageDeliveryZone('')

    // eslint-disable-next-line no-shadow
    const deliveryOption = {
      deliveryZones: data,
      deliveryTime: moment(deliveryTime).format('DD-MM-YYYY'),
      deliveryHourStart,
      deliveryHourEnd,
    }

    setDeliveryZones(JSON.parse(JSON.stringify(data)))
    setCreateEventData({
      ...eventData,
      deliveryOption,
      addDelivery: true,
    })
    setValue('deliveryOption', deliveryOption)
    setValue('addDelivery', true)
    toggleModal()
  }

  const onChangeZoneDelivery = (index, type, value) => {
    deliveryZones[index][type] = value
    setDeliveryZones([...deliveryZones])
  }

  const handleOnRemoveDeliveryZone = (index) => {
    deliveryZones.splice(index, 1)
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const handleRemoveRemember = async () => {
    try {
      const dynamicDeliveryZones = !isEventShop
        ? 'rememberedDeliveryZones'
        : 'shopEventRememberedDeliveryZones'
      await deliveryApi.updateRememberedDeliveryZones(
        userId,
        { [dynamicDeliveryZones]: [] },
        isEventShop
      )
    } catch ({ msgResp }) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryTime(e)
  }

  return (
    <div className='havebg'>
      <div className='row'>
        <div className='col-12'>
          <div className='custom-control custom-checkbox'>
            <input
              id='deliveryOptionCheckBox'
              type='checkbox'
              onChange={(e) => handleChange(e)}
              className='custom-control-input'
              checked={checked}
            />
            <label className='custom-control-label' htmlFor='deliveryOptionCheckBox'>
              Add Delivery Options
            </label>
          </div>
          <small className='error'></small>
        </div>
      </div>
      {openModal && (
        <DeliveryOptionsModal
          modal={openModal}
          toggle={toggleModal}
          handleOnClickOk={handleOnClickOk}
          handleRemoveRemember={handleRemoveRemember}
          deliveryZones={deliveryZones}
          deliveryTime={deliveryTime}
          onChangeZoneDelivery={onChangeZoneDelivery}
          onChangeTimeDelivery={onChangeTimeDelivery}
          handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
          handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
          deliveryHourStart={deliveryHourStart}
          setDeliveryHourStart={setDeliveryHourStart}
          deliveryHourEnd={deliveryHourEnd}
          setDeliveryHourEnd={setDeliveryHourEnd}
          errorMessageDeliveryZone={errorMessageDeliveryZone}
          {...props}
        />
      )}
    </div>
  )
}
