import React from 'react'

export default function Cancel() {
  return (
    <div>
      <div className="row-bot" style={{ display: 'block' }}>
        <div className="text-border">CANCELLED</div>
      </div>
    </div>
  )
}
