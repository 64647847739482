import React from 'react'
import PropTypes from 'prop-types'

const GroupSearch = ({ searchString, onChange }) => (
  <div className='form-group'>
    <i className='icon-search'></i>
    <input
      id='keywordField'
      className='form-control'
      placeholder='Search'
      type='text'
      value={searchString}
      onChange={onChange}
    />
  </div>
)

GroupSearch.propTypes = {
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
}

export default GroupSearch
