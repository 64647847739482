import React from 'react'
import DatePicker from 'react-date-picker'

const CustomDatePicker = (props) => {
  const { value, onChange, minDate, maxDate, format = 'dd-MM-yyyy' } = props
  return (
    <DatePicker
      onChange={onChange}
      value={value}
      className='form-control inputDate'
      calendarIcon={<i className='ico-date' />}
      clearIcon={null}
      format={format}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}

export default CustomDatePicker
