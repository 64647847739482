import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, Spinner } from 'reactstrap'
import authApi from '_api/auth'
import Password from '_components/Password'
import useQuery from '_hooks/useQuery'
import imgTop from '_images/img_top.svg'
import logoLeft from '_images/logo_left.svg'
import { arrayToString } from '_utils/function'
import reportApi from '_api/report'

const ResetPassword = () => {
  const params = useQuery()
  const history = useHistory()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onSubmit',
  })

  const [firstPin, setFirstPin] = useState(new Array(4))
  const [secondPin, setSecondPin] = useState(new Array(4))
  const [pinErrorMessage, setPinErrorMessage] = useState('')

  const onPinChangedFirst = (pinEntry, index) => {
    const newPin = [...firstPin]
    newPin[index] = pinEntry
    setFirstPin(newPin)
  }

  const onPinChangedSePIN = (pinEntry, index) => {
    const newPin = [...secondPin]
    newPin[index] = pinEntry
    setSecondPin(newPin)
  }

  const validatePIN = (firstPIN, secondPIN) => {
    if ((firstPIN && firstPIN.some((item) => item === undefined)) || firstPIN[0] === undefined) {
      setPinErrorMessage('Invalid password')
      return false
    }
    if ((secondPIN && secondPIN.some((item) => item === undefined)) || secondPIN[0] === undefined) {
      setPinErrorMessage('Invalid re-type password')
      return false
    }
    const isValid =
      firstPin.length === secondPIN.length &&
      firstPin.every((value, index) => value === secondPIN[index])

    return isValid
  }

  const onSubmit = async () => {
    const isValid = validatePIN(firstPin, secondPin)
    if (isValid) {
      await authApi
        .searchUser(params?.get('email'))
        .then(async (res) => {
          if (res.msgResp.uid) {
            const patchData = {
              step: 2,
              email: params?.get('email'),
              code: parseInt(params.get('code'), 10),
              newPassword: arrayToString(firstPin),
            }
            await authApi.resetPassword(patchData).then(() => {
              setPinErrorMessage('PIN updated')
              setTimeout(() => {
                history.push('/sign-in')
              }, 1000)
            })
          }
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `[Reset password 01]${msgCode} - ${msgResp}` })
          setPinErrorMessage('Incorrect ID/code')
        })
    }
  }

  return (
    <div className='p-login min-vh-100'>
      <div className='page-login page-login--screen'>
        <div className='main-top align-self-start'>
          <div className='container'>
            <div className='main-logo text-left'>
              <img src={logoLeft} />
            </div>
            <div className='main-img text-center'>
              <img src={imgTop} />
            </div>
          </div>
        </div>
        <div className='main-content'>
          <Form onSubmit={handleSubmit(onSubmit)} className='container'>
            <h2 className='c-ttl text-center'>Set PIN</h2>
            <div className='c-form c-form--email'>
              <div className='c-txt-org text-center'>PIN</div>
              <div id='form-pin'>
                <Password
                  isFirstPin={true}
                  pinLength={4}
                  onPinChanged={onPinChangedFirst}
                  pin={firstPin}
                />
                <div className='c-txt-gray text-center'>Re-type PIN</div>
                <Password pinLength={4} onPinChanged={onPinChangedSePIN} pin={secondPin} />
                {pinErrorMessage && <small className='errorMessage'>{pinErrorMessage}</small>}

                <div className='form-group'>
                  <button className='btn btn--main' type='submit' disabled={isSubmitting}>
                    {isSubmitting ? <Spinner color='light' size='sm' /> : 'SUBMIT'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
