import HitPayLogo from '_images/hitpay-logo.jpg'
import {
  GROUP_IS_HIDDEN,
  GROUP_IS_PRIVATE,
  GROUP_IS_PUBLIC,
  PAYMENT_HIT_PAY,
} from '_utils/constant'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import './style.scss'

function PaymentGroup() {
  const history = useHistory()
  const { groupStored } = useSelector((state) => state.group)
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_HIT_PAY)

  const subTitleClass = 'text-center fw-semibold'

  const onChangePaymentMethod = (e) => {
    const { value } = e.target
    setPaymentMethod(Number(value))
  }

  const renderGroupType = (type) => {
    switch (type) {
      case GROUP_IS_PRIVATE:
        return 'Private'
      case GROUP_IS_PUBLIC:
        return 'Public'
      case GROUP_IS_HIDDEN:
        return 'Hidden'
      default:
        return ''
    }
  }

  const onPayment = () => {
    history.goBack()
  }

  return (
    <div className='payment-group text-dark'>
      <h3 className='fw-bold text-center my-4'>Payment For Group</h3>
      <div className='row'>
        <div className='col-12 col-md-6 border-md-1 border-md-end mb-3 mb-md-0'>
          <p className={subTitleClass}>Group Info</p>
          <ul className='list-unstyled'>
            <li>Name: {groupStored?.name}</li>
            <li>Description: {groupStored?.description}</li>
            <li>Question: {groupStored?.question}</li>
            <li>Contact: {groupStored?.contact}</li>
            <li>Type: {renderGroupType(groupStored?.public)}</li>
          </ul>
        </div>
        <div className='col-12 col-md-6'>
          <p className={subTitleClass}>Payment Method</p>
          <div className='select-payment-method'>
            <div
              className={`form-check rounded position-relative ps-0 ${
                paymentMethod === PAYMENT_HIT_PAY ? 'payment-active' : 'border'
              }`}
            >
              <input
                className='form-check-input position-absolute top-0 opacity-0'
                type='radio'
                name={paymentMethod}
                id={paymentMethod}
                value={paymentMethod}
                checked={paymentMethod === PAYMENT_HIT_PAY}
                onChange={onChangePaymentMethod}
              />
              <label
                className='form-check-label position-absolute w-100 h-100 d-flex justify-content-center cursor-pointer'
                htmlFor={paymentMethod}
              >
                <img src={HitPayLogo} alt='' className='w-75 object-fit-contain' />
              </label>
            </div>
          </div>
          <small>
            Selected payment method: <b>{paymentMethod === PAYMENT_HIT_PAY && 'HitPay'}</b>
          </small>
          <p className='text-center fw-bold mt-3 price'>Price: $15</p>
          <button className='btn btn--main shadow-none rounded-pill' onClick={onPayment}>
            Payment
          </button>
        </div>
      </div>
    </div>
  )
}

export default PaymentGroup
