import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { cacheEnhancer } from 'redux-cache'

import rootReducer from './rootReducer'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware), cacheEnhancer())

export const store = createStore(rootReducer, composedEnhancer)
