/* eslint-disable function-paren-newline */
/* eslint-disable array-callback-return */
import ModalJoin from '_pages/Group/components/ModalJoin'
import { joinGroupWithInvitation } from '_redux/modules/group'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { IoPeopleSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
// import { getAllEventsOpeningLocal } from '_utils/localData'
import { AllPageContext } from '../../AllPage'
import EventSearch from '../components/EventSearch'
import RenderList from './RenderList'
import './style.scss'

export const ListAllEventContext = createContext()

function ListAllEvent() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [group, setGroup] = useState(null)
  const [passCodeValue, setPassCodeValue] = useState('')
  const [questionValue, setQuestionValue] = useState('')
  const [openModalJoinGroup, setOpenModalJoinGroup] = useState(false)

  const contextValue = useContext(AllPageContext)

  if (!contextValue || !contextValue.event) {
    return <div>Context undefined</div> // hoặc return null;
  }

  const {
    event: { onSearchEvent, searchEvent },
  } = contextValue
  const { myGroups } = useSelector((state) => state.group)

  const sharedValues = {
    group,
    setGroup,
    setOpenModalJoinGroup,
  }

  useEffect(() => {
    if (myGroups?.length === 1) {
      setGroup(myGroups[0])
    }
  }, [myGroups?.length])

  useEffect(() => {
    if (!openModalJoinGroup) setGroup({})
  }, [openModalJoinGroup])

  const onChangeSearch = (event) => {
    onSearchEvent(event.target.value)
  }

  const toggle = () => setOpenModalJoinGroup(!openModalJoinGroup)

  const onSubmitJoin = async () => {
    try {
      const postData = {
        groupId: group.id,
        question: group.question,
        answer: questionValue,
        passcode: passCodeValue,
      }

      await dispatch(joinGroupWithInvitation(postData))
    } catch (error) {
      addToast(error?.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      toggle()
    }
  }

  return (
    <div className='row-top event-page mt-5'>
      <div className='row align-items-center mb-4'>
        <div className='col-6'>
          <b>
            <IoPeopleSharp size={22} style={{ transform: 'translateY(-1px)' }} /> Co-Bee Event List
          </b>
        </div>
        <div className='col-6'>
          <EventSearch searchString={searchEvent} onChange={onChangeSearch} />
        </div>
      </div>

      <ListAllEventContext.Provider value={sharedValues}>
        <RenderList />
      </ListAllEventContext.Provider>

      <ModalJoin
        groupInfo={group}
        modal={openModalJoinGroup}
        toggle={toggle}
        onSubmitJoin={onSubmitJoin}
        passCodeValue={passCodeValue}
        setPassCodeValue={setPassCodeValue}
        questionValue={questionValue}
        setQuestionValue={setQuestionValue}
      />
    </div>
  )
}

export default ListAllEvent
