import React from 'react'
import PropTypes from 'prop-types'

export default function OrderConfirmed({ day }) {
  return (
    <div>
      <div className="row-bot">
        <div className="text-border">
          <span>ORDER CONFIRMED<br /><b>Pick Up in {day} Days Time</b>
          </span>
        </div>
      </div>
    </div>
  )
}

OrderConfirmed.propTypes = {
  day: PropTypes.number,
}
