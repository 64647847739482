import axiosClient from './axiosClient'

const invoiceApi = {
  getList: (type) => {
    const url = '/list-co-bee-feature-invoice'
    return axiosClient.get(url, {
      params: {
        packageType: type,
      },
    })
  },
  removeInvoice: ({ invoiceId, packageType }) => {
    const url = '/remove-co-bee-transaction'
    return axiosClient.post(url, { invoiceId, packageType })
  },
  confirmInvoice: ({ packageType, invoiceId, groupId }) => {
    const url = '/check-transaction-before-payment'
    return axiosClient.get(url, {
      params: {
        packageType,
        invoiceId,
        groupId,
      },
    })
  },
  cancelPlan: ({ packageType, packageId }) => {
    const url = '/cancel-co-bee-feature'
    return axiosClient.post(url, { packageId, packageType })
  },
  triggerFeature: ({ packageType, paymentRequestId }) => {
    const url = '/trigger-feature'
    return axiosClient.post(url, { paymentRequestId, packageType })
  },
}

export default invoiceApi
