import { FaCheckCircle } from 'react-icons/fa'
import { IoMdCloseCircle } from 'react-icons/io'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import './style.scss'

const ModalNotify = ({ isOpen, toggle, title, service = {}, showBtnClose, type, onSubmit }) => {
  if (isOpen) {
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='modal-notify-save-card'>
        {title}
        <div
          className={type === 'warning' ? 'bg-warning' : 'bg-success'}
          style={{
            width: '100%',
            height: '100%',
            color: 'white',
            padding: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: '1.5rem',
            fontWeight: 600,
          }}
        >
          {type === 'warning' ? <IoMdCloseCircle size={65} /> : <FaCheckCircle size={65} />}
          <span className='mt-0'>{type === 'warning' ? 'Warning' : 'Success'}</span>
        </div>
        <ModalBody>
          {service?.description && (
            <p
              className={'text-wrap text-center mb-0'}
              style={{
                fontSize: '0.75rem',
              }}
            >
              {service.description}
            </p>
          )}
          <p className='text-center mb-0'>
            <small
              className='fw-semibold'
              style={{
                color: '#102a71',
                fontSize: '0.75rem',
              }}
            >
              Co-bee@gmail.com
            </small>
          </p>
        </ModalBody>
        <ModalFooter>
          {showBtnClose && (
            <button className='btn btn--main' onClick={toggle}>
              Close
            </button>
          )}
          <button
            className={`btn  w-50 mx-auto ${
              type === 'warning' ? 'btn-warning text-dark' : 'btn-success'
            }`}
            type='button'
            onClick={onSubmit}
          >
            Ok
          </button>
        </ModalFooter>
      </Modal>
    )
  }
  return null
}

export default ModalNotify
