import invoiceApi from '_api/invoice'
import {
  CO_BEE_FEATURE_APPROVED_STATUS,
  CO_BEE_FEATURE_CANCEL_STATUS,
  CO_BEE_FEATURE_EXPIRE_PAYMENT_STATUS,
  CO_BEE_FEATURE_PAID_PAYMENT_STATUS,
  PACKAGE_TYPE_COBEE,
  PACKAGE_TYPE_PREMIUM_ACCOUNT,
} from '_utils/constant'

const initialState = {
  listInvoiceGroup: [],
  listInvoiceScheduleEvent: [],
  listInvoicePremiumAccount: [],
}

const GET_INVOICE = 'GET_INVOICE'
const REMOVE_INVOICE = 'REMOVE_INVOICE_GROUP'
const REMOVE_INVOICE_FAILURE = 'REMOVE_INVOICE_GROUP_FAILURE'
const REMOVE_INVOICE_SCHEDULE = 'REMOVE_INVOICE_SCHEDULE'
const REMOVE_INVOICE_SCHEDULE_FAILURE = 'REMOVE_INVOICE_SCHEDULE_FAILURE'
const REMOVE_INVOICE_PREMIUM_ACCOUNT = 'REMOVE_INVOICE_PREMIUM_ACCOUNT'
const REMOVE_INVOICE_PREMIUM_ACCOUNT_FAILURE = 'REMOVE_INVOICE_PREMIUM_ACCOUNT_FAILURE'
const GET_INVOICE_PREMIUM_ACCOUNT = 'GET_INVOICE_PREMIUM_ACCOUNT'
const TRIGGER_PREMIUM_FOR_FIRST = 'TRIGGER_PREMIUM_FOR_FIRST'

export const removeInvoice = (item) => async (dispatch, getState) => {
  const { invoice } = getState()
  const { listInvoicePremiumAccount } = invoice

  try {
    const updatedList = listInvoicePremiumAccount.filter((p) => p.id !== item.id)
    await invoiceApi.removeInvoice({
      invoiceId: item.id,
      packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
    })
    dispatch({
      type: REMOVE_INVOICE,
      payload: { listInvoicePremiumAccount: updatedList },
    })
  } catch (error) {
    dispatch({
      type: REMOVE_INVOICE_FAILURE,
      error: error.message,
    })

    throw new Error(error?.message || 'Failed to remove invoice group')
  }
}

export const getListInvoiceGroup = () => async (dispatch) => {
  try {
    const { msgResp } = await invoiceApi.getList(PACKAGE_TYPE_COBEE)
    dispatch({
      type: GET_INVOICE,
      payload: { listInvoiceGroup: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const getListInvoicePremiumAccount = () => async (dispatch) => {
  try {
    const { msgResp } = await invoiceApi.getList(PACKAGE_TYPE_PREMIUM_ACCOUNT)
    dispatch({
      type: GET_INVOICE_PREMIUM_ACCOUNT,
      payload: { listInvoicePremiumAccount: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const cancelPlan = (item) => async (dispatch, getState) => {
  const { invoice } = getState()
  const { listInvoicePremiumAccount } = invoice
  try {
    await invoiceApi.cancelPlan({ packageId: item.id, packageType: item.packageType })
    const updatedList = listInvoicePremiumAccount.map((invoiceItem) => {
      if (invoiceItem.id === item.id) {
        return {
          ...item,
          status: CO_BEE_FEATURE_CANCEL_STATUS,
          paymentStatus: CO_BEE_FEATURE_EXPIRE_PAYMENT_STATUS,
        }
      }
      return invoiceItem
    })

    dispatch({
      type: REMOVE_INVOICE_PREMIUM_ACCOUNT,
      payload: { listInvoicePremiumAccount: updatedList },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const triggerPremiumForFirst =
  ({ packageType, paymentRequestId }) =>
  async (dispatch, getState) => {
    const { invoice } = getState()
    const { listInvoicePremiumAccount } = invoice

    const updatedList = listInvoicePremiumAccount.map((invoiceItem) => {
      if (invoiceItem.id === paymentRequestId) {
        return {
          ...invoiceItem,
          status: CO_BEE_FEATURE_APPROVED_STATUS,
          paymentStatus: CO_BEE_FEATURE_PAID_PAYMENT_STATUS,
        }
      }
      return invoiceItem
    })

    try {
      await invoiceApi.triggerFeature({ packageType, paymentRequestId })
      dispatch({
        type: TRIGGER_PREMIUM_FOR_FIRST,
        payload: { listInvoicePremiumAccount: updatedList },
      })
    } catch (error) {
      throw new Error(error?.msgResp || error.message)
    }
  }

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_INVOICE:
    case REMOVE_INVOICE_FAILURE:
    case TRIGGER_PREMIUM_FOR_FIRST:
    case GET_INVOICE_PREMIUM_ACCOUNT:
    case REMOVE_INVOICE_PREMIUM_ACCOUNT_FAILURE:
    case REMOVE_INVOICE_PREMIUM_ACCOUNT:
    case REMOVE_INVOICE_SCHEDULE_FAILURE:
    case REMOVE_INVOICE_SCHEDULE:
    case GET_INVOICE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default invoiceReducer
