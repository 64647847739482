/* eslint-disable no-unused-vars */
import { GROUP_APPROVED } from '_utils/constant'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function TopMembers() {
  const { addToast } = useToasts()

  const { summaryData, errorGetSummary } = useSelector((state) => state.dashBoard)

  useEffect(() => {
    if (errorGetSummary) {
      addToast(errorGetSummary, { appearance: 'error', autoDismiss: true })
    }
  }, [])

  const topNewMembers = useMemo(() => summaryData?.summary?.newMemberDetail, [summaryData])
  const page = {
    currentPage: 1,
    ordersPerPage: 10,
  }

  const { currentPage, ordersPerPage } = page

  // Logic for displaying orders
  const indexOfLastOrder = currentPage * ordersPerPage
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage

  const currentData = topNewMembers?.slice(indexOfFirstOrder, indexOfLastOrder)

  return (
    <div className='top-members text-center'>
      <p style={{ color: '#414141' }}>Top 10 Members</p>
      <ul className='top-members_list mb-auto'>
        {currentData?.map((member, index) => (
          <li key={index} className='justify-content-start'>
            {index + 1}.<span className='user-name float-start ms-2'>{member.uName}</span>
            {member.approval === GROUP_APPROVED ? (
              <span className='user-status ms-auto' style={{ color: '#6CCBCB' }}>
                Approved
              </span>
            ) : (
              <span className='user-status ms-auto' style={{ color: '#FF6805' }}>
                Pending
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TopMembers
