import eventApi from '_api/event'
import { DESKTOP, PHONE } from '_utils/constant'
import { getDeviceUser, setDeviceUser } from '_utils/localData'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useToasts } from 'react-toast-notifications'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import MyEventItem from '../components/MyEventItem'

import LoadingOverlay from '../../../components/LoadingOverlay'
import './style.scss'

const ListAllEventPlus = () => {
  const { addToast } = useToasts()

  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [password, setPassword] = useState('')
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => localStorage.getItem('isAuthenticated') === 'true'
  )
  const [modalOpen, setModalOpen] = useState(!isAuthenticated)
  const [passwordError, setPasswordError] = useState('')
  const passwordInputRef = useRef(null)
  const cacheRef = useRef([])

  const fetchEventsData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { msgCode, msgResp } = await eventApi.getAllEvents({
        eventQty: 10,
        isNext: 1,
        // Calculate offset based on page number
        offset: (page - 1) * 10,
      })

      if (msgCode === 95000) {
        setEvents(msgResp)
        setHasNext(msgResp.length === 10)
      } else {
        addToast(`Occur error when loading event: ${msgResp}`, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } catch (error) {
      addToast(`Occur error when loading event: ${error.message || error.msgResp}`, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsLoading(false)
    }
  }, [page])

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('isAuthenticated')
    }

    const userDevice = getDeviceUser()
    if (userDevice === DESKTOP) {
      setDeviceUser(PHONE)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      fetchEventsData()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        if (passwordInputRef.current) {
          passwordInputRef.current.focus()
        }
      }, 100)
    }
  }, [modalOpen])

  const handlePasswordSubmit = () => {
    if (password === 'cohootpassword') {
      setIsAuthenticated(true)
      localStorage.setItem('isAuthenticated', 'true')
      setModalOpen(false)
      setPasswordError('')
    } else {
      setPasswordError('Incorrect password. Please try again.')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handlePasswordSubmit()
  }

  const handlePagination = (direction) => {
    setPage((prevPage) => {
      const newPage = direction === 'next' ? prevPage + 1 : prevPage - 1
      const currentCache = cacheRef.current
      const startIndex = (newPage - 1) * 10
      const endIndex = startIndex + 10

      if (currentCache.length >= endIndex) {
        setEvents(currentCache.slice(startIndex, endIndex))
        setHasNext(currentCache.length > endIndex)
      } else {
        fetchEventsData()
      }

      return newPage
    })
  }

  if (!isAuthenticated) {
    return (
      <Modal
        isOpen={modalOpen}
        toggle={() => {}}
        backdrop='static'
        keyboard={false}
        className='modal-password'
      >
        <ModalHeader className='border-bottom-0 justify-content-center'>
          <span className='mb-0' style={{ color: '#f06a0f', fontSize: '24px' }}>
            Admin Access Required
          </span>
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody className='pt-0 pb-4 px-4'>
            <FormGroup>
              <Label for='password' className='fw-bold mb-2'>
                Admin Password
              </Label>
              <Input
                type='password'
                id='password'
                className='rounded-pill'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Enter admin password'
                innerRef={passwordInputRef}
              />
              {passwordError && <div className='text-danger mt-2'>{passwordError}</div>}
            </FormGroup>
          </ModalBody>
          <ModalFooter className='justify-content-center border-top-0 pt-0 pb-4 px-4'>
            <Button
              color='primary'
              type='submit'
              className='rounded-pill px-4 py-2 w-100 m-0'
              style={{ backgroundColor: '#00a19c', borderColor: '#00a19c' }}
            >
              Access Events
            </Button>
            <Link to='/' className='text-orange text-decoration-underline mt-3 d-block'>
              Return to Home
            </Link>
          </ModalFooter>
        </form>
      </Modal>
    )
  }

  return (
    <div className='row-top event-page'>
      <div className='pagination-buttons mt-3'>
        <h4 className='fw-bold mb-0' style={{ color: '#f06a0f' }}>
          List All Event
        </h4>

        <div className='d-flex justify-content-end'>
          <Button
            className='pagination-button prev me-2'
            onClick={() => handlePagination('prev')}
            disabled={page === 1}
          >
            <RiArrowLeftSLine size={20} />
          </Button>
          <Button
            className='pagination-button next'
            onClick={() => handlePagination('next')}
            disabled={!hasNext}
          >
            <RiArrowRightSLine size={20} />
          </Button>
        </div>
      </div>
      <p className='text-end mb-0'>
        <span className='ms-auto font-size-xs mt-auto'>
          Page {page} | {events.length} items
        </span>
      </p>

      {isLoading ? (
        <div className='d-flex justify-content-center mt-5'>
          <LoadingOverlay />
        </div>
      ) : (
        <div className='group-list group-list--event group-list--event02'>
          {events.map((event) => (
            <div key={event.id}>
              <MyEventItem
                event={event}
                isByAdmin={true}
                setShareLink={() => {}}
                openModal={() => {}}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ListAllEventPlus
