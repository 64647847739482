import React from 'react'

export default function OrderConfirmedAfterClose() {
  return (
    <div>
      <div className="row-bot">
        <div className="text-border">
          <span>ORDER PLACED<br /><b>Payment to be made after closing date</b></span>
        </div>
      </div>
    </div>
  )
}
