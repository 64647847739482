import axiosClient from './axiosClient'

const groupApi = {
  create: (data) => {
    const url = 'create-group'
    return axiosClient.post(url, data)
  },
  getById: (id) => {
    const url = `/get-group?id=${id}`
    return axiosClient.get(url)
  },
  getListGroup: () => {
    const url = '/list-groups'
    return axiosClient.get(url)
  },
  getGroupWithUser: () => {
    const url = '/get-user?getGroups=1'
    return axiosClient.get(url)
  },
  getGroupWithMembers: (data) => {
    const url = `/get-group?id=${data.id}&getMembers=1`
    return axiosClient.get(url)
  },
  updateGroup: (data) => {
    const url = `/update-group?id=${data.id}`
    return axiosClient.patch(url, data.postData)
  },
  changeGroupRole: (data) => {
    const url = '/change-group-role'
    return axiosClient.patch(url, data)
  },
  banMember: (data) => {
    const url = '/ban-member'
    return axiosClient.patch(url, data)
  },
  removeMember: (data) => {
    const url = '/remove-member'
    return axiosClient.delete(url, { data: { ...data } })
  },
  changeVisibleUserGroup: (data) => {
    const url = '/change-visible-user-group'
    return axiosClient.patch(url, { ...data })
  },
  joinGroup: (data) => {
    const url = '/join-group'
    return axiosClient.post(url, data)
  },
  approveGroupJoin: (data) => {
    const url = '/approve-group-join'
    return axiosClient.patch(url, data)
  },
  getMemberInfo: (gid = '') => {
    const url = `/get-member-info?groupId=${gid}`
    return axiosClient.get(url)
  },
  getListOrdersByBuyerInGroup: (userId, groupId) => {
    const url = `/list-orders-by-buyer-in-group?userId=${userId}&groupId=${groupId}`
    return axiosClient.get(url)
  },
}

export default groupApi
