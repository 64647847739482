/* eslint-disable no-shadow */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

export default function TableHeaderWithSort({
  headers,
  onSorting,
  defaultSortingField = '',
  defaultSortingOrder = 'desc',
  className = '',
  style = {},
}) {
  const [sortingField, setSortingField] = useState(defaultSortingField)
  const [sortingOrder, setSortingOrder] = useState(defaultSortingOrder)

  const onSortingChange = (field, type) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    onSorting(field, order, type)
  }

  return (
    <div className={className} style={style}>
      {headers.map(({ name, field, className, type, sortable }) => (
        <div
          className={className}
          key={name}
          onClick={() => (sortable ? onSortingChange(field, type) : null)}
          style={sortable ? { cursor: 'pointer' } : {}}
        >
          {name}
          {sortingField && sortingField === field && (
            <i className={sortingOrder === 'desc' ? 'arrow_carrot-down' : 'arrow_carrot-up'} />
          )}
        </div>
      ))}
    </div>
  )
}

TableHeaderWithSort.propTypes = {
  headers: PropTypes.array,
  onSorting: PropTypes.func,
  defaultSortingField: PropTypes.string,
  defaultSortingOrder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}
