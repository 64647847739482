import GroupList from '_pages/Group/GroupList'
import React from 'react'

function ListMyGroupsPage() {
  return (
    <div className='wrapper overflow-x-scroll'>
      <GroupList />
    </div>
  )
}

export default ListMyGroupsPage
