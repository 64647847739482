import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SocialButton from '_components/SocialButton'

const SignUpOption = ({ setIsSignUpWithPassword, setIsShowFormConnect }) => {
  const [checked, setChecked] = useState(false)

  return (
    <div className='main-content'>
      <div className='container'>
        <h2 className='c-ttl'>Join The Community</h2>
        <div className='c-form c-form--signup'>
          <div className='row'>
            <div className='col-12'>
              <SocialButton
                isLogin={false}
                social='facebook'
                setIsShowFormConnect={setIsShowFormConnect}
              />
            </div>
            <div className='col-12'>
              <SocialButton
                isLogin={false}
                social='google'
                setIsShowFormConnect={setIsShowFormConnect}
              />
            </div>
            <div className='col-12'>
              <button
                className='btn btn--graylight btn--bold'
                href='/sign-up'
                onClick={() => setIsSignUpWithPassword(true)}
              >
                <i className='icon-phone'></i>Sign-Up with Mobile / Email
              </button>
            </div>
          </div>
          <div className='custom-control custom-checkbox'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='customCheck1'
              value={checked}
              onChange={(e) => setChecked(e.target.value)}
              defaultChecked
            />
            <label className='custom-control-label' htmlFor='customCheck1'>
              I want to receive account summary information, special offers and marketing
              communication from Co-bee through email or SMS.
            </label>
            <p
              style={{
                fontSize: '15px',
              }}
            >
              By signing up to Co-bee, I agree {`${''}Co-bee's`} <a href='/policy'>Terms of Service</a>{' '}
              and <a href='/policy'>Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpOption

SignUpOption.propTypes = {
  setIsSignUpWithPassword: PropTypes.func,
  setIsShowFormConnect: PropTypes.func,
}
