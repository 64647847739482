import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form } from 'reactstrap'
import * as Yup from 'yup'

import authApi from '_api/auth'
import InputField from '_components/Input'
import { FACEBOOK, GOOGLE } from '_utils/constant'
import { getSocialToken, getUserInfo, removeSocialToken, setUserToken } from '_utils/localData'

const validationSchema = Yup.object().shape({
  phone: Yup.string('Invalid phone number')
    .typeError('Invalid phone number')
    .required('Invalid phone number')
    .nullable(),
  postalCode: Yup.string().max(6).nullable().notRequired(),
})

const FormConnect = () => {
  const history = useHistory()
  const [userInfo, setUserInfo] = useState(getUserInfo)
  const { addToast } = useToasts()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (data) => {
    try {
      const postData = {
        accessToken: getSocialToken(),
        email: userInfo.email,
        name: userInfo.name,
        phone: data.phone,
      }
      const socialType = userInfo.social
      if (socialType === GOOGLE) {
        postData.linkedGoogleAccount = userInfo.id
      } else if (socialType === FACEBOOK) {
        postData.linkedFBAccount = userInfo.id
      }
      const res = await authApi.register(postData)
      setUserToken(res.msgResp.token)
      removeSocialToken()
      history.push('/welcome')
    } catch ({ msgResp }) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    setUserInfo(getUserInfo)
  }, [localStorage])
  return (
    <div className='main-content'>
      <Form className='container c-form--connect' onSubmit={handleSubmit(onSubmit)}>
        <h2 className='c-ttl c-ttl--01'>
          Create profile to connect<span>to the community nearest to you.</span>
        </h2>
        <div className='c-form c-form--email'>
          <div className='upload-image'>
            <img
              id='userAvatarImg'
              className='card-img-top'
              style={{ width: 107 }}
              src={userInfo?.imageUrl}
              alt=''
            />
          </div>
          <div className='form-group'>
            <InputField
              className='form-control'
              id='phone'
              placeholder='Mobile Number'
              name='phone'
              register={register('phone')}
            />
            {errors?.phone && <small className='errorMessage'>{errors.phone.message}</small>}
          </div>
          <div className='form-group'>
            <InputField
              className='form-control'
              id='postalCode'
              placeholder='Postal Code'
              name='postalCode'
              type='text'
              register={register('postalCode')}
            />
            {errors?.postalCode && (
              <small className='errorMessage'>{errors.postalCode.message}</small>
            )}
          </div>
          <div className='form-group'>
            <button className='btn btn--main' type='submit'>
              NEXT
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default FormConnect
