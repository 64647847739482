import ListMyPurchases from '_pages/Event/ListMyPurchases'
import React from 'react'

function ListMyPurchasesPage() {
  return (
    <div className='custom-scrollbar'>
      <div className='w-50 mx-auto'>
        <ListMyPurchases />
      </div>
    </div>
  )
}

export default ListMyPurchasesPage
