/* eslint-disable no-unused-vars */
import groupApi from '_api/group'
import useQuery from '_hooks/useQuery'
import { getGroupWithEventById } from '_redux/modules/group'
import {
  CO_BEE_FEATURE_CANCEL_STATUS,
  GROUP_IS_PUBLIC,
  PACKAGE_TYPE_COBEE,
  PAYMENT_STATUS_PAID,
  STATUS_PENDING,
} from '_utils/constant'
import { sortListEventByClosingDate } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import LoadingOverlay from '_components/LoadingOverlay'
import { useToasts } from 'react-toast-notifications'
import GroupDetail from '../components/GroupDetail'
import GroupEventItem from '../components/GroupEventItem'
import ModalNotifyExpired from '../components/ModalNotifyExpired'
import ModalNotifySuccess from '../components/ModalNotifySuccess'
import './style.scss'

const ListEvents = () => {
  const { addToast } = useToasts()
  const history = useHistory()
  const params = useQuery()
  const dispatch = useDispatch()
  const idGroupInParam = params.get('groupId')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModalNotifySuccess, setIsOpenModalNotifySuccess] = useState(false)
  const [isOpenModalNotifyExpired, setIsOpenModalNotifyExpired] = useState(false)
  const [isProcessingRenew, setIsProcessingRenew] = useState(false)

  const [domain, setDomain] = useState({})

  const { groupById = {} } = useSelector((state) => state.group)
  const { listInvoiceGroup } = useSelector((state) => state.invoice)
  const { listPackage } = useSelector((state) => state.package)

  const currentUser = getUserInfo()

  const isAllowHost =
    currentUser?.groups?.length && currentUser?.groups?.some((g) => g?.isHost || g?.isAdmin)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const paymentGroup = {
    type: queryParams.get('type'),
    reference: queryParams.get('reference'),
    status: queryParams.get('status'),
  }

  useEffect(() => {
    const getGroup = async () => {
      setIsLoading(true)
      await dispatch(getGroupWithEventById(idGroupInParam))
      setIsLoading(false)
    }

    getGroup()

    if (currentUser) {
      const { domainFeatures = [] } = currentUser
      if (domainFeatures.length) {
        const domainFeaturePaid = domainFeatures.filter(
          (item) =>
            item.domainPaymentStatus >= PAYMENT_STATUS_PAID &&
            item.domainStatus >= STATUS_PENDING &&
            item.groupId === idGroupInParam
        )
        if (domainFeaturePaid.length) {
          setDomain(domainFeaturePaid[0])
        } else {
          setDomain({})
        }
      } else {
        setDomain({})
      }
    }
  }, [])

  useEffect(() => {
    if (paymentGroup.reference) {
      setIsOpenModalNotifySuccess(true)
    }
  }, [paymentGroup.reference])

  const sortedEvents = groupById?.events?.sort((a, b) => a?.createdAt - b?.createdAt)

  const toggleModalNotifySuccess = () => {
    const newUrl = location.pathname + (idGroupInParam ? `?groupId=${idGroupInParam}` : '')
    setIsOpenModalNotifySuccess(!isOpenModalNotifySuccess)
    history.push(newUrl)
  }

  const toggleModalNotifyExpire = () => setIsOpenModalNotifyExpired(!isOpenModalNotifyExpired)

  const currentPlanGroup = listInvoiceGroup?.find((item) => item.groupId === idGroupInParam)

  useEffect(() => {
    if (currentPlanGroup?.status === CO_BEE_FEATURE_CANCEL_STATUS) {
      setIsOpenModalNotifyExpired(true)
    }
  }, [currentPlanGroup])

  const onRenewGroup = async () => {
    try {
      setIsProcessingRenew(true)
      const { msgResp } = await groupApi.paymentCreateGroup({
        packageType: PACKAGE_TYPE_COBEE,
        packageId: listPackage[0]?.packageId,
        groupId: idGroupInParam,
        startDate: moment().format('YYYY-MM-DD'),
        groupType: GROUP_IS_PUBLIC,
      })

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }

      addToast('Renew group success', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error?.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsProcessingRenew(false)
      toggleModalNotifyExpire()
    }
  }

  if (isLoading) return <LoadingOverlay />

  return (
    <div className='row-top list-events-page'>
      <GroupDetail
        idGroupInParam={idGroupInParam}
        groupById={groupById}
        domain={domain}
        setDomain={setDomain}
      />
      <div className='news-group'>
        {isAllowHost && (
          <div className='text-right'>
            <Link
              to={`/event/create?groupId=${groupById?.id}`}
              className='c-txt-14 text-underline'
              style={{ display: 'block' }}
            >
              Host an Event
            </Link>
          </div>
        )}
        <div className='row mt-2'>
          {sortListEventByClosingDate(sortedEvents)?.map((item, index) => (
            <GroupEventItem eventInfo={item} key={index} />
          ))}
        </div>
      </div>

      <ModalNotifySuccess
        isOpenModal={isOpenModalNotifySuccess}
        toggleModal={toggleModalNotifySuccess}
        className={'modal-subscription-success'}
      />

      <ModalNotifyExpired
        isOpen={isOpenModalNotifyExpired}
        toggleModal={() => setIsOpenModalNotifyExpired(!isOpenModalNotifyExpired)}
        style={{ transform: 'translateY(50%)' }}
        onRenewGroup={onRenewGroup}
        isProcessingRenew={isProcessingRenew}
      />
    </div>
  )
}

export default ListEvents
