import React from 'react'
import SelectDate from './SelectDate'
import SelectPaymentStatus from './SelectPaymentStatus'

function SelectFilter({ handleChangeRangeDate, handleChangePaymentStatus, filter }) {
  const { paymentStatus } = filter
  return (
    <div className='d-flex'>
      <SelectDate onChangeRangeDate={handleChangeRangeDate} />
      <SelectPaymentStatus
        onChangePaymentStatus={handleChangePaymentStatus}
        paymentStatus={paymentStatus}
      />
    </div>
  )
}

export default SelectFilter
