/* eslint-disable function-paren-newline */
import LoadingOverlay from '_components/LoadingOverlay'
import Pagination from '_components/Pagination'
import { getListShopPaid } from '_redux/modules/shop'
import { ITEMS_PER_PAGE } from '_utils/constant'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { IoStorefront } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { AllPageContext } from '../AllPage'
import ShopItem from './components/ShopItem'
import './styles.scss'

const ShopList = () => {
  const dispatch = useDispatch()
  const context = useContext(AllPageContext)
  const shop = context?.shop
  const listShopContext = shop?.listShop
  const searchShopContext = shop?.searchShop
  const onSearchShopContext = shop?.onSearchShop
  const numberOfPagesShopContext = shop?.numberOfPagesShop
  const onPageChangeShopContext = shop?.onPageChangeShop
  const currentPageShopContext = shop?.currentPageShop
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const { listShop } = useSelector((state) => state.shop)

  const fetchData = async () => {
    try {
      setLoading(true)
      await dispatch(getListShopPaid())
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pathname === '/co-hoot') {
      fetchData()
    }
  }, [pathname])

  const onPageChange = (page) => {
    if (context) {
      onPageChangeShopContext(page)
    } else {
      setCurrentPage(page)
    }
  }

  const onSearch = (e) => {
    const { value } = e.target
    if (!context) {
      setSearchString(value)
    } else {
      onSearchShopContext(value)
    }
  }

  const filteredList = useMemo(() => {
    let typeList = pathname === '/co-hoot' ? [...listShop] : [...listShopContext]
    const typeCurrentPage = pathname === '/co-hoot' ? currentPage : currentPageShopContext

    if (searchShopContext || searchString) {
      typeList = typeList.filter((shopItem) =>
        shopItem.shopName
          ?.toLowerCase()
          ?.includes(searchShopContext?.toLowerCase() || searchString?.toLowerCase())
      )
    }

    const paginatedList = typeList.slice(
      (typeCurrentPage - 1) * ITEMS_PER_PAGE,
      typeCurrentPage * ITEMS_PER_PAGE
    )

    return paginatedList
  }, [
    currentPage,
    searchShopContext,
    currentPageShopContext,
    searchString,
    JSON.stringify(listShop),
    JSON.stringify(listShopContext),
  ])

  const renderList = () => {
    if (filteredList.length) {
      return filteredList.map((shopItem) => <ShopItem key={shopItem.id} shop={shopItem} />)
    }

    return <h6 className='fw-semibold text-center my-5'>No Shops Found</h6>
  }

  const renderPagination = () => {
    if (
      (searchShopContext && filteredList.length < ITEMS_PER_PAGE) ||
      (searchString && filteredList.length < ITEMS_PER_PAGE) ||
      (context && listShopContext?.length < ITEMS_PER_PAGE) ||
      listShop.length < ITEMS_PER_PAGE
    ) {
      return null
    }

    return (
      <Pagination
        pages={numberOfPagesShopContext || Math.ceil(listShop.length / ITEMS_PER_PAGE)}
        onPageChange={onPageChange}
        currentPage={currentPageShopContext || currentPage}
        className='mt-5'
      />
    )
  }

  if (!context && loading) return <LoadingOverlay />

  return (
    <div className={'get-shop-opening mt-3'}>
      <div className='row mb-4 align-items-center'>
        <div className='col-6'>
          <b>
            <IoStorefront style={{ transform: 'translateY(-2px)' }} /> Co-Hoot Shop List
          </b>
        </div>
        <div className='col-6'>
          <div className='c-form c-form--search event-search'>
            <div className='form-group'>
              <i className='icon-search'></i>
              <input
                className='form-control'
                placeholder='Search'
                type='text'
                value={!context ? searchString : searchShopContext}
                onChange={onSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'shop-list'}>{renderList()}</div>

      {renderPagination()}
    </div>
  )
}

export default ShopList
