/* eslint-disable max-len */
import moment from 'moment'
import axiosClient from './axiosClient'

const eventApi = {
  getListEventsOpening: (groupId = null) => {
    const url = '/list-events-opening'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }

    return axiosClient.get(url)
  },
  getEventDetail: ({
    eid,
    isGetProducts = true,
    isGetBuyerNames = false,
    isGetDeliveryZones = false,
    isEventShop = false,
    isByAdmin = false,
  }) => {
    if (!eid) return null

    const params = new URLSearchParams()
    params.append('id', eid)
    if (isGetProducts) params.append('getProducts', '1')
    if (isGetBuyerNames) params.append('getBuyerNames', '1')
    if (isGetDeliveryZones) params.append('getDeliveryZones', '1')

    const endpointByShop = isEventShop ? '/get-shop-event-detail' : '/get-event'
    const endpointByAdmin = isByAdmin ? '/get-event-by-admin' : endpointByShop

    const url = `${endpointByAdmin}?${params.toString()}`
    return axiosClient.get(url)
  },
  getListEventsByGroupId: (groupId, date) => {
    let url = `/list-events-by-group?groupId=${groupId}`
    if (date) {
      url = `/list-events-by-group?groupId=${groupId}&date=${moment(date).format(
        'DD-MM-YYYY'
      )} 00:00`
    }
    return axiosClient.get(url)
  },
  getListMyEvents: (groupId, isEventShop = false) => {
    const url = !isEventShop ? '/list-my-events' : '/list-my-shop-event'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getShareEventCode: (eventId) => {
    const url = `/update-event-share?id=${eventId}`
    return axiosClient.patch(url)
  },
  getEventById: (id, isEventShop = false) => {
    const endPoint = !isEventShop ? 'get-event' : 'get-shop-event-detail'
    const url = `/${endPoint}?id=${id}&getProducts=1&getDeliveryZones=1`
    return axiosClient.get(url)
  },
  updateEventStatus: (id, data, isEventShop = false) => {
    const endPoint = !isEventShop ? 'update-event-status' : 'update-shop-event-status'
    const url = `/${endPoint}?id=${id}`
    return axiosClient.patch(url, data)
  },
  getEvent: (id, isEventShop = false) => {
    const endPoint = isEventShop ? '/get-shop-event-detail' : '/get-event'
    const url = `${endPoint}?id=${id}&getProduct=1`
    return axiosClient.get(url)
  },
  createEvent: (data, isEventShop = false) => {
    const url = !isEventShop ? '/create-event' : '/create-shop-event'
    return axiosClient.post(url, data)
  },
  updateEvent: (data, isEventShop) => {
    const endPoint = isEventShop ? '/update-shop-event' : '/update-event'
    const url = `${endPoint}?id=${data.id}`
    return axiosClient.patch(url, data)
  },
  deleteEvent: (id, isEventShop = false) => {
    const endPoint = !isEventShop ? 'delete-event' : 'delete-shop-event'
    const url = `/${endPoint}?id=${id}&getProducts=1`
    return axiosClient.delete(url)
  },
  updateEventDiscount: (id, data, isEventShop = false) => {
    const endPoint = !isEventShop ? 'update-event' : 'update-shop-event'
    const url = `/${endPoint}?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventDelivery: (id, data, isEventShop = false) => {
    const endPoint = !isEventShop ? 'update-event' : 'update-shop-event'
    const url = `/${endPoint}?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventRequestPayment: (id, data, isEventShop = false) => {
    const endPoint = !isEventShop ? 'update-event' : 'update-shop-event'
    const url = `/${endPoint}?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventRequestPaymentAndNotify: (id, data, isEventShop = false) => {
    const endPoint = !isEventShop ? 'request-payment' : 'shop-event-request-payment'
    const url = `/${endPoint}?eid=${id}`
    return axiosClient.patch(url, data)
  },
  getShareEvent: ({ eventId, shareCode }) => {
    const url = `/get-share-event?id=${eventId}&shareCode=${shareCode}`
    return axiosClient.get(url)
  },
  createScheduledEvent: (data) => {
    const url = '/create-scheduled-event'
    return axiosClient.post(url, data)
  },
  updateScheduledEvent: (data) => {
    const url = `/update-scheduled-event?id=${data?.id}`
    return axiosClient.patch(url, data)
  },
  getScheduledEvent: (id) => {
    const url = `/get-scheduled-event?id=${id}&getProducts=1`
    return axiosClient.get(url)
  },
  listMyScheduledEvents: (groupId) => {
    const url = '/list-my-scheduled-events'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  listInvoicesCombinedPayment: (combinedPaymentRef, isEventShop = false) => {
    const endPoint = !isEventShop
      ? 'list-invoices-combined-payment-ref'
      : 'list-shop-event-invoice-combined-payment-ref'
    const url = `/${endPoint}?combinedPaymentRef=${combinedPaymentRef}`
    return axiosClient.get(url)
  },
  deleteScheduledEvent: (id) => {
    const url = `/delete-scheduled-event?id=${id}`
    return axiosClient.delete(url)
  },
  getEventsClosePerMonthByHost: (data, groupId) => {
    const url = `/list-event-close-per-month-by-host?month=${data.month}&year=${data.year}&groupId=${groupId}`
    return axiosClient.get(url)
  },
  getEventsCollectionPerMonthByHost: (data, groupId) => {
    const url = `/list-event-collection-per-month-by-host?month=${data.month}&year=${data.year}&groupId=${groupId}`
    return axiosClient.get(url)
  },
  getListEventAndDelivery: (groupId) => {
    const url = `/get-list-orders-and-deliveries-by-host?groupId=${groupId}`
    return axiosClient.get(url)
  },
  getListEventUnAuth: (take, page) => {
    const url = `/list-events-opening-unauth?take=${take}&page=${page}`
    return axiosClient.get(url)
  },
  getAllEventsByGroup: (groupId) => {
    const url = '/list-events-by-group'
    return axiosClient.get(url, {
      params: {
        groupId,
      },
    })
  },
  getListEventValid: () => {
    const url = '/list-shop-paid-public-groupBy'
    return axiosClient.get(url)
  },
  paymentScheduleEvent: ({ packageType, packageId, startDate }) => {
    const url = '/create-transaction-for-co-bee-feature'
    return axiosClient.post(url, {
      packageType,
      packageId,
      start_date: startDate,
    })
  },
  getListMyShopEvents: () => {
    const url = '/list-my-shop-event'
    return axiosClient.get(url)
  },
  checkProductBeforeRemove: (eventId, productId) => {
    const url = '/check-product-before-remove'
    return axiosClient.post(url, {
      eid: eventId,
      pid: productId,
    })
  },
  getAllEvents: ({ eventQty, isNext, timestamp, status }) => {
    const url = '/get-all-event'
    return axiosClient.get(url, {
      params: {
        eventQty,
        isNext,
        timestamp,
        status,
      },
    })
  },
}

export default eventApi
