import React from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Button } from 'reactstrap'

function Pagination({ currentOrders, onHandlePrev, onHandleNext }) {
  return (
    <>
      <span className='me-3 ms-auto'>Viewing 1-10 of {currentOrders ? currentOrders.length : ''}</span>
      <div className='d-flex align-items-center'>
        <Button className='btn-prev rounded-0 shadow-none' outline onClick={(e) => onHandlePrev(e)}>
          <BsChevronLeft />
        </Button>
        <Button className='btn-next rounded-0 shadow-none' outline onClick={(e) => onHandleNext(e)}>
          <BsChevronRight />
        </Button>
      </div>
    </>
  )
}

export default Pagination
