import { BiLinkAlt } from 'react-icons/bi'
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import React from 'react'

const LinkIcons = ({ shop }) => {
  const disableWhatsApp = shop?.whatsApp?.length === 0
  const disableFacebook = shop?.linkFacebook?.length === 0
  const disableInstagram = shop?.linkInstagram?.length === 0
  const disableColor = {
    filter: 'grayscale(100%)',
    pointerEvents: 'none',
    opacity: 0.5,
  }
  return (
    <>
      <a
        href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${shop?.whatsApp}`}
        target='blank'
        className='whats-app'
        style={disableWhatsApp ? disableColor : {}}
      >
        <BsWhatsapp color='#25d366' />
      </a>

      <a
        href={shop?.linkInstagram}
        target='blank'
        className='instagram'
        style={disableInstagram ? disableColor : {}}
      >
        {/* This line used to hover gradient color */}
        <svg width='0' height='0'>
          <radialGradient id='instagram-gradient' r='150%' cx='30%' cy='107%'>
            <stop stopColor='#fdf497' offset='0' />
            <stop stopColor='#fdf497' offset='0.05' />
            <stop stopColor='#fd5949' offset='0.45' />
            <stop stopColor='#d6249f' offset='0.6' />
            <stop stopColor='#285AEB' offset='0.9' />
          </radialGradient>
        </svg>
        <BsInstagram
          style={
            shop.linkInstagram?.length === 0
              ? { filter: 'grayscale(100%)' }
              : {
                  stroke: 'url(#instagram-gradient)',
                  fill: 'url(#instagram-gradient)',
                }
          }
        />
      </a>

      <a
        href={shop?.linkFacebook}
        target='blank'
        className='facebook'
        style={disableFacebook ? disableColor : {}}
      >
        <BsFacebook color='#1778f2' />
      </a>

      <a href={shop?.shopLink} target='blank' className='domain-link'>
        <BiLinkAlt color='#007bff' />
      </a>
    </>
  )
}

export default LinkIcons
