import React, { createContext, useState } from 'react'

export const CollapseContext = createContext()

export default function CollapseProvider({ children }) {
  const [isCollapse, setIsCollapse] = useState(false)
  const [dateWhenSyncData, setDateWhenSyncData] = useState()
  const toggleCollapse = () => {
    setIsCollapse(!isCollapse)
  }

  const values = {
    isCollapse,
    toggleCollapse,
    dateWhenSyncData,
    setDateWhenSyncData,
  }

  return <CollapseContext.Provider value={values}>{children}</CollapseContext.Provider>
}
