import {
  DELIVERY_BUYER_REFUND,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const options = [
  { value: DELIVERY_HOST_CANCELLED, label: 'Cancel' },
  { value: DELIVERY_HOST_UNPAID, label: 'Unpaid' },
  { value: DELIVERY_HOST_PAID, label: 'Paid' },
  { value: DELIVERY_BUYER_REFUND, label: 'Refunded' },
]

function SelectPaymentStatus({ onChangePaymentStatus, paymentStatus }) {
  const [state, setState] = useState({
    selectedOption: [],
  })

  useEffect(() => {
    setState({
      ...state,
      selectedOption: paymentStatus,
    })
  }, [paymentStatus])

  const handleChange = (selectedOption) => {
    setState({ selectedOption })
    onChangePaymentStatus({ selectedOption })
  }

  return (
    <div>
      <Select
        isMulti
        closeMenuOnSelect={false}
        value={paymentStatus}
        onChange={handleChange}
        options={options}
        placeholder='Payment Status'
        className='rounded-0 ms-3 text-orange w-auto'
        styles={{
          control: (provided, stateSelect) => ({
            ...provided,
            borderRadius: '0',
            borderColor: stateSelect.isFocused ? '#fd7e14' : provided.borderColor,
            boxShadow: stateSelect.isFocused ? 'none' : provided.boxShadow,
            '&:hover': {
              borderColor: '#fd7e14',
            },
          }),
        }}
      />
    </div>
  )
}

export default SelectPaymentStatus
