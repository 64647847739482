/* eslint-disable react/no-deprecated */
import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'react-image-gallery/styles/scss/image-gallery.scss'

// This styles.scss must imported below bootstrap css to avoid break layout
import '_styles/styles.scss'
import './index.css'

import App from './pages'

// router in react-connect-router
ReactDOM.render(<App />, document.getElementById('root'))
