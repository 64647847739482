/* eslint-disable react/no-unknown-property */
import React from 'react'

function CardFrame({ cardInfo, buttonEdit }) {
  const renderCard = (cardType, last4, expiry) => {
    switch (cardType) {
      case 'visa': {
        return (
          <div className='credit-card rounded visa'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      case 'mastercard': {
        return (
          <div className='credit-card rounded mastercard'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      case 'amex': {
        return (
          <div className='credit-card rounded amex'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      case 'discover': {
        return (
          <div className='credit-card rounded discover'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      case 'diners': {
        return (
          <div className='credit-card rounded diners'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      case 'jcb': {
        return (
          <div className='credit-card rounded jcb'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      case 'unionpay': {
        return (
          <div className='credit-card rounded unionpay'>
            <div className='credit-card-last4'>{last4}</div>
            <div className='credit-card-expiry'>{expiry}</div>
            {buttonEdit && buttonEdit()}
          </div>
        )
      }

      default:
        break
    }
  }

  return <>{renderCard('visa', cardInfo.cardNumber.slice(-4), cardInfo.expiryDate)}</>
}

export default CardFrame
