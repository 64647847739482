import React from 'react'
import PropTypes from 'prop-types'

const GroupDescription = ({ register, error }) => (
  <div className='form-group'>
    <label className='c-txt-blue'>Group description</label>
    <textarea
      id='groupDescription'
      className='form-control'
      placeholder=''
      type='text'
      {...register}
    />
    <small className='errorMessage'>{error?.message}</small>
  </div>
)

GroupDescription.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  register: PropTypes.object,
}

export default GroupDescription
