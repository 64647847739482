import React from 'react'
import { useHistory } from 'react-router-dom'

export default function BoxPrice({ totalAllOrder, adminCostAllOrder, paymentCollected }) {
  const history = useHistory()

  const handleBackEventList = (e) => {
    e.preventDefault()
    history.push('/event/list-my-events')
  }

  return (
    <div className='row-bot'>
      <div className='text-right price havebg'>
        <p>
          Total Purchase: $<span id='totalAmount'>{totalAllOrder?.toFixed(2)}</span>
        </p>
        <p>
          Admin Fee: $<span id='adminCost'>{adminCostAllOrder?.toFixed(2)}</span>
        </p>
        <p>
          Payment Collected: $<span id='totalConfirmedPaid'>{paymentCollected?.toFixed(2)}</span>
        </p>
      </div>
      <div className='row align-items-center'>
        <div className='col-6 6 m-auto'>
          <a
            className='btn btn--solid btn--solid03'
            onClick={handleBackEventList}
          >
            Exit
          </a>
        </div>
      </div>
    </div>
  )
}
