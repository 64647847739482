import React, { useState, useEffect } from 'react'
import moment from 'moment'
import parse from 'html-react-parser'
import { SHORT_DATE, GMT_0 } from '_utils/constant'
import { convertTimestampToCurrentGMT } from '_utils/function'
import { useDispatch, useSelector } from 'react-redux'
import { getNotifications } from '_redux/modules/user'

const Notifications = () => {
  const dispatch = useDispatch()
  const { notifications: notificationsRedux } = useSelector((state) => state.user)

  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    dispatch(getNotifications)
  }, [])

  useEffect(() => {
    setNotifications(notificationsRedux)
  }, [notificationsRedux])

  const Item = ({ createdAt, senderName, message }) => (
    <div className='noti-list-item row'>
      <div className='col-3'>{moment.unix(convertTimestampToCurrentGMT(createdAt))
        .utcOffset(GMT_0).format(SHORT_DATE)}</div>
      <div className='col-7'>{parse(message)}</div>
      <div className='col-2 text-right'>{senderName}</div>
    </div>
  )

  return (
    <React.Fragment>
      <div className='c-txt-14 text-center text-underline'>Notification</div>
      <div id='itemList' className='noti-list'>
        {notifications.map((notification) => (
          <Item key={notification.id} {...notification} />
        ))}
      </div>
    </React.Fragment>
  )
}

export default Notifications
