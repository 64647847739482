import deliveryApi from '_api/delivery'
import useQuery from '_hooks/useQuery'
import { updateDeliveryComment } from '_redux/modules/delivery'
import {
  DELIVERY_BUYER_PAID,
  DELIVERY_HOST_CANCELLED,
  SINGAPORE_CODE_NO_ADD,
} from '_utils/constant'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ModalDetailDelivery({
  data,
  modalDetail,
  toggleDetail,
  handleOpenWarning,
  deliveryId,
}) {
  const dispatch = useDispatch()
  const query = useQuery()

  const deliveryShop = query.get('deliveryShop')
  const [messageNoteUpdate, setMessageNoteUpdate] = useState('')

  let { suggestedOrders = null } = data

  suggestedOrders = suggestedOrders
    ? suggestedOrders.map(({ eTitle, eOrderNo }) => `${eTitle} (No: ${eOrderNo}))`)
    : null

  const onChangeNote = async (e) => {
    const _data = { comment: e.target.value }
    try {
      await deliveryApi.updateDeliveryBooking(data?.ref, _data, deliveryShop)
      setMessageNoteUpdate('Updated')
      dispatch(updateDeliveryComment(deliveryId, data?.ref, e.target.value))
    } catch (error) {
      const { msgResp } = error
      setMessageNoteUpdate(`Error: ${msgResp}`)
    }
  }

  useEffect(() => {
    if (messageNoteUpdate) {
      const timer = setTimeout(() => {
        setMessageNoteUpdate('')
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [messageNoteUpdate])

  return (
    <div>
      <Modal isOpen={modalDetail} toggle={toggleDetail} centered>
        <ModalHeader style={{ padding: 0, border: 0, width: '100%', display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              height: '32px',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span
              className='pull-left'
              style={{ fontSize: '12px', color: 'rgb(124, 121, 122)', paddingLeft: '5px' }}
            >
              Ref: <span id='orderNo'>{data?.ref?.substring(0, 4)}</span>
            </span>
            {data && data.status !== DELIVERY_HOST_CANCELLED ? (
              <a
                id='cancelBtn'
                href='#'
                className='pull-right'
                onClick={(e) => handleOpenWarning(e, data)}
                style={{
                  fontSize: '12px',
                  color: 'rgb(124, 121, 122)',
                  display: 'inline-block',
                  paddingRight: '5px',
                }}
              >
                Cancel delivery / Refund
              </a>
            ) : null}
          </div>
          <div className='welcome-group welcome-group--white'>
            <div className='welcome-group__img'>
              <div className='c-image-edit'>
                <div className='c-image' style={{ justifyContent: 'center' }}>
                  <img
                    width='100%'
                    height='100%'
                    id='userPhotoUrl'
                    src={
                      data.uPhotoUrl
                        ? data.uPhotoUrl
                        : process.env.REACT_APP_AVATAR_URL + data?.uName
                    }
                    alt=''
                  />
                </div>
                <a
                  id='userWhatsapp'
                  href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${data?.uPhone}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='icon-whatapp' />
                </a>
              </div>
            </div>
          </div>
          <p className='c-txt-14 text-center'>
            <span style={{ fontWeight: 'bold' }}>{data?.uName}</span>
            <small id='userPhone'>{data?.uPhone}</small>
          </p>
        </ModalHeader>
        <ModalBody style={{ marginLeft: '5px', marginRight: '5px', border: 0, padding: 0 }}>
          <div>
            <div className='detail-list-news__item' style={{ color: '#7C797A' }}>
              <div className='row'>
                <div className='col-5'>Zone Name: </div>
                <div className='col-7'>{data.dzName}</div>
              </div>
              <div className='row'>
                <div className='col-5'>Price: </div>
                <div className='col-7'>{data.dzPrice.toFixed(2)}</div>
              </div>
              <div className='row'>
                <div className='col-5'>Address: </div>
                <div className='col-7'>{data.address}</div>
              </div>
              {suggestedOrders && suggestedOrders.length ? (
                <div className='row'>
                  <div className='col-5'>Order(s) suggested: </div>
                  <div className='col-7'>{suggestedOrders.join(', ')}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='detail-list-news__item'>
            <div className='detail-list-news__des mt-1'>
              <span style={{ fontWeight: 'bold' }}>NOTE</span>
              <textarea
                id='comment2'
                cols={30}
                rows={2}
                className='form-control form-control--80'
                onBlur={(e) => onChangeNote(e)}
                defaultValue={data.comment}
              />
              {messageNoteUpdate && (
                <>
                  <br />
                  <span id='commentUpdated' style={{ color: '#51aaaa' }}>
                    {messageNoteUpdate}
                  </span>
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-end flex-row border-0 p-0'>
          <div className='text-right price f-16 pe-3 mx-0'>
            <p>
              Payment Made:
              <span id='paymentStatus' className='t-done'>
                {data.bookerStatus === DELIVERY_BUYER_PAID ? 'Paid' : 'Unpaid'}
              </span>
            </p>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
