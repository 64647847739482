/* eslint-disable max-len */
import eventApi from '_api/event'
import groupApi from '_api/group'
import orderApi from '_api/order'
import reportApi from '_api/report'
import userApi from '_api/user'
import {
  EVENT_PAY_LATER,
  EVENT_STATUS_OPEN,
  GROUP_IS_PRIVATE,
  LIST_TIME,
  SUBITEM_REQUIRED,
} from '_utils/constant'
import { BUYER_NOT_IN_GROUP_CODE } from '_utils/errorCodes'
import { convertToCurrentGMT, formatDate, getDateRemainFromNow } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import parse from 'html-react-parser'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'

import useQuery from '_hooks/useQuery'
import {
  clearEventAndOrder,
  updateEventWhenOrder,
  updateTotalOrderCountWhenUpdateOrder,
} from '_redux/modules/event'
import {
  clearPastPurchasedMember,
  updateTotalOrderCountInGroupWhenOrder,
  updateTotalOrderCountInGroupWhenOrderWhenUpdateOrder,
} from '_redux/modules/group'
import { clearDataPurchaseDetailByEid, clearMyOrder } from '_redux/modules/order'
import MyGallery from '../components/gallery'
import './style.scss'

const ShowListProducts = ({
  productOrderRender,
  formatPrice,
  calculatePricePerProduct,
  findProductById,
  handleChangeSubItem,
  changeProductQuantity,
  addAnotherOrder,
  newOrderProducts,
  getValueSelect,
  deleteItemOrder,
  changeProductQuantityInput,
  checkMoreThanLimitPerOrder,
  edittingOrder,
}) =>
  newOrderProducts &&
  !!productOrderRender.length &&
  productOrderRender &&
  !!productOrderRender.length &&
  productOrderRender.map((p, _index) => {
    const soldOut = p.maxQuantity !== 1000 && p.orderCount >= p.maxQuantity
    // eslint-disable-next-line max-len
    return (
      <div key={_index} className='detail-list-news__item'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='row'>
            {p.photoUrls && p.photoUrls.length !== 0 && (
              <div className='col-3'>
                <MyGallery image={p?.photoUrls[0]} />
              </div>
            )}
            <div className='col-6'>
              <span style={{ color: '#079d9a', fontWeight: 'bold' }}>{p.name}</span>
              <br />
              <span style={{ color: '#07C3C3' }}>{p.description}</span>
              <br />
              <span style={{ color: '#f06a0f' }}>
                ${formatPrice(calculatePricePerProduct(p?.id, p?.index))}
              </span>
              {soldOut ? (
                <>
                  <br />
                  <span style={{ fontSize: '1rem', color: 'red' }}>SOLD OUT</span>
                </>
              ) : null}
              <div className='c-form c-form-select-list'>
                {p.subItems &&
                  p.subItems.map((s, i) => (
                    <div key={i} className='form-item'>
                      <select
                        className='form-control'
                        onChange={(e) =>
                          handleChangeSubItem(
                            p.id,
                            s,
                            e.target.value !== '' ? JSON.parse(e.target.value) : null,
                            p.index
                          )
                        }
                        value={getValueSelect(p.id, p.index, s.type)}
                      >
                        {s.required !== SUBITEM_REQUIRED && <option value={''}>Option</option>}
                        {s.list &&
                          s.list.map((l, index) => (
                            <option
                              key={index}
                              value={JSON.stringify({ name: l.name, price: l.price })}
                            >
                              {l.name} (+${l.price})
                            </option>
                          ))}
                      </select>
                    </div>
                  ))}
              </div>
              <div className='c-txt-gray c-txt-11'>{p.orderCount} order(s) made</div>
              {p.maxQuantity !== 1000 && (
                <div className='c-txt-gray c-txt-11'>
                  Available stock: {p.maxQuantity - p.newOrderCount}
                </div>
              )}
            </div>
            {!soldOut || edittingOrder ? (
              <div className='col-3' style={{ marginLeft: 'auto' }}>
                <div className='quanlity'>
                  <span
                    className='icon-mim'
                    style={{ userSelect: 'none' }}
                    onClick={() =>
                      findProductById(p.id, p.index).quantity !== 0 &&
                      changeProductQuantity({
                        pid: p.id,
                        number: findProductById(p.id, p.index).quantity - 1,
                        index: p.index,
                        type: 'minus',
                      })
                    }
                  >
                    -
                  </span>
                  <span className='icon-input'>
                    <input
                      type='number'
                      value={findProductById(p.id, p.index)?.quantityInput}
                      // eslint-disable-next-line max-len
                      onChange={(e) =>
                        changeProductQuantityInput({
                          pid: p.id,
                          number: parseInt(e.target.value, 10),
                          index: p.index,
                        })
                      }
                      onBlur={(e) =>
                        changeProductQuantity({
                          pid: p.id,
                          number: parseInt(e.target.value, 10),
                          index: p.index,
                        })
                      }
                      className='form-control'
                    />
                  </span>

                  <span
                    className='icon-plugs'
                    style={{ userSelect: 'none' }}
                    onClick={() =>
                      (p.limitPerOrder === 0 ||
                        findProductById(p.id, p.index).quantity < p.limitPerOrder) &&
                      p.maxQuantity - p.newOrderCount > 0 &&
                      !checkMoreThanLimitPerOrder(p.id, p.limitPerOrder) &&
                      changeProductQuantity({
                        pid: p.id,
                        number: findProductById(p.id, p.index).quantity + 1,
                        index: p.index,
                        type: 'plus',
                      })
                    }
                  >
                    +
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          {p.subItems &&
          p.subItems.length > 0 &&
          !checkMoreThanLimitPerOrder(p.id, p.limitPerOrder) ? (
            <div
              className='c-txt-gray'
              style={{ textAlign: 'right', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => addAnotherOrder(p.id)}
            >
              Add Another Order with a different selection
            </div>
          ) : null}
          {p.index > 1 ? (
            <div>
              <div
                className='c-txt-gray'
                style={{ textAlign: 'right', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => deleteItemOrder(p.id, p.index)}
              >
                Remove
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  })

const EventDetail = ({ history }) => {
  const dispatch = useDispatch()
  const params = useQuery()
  const { addToast } = useToasts()
  const userInfo = getUserInfo()
  const shareCode = params.get('shareCode')
  const eid = params.get('eid') || params.get('bid')
  const oid = params.get('oid')
  const isEventShop = params.get('eventShop')

  const [noEventFound, setNoEventFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [eventDetail, setEventDetail] = useState({})
  const [groupDetail, setGroupDetail] = useState({})
  const [isPurchase, setIsPurchase] = useState(false)
  const [newOrderProducts, setNewOrderProducts] = useState([])
  const [error, setError] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [orderNo, setOrderNo] = useState('')
  const [comment, setComment] = useState('')
  const [phoneOrEmail, setPhoneOrEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [checkedNote, setCheckedNote] = useState([])
  const {
    photoUrls,
    createdUserName,
    closingTime,
    title,
    description,
    pickupTime,
    pickupAddress,
    products,
    adminCost,
    paynow = false,
    termConds = [],
    extraFields = [],
  } = eventDetail
  const [collectionTimeStart, setCollectionTimeStart] = useState('')
  const [collectionTimeEnd, setCollectionTimeEnd] = useState('')
  const [productOrderRender, setProductOrderRender] = useState([])
  const [initTotalOrderCount, setInitTotalOrderCount = 0] = useState(0)
  const [checkShareEventHaveGroupIsPrivate, setCheckShareEventHaveGroupIsPrivate] = useState(false)
  const payLater = paynow === EVENT_PAY_LATER
  const adminOrHost = !!groupDetail?.isAdmin || !!groupDetail?.isHost
  const isAllowManageEvent =
    !!groupDetail?.isAdmin || eventDetail?.createdUserId === groupDetail?.uid
  const edittingOrder = !!eid && !!oid && !shareCode
  const helpOrder = !!phoneOrEmail
  // eslint-disable-next-line max-len
  const allowPlaceOrder =
    eventDetail.status === EVENT_STATUS_OPEN &&
    moment(convertToCurrentGMT(eventDetail.closingTime), 'DD-MM-YYYY HH:mm').isAfter(
      moment(new Date())
    )

  useEffect(() => {
    setError('')
  }, [newOrderProducts])

  const listImages = photoUrls?.map((item) => ({ original: item })) || []

  const getEventDetail = async () => {
    try {
      const res = await eventApi.getEventDetail({
        eid,
        isGetProducts: true,
        isGetBuyerNames: true,
        isGetDeliveryZones: false,
        isEventShop: !!isEventShop,
      })

      if (res) {
        setEventDetail(res.msgResp || {})

        if (res.msgResp?.shop?.id) {
          setGroupDetail({ group: res.msgResp.shop })
        }

        return {
          shopId: res.msgResp.shopId,
          groupId: res.msgResp.groupId,
          eventId: res.msgResp.id,
          createdUserId: res.msgResp.createdUserId,
          productsDetail: res.msgResp.products,
          ref: res.msgResp.ref,
        }
      }
    } catch (_error) {
      setNoEventFound(true)
      reportApi.report({
        message: `[Event details 01]${_error.msgCode} - [Event detail] #getEventDetail ${_error.msgResp}`,
      })
      addToast(_error.msgResp, { appearance: 'error', autoDismiss: true })
    }
    setIsLoading(false)
  }

  const getGroupDetail = async (gid = '') => {
    try {
      const res = await groupApi.getMemberInfo(gid)

      if (res) {
        setGroupDetail(res.msgResp || {})
      }
    } catch (err) {
      addToast(err?.msgResp || 'Something went wrong!', { appearance: 'error', autoDismiss: true })
    }
  }

  const getListOrderByRef = async () => {
    try {
      const res = await orderApi.getOrderByRef(oid, isEventShop)
      if (res) {
        return res.msgResp
      }
    } catch (err) {
      addToast(err?.msgResp || 'Something went wrong!', { appearance: 'error', autoDismiss: true })
    }
  }

  const convertProducts = (productsDetail, listOrderByRef) =>
    productsDetail.map((p) => {
      // eslint-disable-next-line max-len
      const quantity =
        listOrderByRef && listOrderByRef.length
          ? listOrderByRef
              .filter((pref) => pref.pid === p.id)
              .reduce((pre, cur) => pre + cur.pQuantity, 0)
          : p.defaultQuantity
      return {
        id: p.id,
        // eslint-disable-next-line max-len
        subItems: p.subItems
          ? p.subItems
              .filter((s) => s.required === SUBITEM_REQUIRED)
              .map((s) => ({
                type: s.type,
                name: s.list[0].name,
                price: s.list[0].price,
                required: s.required,
              }))
          : [],
        quantity: quantity || 0,
        quantityInput: quantity || 0,
        price: p.price,
        limitPerOrder: p.limitPerOrder,
        minOrderQty: p.minOrderQty,
        index: 1,
      }
    })

  const convertProductsWhenEdit = (productsDetail, listOrderByRef) => {
    let newProductsDetail = []
    let newOrderProductsReturn = []
    productsDetail.forEach((p) => {
      // eslint-disable-next-line max-len
      const itemOrderByPid =
        listOrderByRef && listOrderByRef.length
          ? listOrderByRef?.filter((pref) => pref.pid === p.id)
          : []
      if (itemOrderByPid && !!itemOrderByPid.length > 0) {
        itemOrderByPid.forEach((item, index) => {
          newProductsDetail = [
            ...newProductsDetail,
            { ...p, index: index + 1, newOrderCount: p.orderCount },
          ]
          newOrderProductsReturn = [
            ...newOrderProductsReturn,
            {
              id: p.id,
              // eslint-disable-next-line max-len
              subItems:
                item && item.pSubItems && !!item.pSubItems.length > 0 ? [...item.pSubItems] : [],
              quantity: item.pQuantity,
              quantityInput: item.pQuantity,
              price: p.price,
              limitPerOrder: p.limitPerOrder,
              index: index + 1,
            },
          ]
        })
      } else {
        newProductsDetail = [
          ...newProductsDetail,
          { ...p, index: 1, newOrderCount: p.defaultQuantity + p.orderCount },
        ]
        newOrderProductsReturn = [
          ...newOrderProductsReturn,
          {
            id: p.id,
            // eslint-disable-next-line max-len
            subItems: p.subItems
              .filter((s) => s.required === SUBITEM_REQUIRED)
              .map((s) => ({
                type: s.type,
                name: s.list[0].name,
                price: s.list[0].price,
                required: s.required,
              })),
            quantity: p.defaultQuantity,
            quantityInput: p.defaultQuantity,
            price: p.price,
            limitPerOrder: p.limitPerOrder,
            minOrderQty: p.minOrderQty,
            index: 1,
          },
        ]
      }
    })
    setProductOrderRender([...newProductsDetail])
    setNewOrderProducts([...newOrderProductsReturn])
    setInitTotalOrderCount(newOrderProductsReturn.reduce((a, b) => a + b.quantity, 0))
  }

  const initialState = async () => {
    try {
      const { groupId = '', shopId = '', productsDetail = {} } = (await getEventDetail()) || {}
      const userInGroup = userInfo?.groups?.find((g) => g.gid === groupId)
      if ((groupId || shopId) && productsDetail) {
        if (edittingOrder) {
          const listOrderByRef = await getListOrderByRef()
          convertProductsWhenEdit(productsDetail, listOrderByRef)
        } else {
          setNewOrderProducts(convertProducts(productsDetail))
          setProductOrderRender(
            productsDetail.map((p) => ({
              ...p,
              index: 1,
              newOrderCount: (p.defaultQuantity || 0) + p.orderCount,
              quantity: p.defaultQuantity || 0,
              quantityInput: p.defaultQuantity || 0,
            }))
          )
        }

        if (userInGroup) await getGroupDetail(groupId)
      }
      setIsLoading(false)
    } catch (err) {
      addToast(err?.msgResp || 'Something went wrong!', { appearance: 'error', autoDismiss: true })
    }
  }

  const getSharedEvent = async () => {
    try {
      const res = await eventApi.getShareEvent({ eventId: eid, shareCode })
      if (res && res.msgCode % 100 === 0) {
        const { msgResp: _eventDetail } = res || {}
        const { products: _products } = _eventDetail
        setEventDetail(res.msgResp || {})
        setGroupDetail({ group: res.msgResp.group })
        setNewOrderProducts(convertProducts(_products))
        setProductOrderRender(
          _products.map((p) => ({
            ...p,
            index: 1,
            newOrderCount: p.defaultQuantity + p.orderCount,
          }))
        )
      }
    } catch (err) {
      setNoEventFound(true)
      reportApi.report({
        message: `[Event details 02]${err.msgCode} - [Event detail] #getSharedEvent ${err.msgResp}`,
      })
      addToast(err?.msgResp || 'Something went wrong!', { appearance: 'error', autoDismiss: true })
    }
    setIsLoading(false)
  }

  const handleOnClickNote = (index) => {
    checkedNote[index] = !checkedNote[index]
    setCheckedNote([...checkedNote])
  }

  useEffect(() => {
    if (eventDetail && eventDetail.pickupDuration && eventDetail.pickupTime) {
      const pickupTimeCurrentGMT = convertToCurrentGMT(eventDetail.pickupTime)
      const timeStart = Number(pickupTimeCurrentGMT.split(' ')[1].split(':')[0])
      const timeEnd = timeStart + eventDetail?.pickupDuration / 3600
      if (timeEnd <= 24) {
        const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
        const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
        setCollectionTimeStart(LIST_TIME[indexStart]?.text)
        setCollectionTimeEnd(LIST_TIME[indexEnd]?.text)
      } else {
        setCollectionTimeStart('')
        setCollectionTimeEnd('')
      }
    }

    setCheckedNote([...termConds.map(() => false)])
  }, [eventDetail])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setIsLoading(true)
      if (!shareCode) {
        initialState()
      } else {
        getSharedEvent()
      }
    }
    return () => {
      mounted = false
    }
  }, [])

  const formatPrice = (number) => (Math.round(number * 100) / 100).toFixed(2)

  const findProductById = (pid, index) =>
    newOrderProducts.find((n) => n.id === pid && n.index === index)

  const updateOrderCount = (pid, orderCount) => {
    for (let index = 0; index < productOrderRender.length; index++) {
      if (productOrderRender[index].id === pid) {
        productOrderRender[index].newOrderCount = orderCount
      }
    }
    setProductOrderRender([...productOrderRender])
  }

  const checkMoreThanLimitPerOrder = (pid, limitPerOrder) => {
    if (limitPerOrder === 0) {
      return false
    }

    const listProduct = newOrderProducts.filter((item) => item.id === pid)
    const sumQtyPerProduct = listProduct.reduce((pre, cur) => pre + cur.quantity, 0)
    return sumQtyPerProduct >= limitPerOrder
  }

  const checkLessThanMinOrderQty = (pid, minOrderQty) => {
    const listProduct = newOrderProducts.filter((item) => item.id === pid)
    const sumQtyPerProduct = listProduct.reduce((pre, cur) => pre + cur.quantity, 0)
    return sumQtyPerProduct <= minOrderQty
  }

  const changeProductQuantity = ({ pid, number, index, type }) => {
    const productIndexNewOrder = newOrderProducts.findIndex(
      (n) => n.id === pid && n.index === index
    )
    const productIndexOrderRender = productOrderRender.findIndex(
      (n) => n.id === pid && n.index === index
    )

    const listProduct = newOrderProducts.filter((item) => item.id === pid)
    const sumQtyPerProduct = listProduct.reduce((pre, cur) => pre + cur.quantity, 0)

    const availableStock =
      productOrderRender[productIndexOrderRender].maxQuantity -
      productOrderRender[productIndexOrderRender].newOrderCount +
      newOrderProducts[productIndexNewOrder].quantity
    const newLimitPerOrder =
      productOrderRender[productIndexOrderRender].limitPerOrder -
      sumQtyPerProduct +
      newOrderProducts[productIndexNewOrder].quantity

    const minOrder = productOrderRender[productIndexOrderRender].minOrderQty || 1
    const maxOrder =
      newLimitPerOrder === 0
        ? availableStock
        : newLimitPerOrder > availableStock
        ? availableStock
        : newLimitPerOrder

    let adjustedNumber = number

    if (type === 'plus') {
      if (adjustedNumber < minOrder) {
        adjustedNumber = minOrder
      }
      if (adjustedNumber > maxOrder) {
        adjustedNumber = maxOrder
      }
    } else if (type === 'minus') {
      if (adjustedNumber < minOrder) {
        adjustedNumber = 0
      }
    }

    const oldQty = newOrderProducts[productIndexNewOrder].quantity

    newOrderProducts[productIndexNewOrder] = {
      ...newOrderProducts[productIndexNewOrder],
      quantity: adjustedNumber,
      quantityInput: adjustedNumber,
    }

    const newQty = newOrderProducts[productIndexNewOrder].quantity

    if (newQty - oldQty > 0) {
      updateOrderCount(
        pid,
        productOrderRender[productIndexOrderRender].newOrderCount + (newQty - oldQty)
      )
    } else {
      updateOrderCount(
        pid,
        productOrderRender[productIndexOrderRender].newOrderCount - (oldQty - newQty)
      )
    }
    setNewOrderProducts([...newOrderProducts])
  }

  const changeProductQuantityInput = ({ pid, number, index }) => {
    const productIndex = newOrderProducts.findIndex((n) => n.id === pid && n.index === index)
    const productIndexOrderRender = productOrderRender.findIndex(
      (n) => n.id === pid && n.index === index
    )

    const listProduct = newOrderProducts.filter((item) => item.id === pid)
    const sumQtyPerProduct = listProduct.reduce((pre, cur) => pre + cur.quantity, 0)

    const availableStock =
      productOrderRender[productIndexOrderRender].maxQuantity -
      productOrderRender[productIndexOrderRender].newOrderCount +
      newOrderProducts[productIndex].quantity
    const newLimitPerOrder =
      productOrderRender[productIndexOrderRender].limitPerOrder -
      sumQtyPerProduct +
      newOrderProducts[productIndex].quantity

    const minOrder = productOrderRender[productIndexOrderRender].minOrderQty || 1
    const maxOrder =
      newLimitPerOrder === 0
        ? availableStock
        : newLimitPerOrder > availableStock
        ? availableStock
        : newLimitPerOrder

    let newQuantity = !number ? 0 : number

    if (newQuantity < minOrder) {
      newQuantity = minOrder
    }

    if (newQuantity > maxOrder) {
      newQuantity = maxOrder
    }

    const oldQty = newOrderProducts[productIndex].quantity
    newOrderProducts[productIndex] = {
      ...newOrderProducts[productIndex],
      quantityInput: newQuantity,
      quantity: newQuantity,
    }
    const newQty = newOrderProducts[productIndex].quantity
    updateOrderCount(
      pid,
      productOrderRender[productIndexOrderRender].newOrderCount + (newQty - oldQty)
    )
    setNewOrderProducts([...newOrderProducts])
  }

  const getValueSelect = (pid, index, type) => {
    const posProduct = newOrderProducts.findIndex((n) => n.id === pid && n.index === index)
    const listSubItem = newOrderProducts[posProduct].subItems
    const posSubItem = listSubItem.findIndex((s) => s.type === type)
    if (posSubItem !== -1) {
      return JSON.stringify({
        name: listSubItem[posSubItem].name,
        price: listSubItem[posSubItem].price,
      })
    }
    return ''
  }

  const handleChangeSubItem = (pid, subItem, value, index) => {
    const posProduct = newOrderProducts.findIndex((n) => n.id === pid && n.index === index)
    const listSubItem = newOrderProducts[posProduct].subItems
    const posSubItem = listSubItem.findIndex((s) => s.type === subItem.type)
    if (posSubItem !== -1) {
      if (value) {
        listSubItem[posSubItem].price = value.price
        listSubItem[posSubItem].name = value.name
      } else {
        listSubItem.splice(posSubItem, 1)
      }
    } else if (value) {
      listSubItem.push({
        ...value,
        type: subItem.type,
        required: subItem.required,
      })
    }
    const newOrder = [...newOrderProducts]
    newOrder[posProduct].subItems = listSubItem
    setNewOrderProducts(newOrder)
  }

  const userInGroup = userInfo?.groups?.find(
    (group) => group?.gid === groupDetail?.group?.id && group?.uid === userInfo?.id
  )

  const onSubmit = async () => {
    try {
      if (!!termConds.length && checkedNote.some((item) => item === false)) {
        setError('Please check the note')
        return
      }
      setError('')

      if (userInGroup !== undefined && userInGroup.banned === 1) {
        addToast('You can not order!', {
          appearance: 'error',
          autoDismiss: true,
        })
        setError('Please check the note')
        return
      }
      setError('')

      let newOrder = {}
      setIsSubmitted(true)
      const newProducts = newOrderProducts.filter((p) => p.quantity > 0)

      const details = {}
      for (let index = 0; index < newProducts.length; index++) {
        if (details[newProducts[index].id]) {
          details[newProducts[index].id] = [
            ...details[newProducts[index].id],
            {
              quantity: newProducts[index].quantity,
              subItems:
                newProducts[index].subItems &&
                !!newProducts[index].subItems.length &&
                newProducts[index].subItems.map((s) => ({
                  type: s.type,
                  name: s.name,
                  price: s.price,
                })),
            },
          ]
        } else {
          details[newProducts[index].id] = [
            {
              quantity: newProducts[index].quantity,
              subItems:
                newProducts[index].subItems &&
                !!newProducts[index].subItems.length &&
                newProducts[index].subItems.map((s) => ({
                  type: s.type,
                  name: s.name,
                  price: s.price,
                })),
            },
          ]
        }
      }
      if (!Object.keys(details).length) {
        setError('Nothing to Purchase')
        setIsSubmitted(false)
        return
      }
      if (helpOrder) {
        const resFindUser = await userApi.searchUser(phoneOrEmail)
        const { uid } = resFindUser?.msgResp
        if (uid) {
          newOrder = { ...newOrder, representedUserId: uid }
        } else {
          setError('User not found.')
          setIsSubmitted(false)
          return
        }
      }

      newOrder = {
        ...newOrder,
        eid,
        details,
        comment,
      }

      if (edittingOrder) {
        dispatch(clearPastPurchasedMember())
        dispatch(clearDataPurchaseDetailByEid(newOrder?.eid))
        dispatch(clearEventAndOrder())

        try {
          const { msgResp } = !isEventShop
            ? await orderApi.updateOrder({ oid, data: newOrder })
            : await orderApi.updateShopEventOrder({ oid, data: newOrder })

          if (payLater) {
            if (isEventShop) {
              history.push(`/event/purchase-detail?eventShop=true&ref=${oid}`)
            } else {
              history.push(`/event/purchase-detail?ref=${oid}`)
            }
          } else {
            setShowModal(true)
          }

          setOrderNo(msgResp?.ref)
          setIsSubmitted(false)
          dispatch(clearMyOrder())
        } catch (err) {
          reportApi.report({ message: `[Event detail 03]${err.msgCode} - ${err.msgResp}` })
          setError(err.msgResp)
          setIsSubmitted(false)
        }

        dispatch(updateTotalOrderCountWhenUpdateOrder({ ...newOrder, initTotalOrderCount }))
        dispatch(
          updateTotalOrderCountInGroupWhenOrderWhenUpdateOrder({ ...newOrder, initTotalOrderCount })
        )
      } else {
        try {
          dispatch(clearPastPurchasedMember())
          dispatch(clearDataPurchaseDetailByEid(newOrder?.eid))
          dispatch(clearEventAndOrder())
          const res = !isEventShop
            ? await orderApi.createOrder(newOrder)
            : await orderApi.createShopEventOrder(newOrder)
          dispatch(updateEventWhenOrder(newOrder))
          dispatch(updateTotalOrderCountInGroupWhenOrder(newOrder))
          if (res) {
            if (payLater) {
              if (isEventShop) {
                history.push(`/event/purchase-detail?eventShop=true&ref=${res?.msgResp?.ref}`)
              } else {
                history.push(`/event/purchase-detail?ref=${res?.msgResp?.ref}`)
              }
            } else setShowModal(true)
            setOrderNo(res?.msgResp?.ref)
            setIsSubmitted(false)
            if (!helpOrder) dispatch(clearMyOrder())
          }
        } catch (err) {
          setIsSubmitted(false)
          if (err?.msgCode === BUYER_NOT_IN_GROUP_CODE && shareCode) {
            return addToast(err.msgResp, { appearance: 'info', autoDismiss: true })
            // history.push(`/group/join/${eventDetail.group.id}`)
          }

          reportApi.report({
            message: `[Event details 04]${err.msgCode} - [Event detail] #onCreateOrder ${err.msgResp}`,
          })
          setError(err.msgResp)
        }
      }
    } catch (err) {
      addToast(err?.msgResp || 'Something went wrong!', { appearance: 'error', autoDismiss: true })
    }
  }

  // eslint-disable-next-line max-len
  const calculatePricePerProduct = (pid, index) => {
    const product = findProductById(pid, index)
    return product?.price + product?.subItems?.reduce((pre, cur) => pre + cur.price, 0)
  }

  // eslint-disable-next-line max-len
  const calculateTotalPrice = () =>
    newOrderProducts?.reduce(
      (pre, cur) => pre + cur.quantity * calculatePricePerProduct(cur.id, cur.index),
      0
    )

  const addAnotherOrder = (pid) => {
    const lastIndex = productOrderRender.map((item) => item.id).lastIndexOf(pid)
    let product = products.find((item) => item.id === pid)
    product = {
      ...product,
      index: productOrderRender[lastIndex].index + 1,
      newOrderCount: productOrderRender[lastIndex].newOrderCount,
    }

    productOrderRender.splice(lastIndex + 1, 0, product)
    const newOrderProductConvert = {
      id: product.id,
      // eslint-disable-next-line max-len
      subItems: product.subItems
        .filter((s) => s.required === SUBITEM_REQUIRED)
        .map((s) => ({
          type: s.type,
          name: s.list[0].name,
          price: s.list[0].price,
          required: s.required,
        })),
      quantity: 0,
      quantityInput: 0,
      price: product.price,
      limitPerOrder: product.limitPerOrder,
      index: productOrderRender[lastIndex].index + 1,
    }
    const newOrderProduct = [...newOrderProducts, newOrderProductConvert]
    setNewOrderProducts([...newOrderProduct])
    setProductOrderRender([...productOrderRender])
  }

  const deleteItemOrder = (pid, index) => {
    const indexOrderProducts = newOrderProducts.findIndex(
      (item) => item.id === pid && item.index === index
    )
    const indexProductOrderRender = productOrderRender.findIndex(
      (item) => item.id === pid && item.index === index
    )

    // updateOrderCount
    for (let i = 0; i < productOrderRender.length; i++) {
      if (productOrderRender[i].id === pid) {
        productOrderRender[i].newOrderCount -= newOrderProducts[indexOrderProducts].quantity
      }
    }

    newOrderProducts.splice(indexOrderProducts, 1)
    productOrderRender.splice(indexProductOrderRender, 1)

    setNewOrderProducts([...newOrderProducts])
    setProductOrderRender([...productOrderRender])
  }

  useEffect(() => {
    const listGroupOfUser = getUserInfo()?.groups
    if (groupDetail.group?.public === GROUP_IS_PRIVATE) {
      const checkUserHaveThisGroup = listGroupOfUser?.filter(
        (group) => group?.gid === groupDetail?.group?.id
      )
      if (checkUserHaveThisGroup?.length) {
        setCheckShareEventHaveGroupIsPrivate(false)
      } else {
        setCheckShareEventHaveGroupIsPrivate(true)
      }
    } else {
      setCheckShareEventHaveGroupIsPrivate(false)
    }
  }, [groupDetail])

  if (checkShareEventHaveGroupIsPrivate) {
    return <p id='alert-event-in-private-group'>Viewing permitted for members only!!</p>
  }

  return (
    <React.Fragment>
      {isLoading && <div>Loading...</div>}
      {noEventFound && !isLoading && <div>No Event Found</div>}
      {!isLoading && !noEventFound && (
        <React.Fragment>
          <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id='modal-placed'
            tabIndex={-1}
            role='dialog'
            aria-labelledby='exampleModalCenterTitle'
          >
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content modal-content--yellow'>
                <div className='container text-center'>
                  <p className='c-txt-gray w-100'>
                    <strong>Your purchase has been placed.</strong>
                  </p>
                  <p className='c-txt-gray mb-4 mt-4'>
                    Please make payment using QR code found under MY PURCHASE to confirm your order
                    <br />
                    {/* <span className='txt-org'>{createdUserName}</span> */}
                  </p>
                  <div className='row'>
                    <div className='col-6 m-auto'>
                      <Link
                        to={
                          !isEventShop
                            ? `/event/purchase-detail?ref=${orderNo}`
                            : `/event/purchase-detail?eventShop=true&ref=${orderNo}`
                        }
                        className='btn btn--org btn--org02'
                      >
                        Confirm your order
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row-top'>
            <div className='group-list group-list--event detail-event group-list--event02'>
              <div className='group-list__item'>
                <div className='group-list__img'>
                  <div className='c-image'>
                    <img
                      src={
                        eventDetail?.createdUserPhotoUrl
                          ? eventDetail.createdUserPhotoUrl
                          : `${process.env.REACT_APP_AVATAR_URL}${eventDetail.createdUserName}`
                      }
                    />
                  </div>
                </div>
                <div className='group-list__info'>
                  <h3>{createdUserName}</h3>
                  <p>{groupDetail?.group?.name || groupDetail?.group?.shopName}</p>
                </div>
                <div className='group-list__btn text-right'>
                  {isAllowManageEvent && (
                    <Link
                      to={`/event/manage-orders?eventId=${eventDetail.id}`}
                      className='manageBtn'
                    >
                      MANAGE
                    </Link>
                  )}
                  <span className='txt-date'>
                    {getDateRemainFromNow(convertToCurrentGMT(closingTime))} left
                  </span>
                  <span className='txt-number'>
                    {eventDetail?.products?.reduce((pre, cur) => pre + cur.orderCount || 0, 0)} BUY
                  </span>
                </div>
                <div className='group-list__des'>
                  {listImages?.length > 0 && (
                    <ReactImageGallery
                      items={listImages}
                      lazyLoad
                      autoPlay
                      showPlayButton={false}
                      showNav={false}
                      showBullets={true}
                      showThumbnails={false}
                    />
                  )}
                  <h4>{title}</h4>
                  <p className='paragraph-pre-wrap'>{description}</p>
                </div>
                <div className='group-list__detail'>
                  <div className='row'>
                    <div className='col-3'>Closing: </div>
                    <div className='col-9'>
                      <p>{formatDate({ date: convertToCurrentGMT(closingTime) })}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-3'>Collection:</div>
                    <div className='col-9 align-self-center text-blue'>
                      <span>{formatDate({ date: convertToCurrentGMT(pickupTime) })}</span>
                      {collectionTimeStart && collectionTimeEnd ? (
                        <span>{` (${collectionTimeStart} - ${collectionTimeEnd})`}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-3'>Address:</div>
                    <div className='col-9 align-self-center text-blue'>
                      <p>{pickupAddress}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='detail-list-news'>
              <ShowListProducts
                productOrderRender={productOrderRender}
                formatPrice={formatPrice}
                calculatePricePerProduct={calculatePricePerProduct}
                findProductById={findProductById}
                newOrderProducts={newOrderProducts}
                calculateTotalPrice={calculateTotalPrice}
                handleChangeSubItem={handleChangeSubItem}
                getValueSelect={getValueSelect}
                changeProductQuantity={changeProductQuantity}
                addAnotherOrder={addAnotherOrder}
                deleteItemOrder={deleteItemOrder}
                changeProductQuantityInput={changeProductQuantityInput}
                checkMoreThanLimitPerOrder={checkMoreThanLimitPerOrder}
                edittingOrder={edittingOrder}
                checkLessThanMinOrderQty={checkLessThanMinOrderQty}
              />
            </div>
            {!!termConds.length && <p>Please note:</p>}
            {!!termConds.length &&
              termConds.map((item, index) => (
                <React.Fragment key={index}>
                  <div className='c-form termCond'>
                    <div className='custom-control custom-checkbox'>
                      <input
                        id='customCheck3'
                        type='checkbox'
                        className='custom-control-input'
                        checked={checkedNote[index]}
                        readOnly
                      />
                      <label
                        className='custom-control-label'
                        onClick={() => handleOnClickNote(index)}
                        htmlFor='customCheck3'
                      >
                        &nbsp;
                      </label>
                      <span className='c-txt-12' onClick={() => handleOnClickNote(index)}>
                        {parse(item)}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            {!!extraFields?.length && (
              <div className='c-form extraFields'>
                {extraFields?.map((e, i) => (
                  <div key={i} className='custom-control custom-checkbox'>
                    <label className='custom-control-label'>{e?.name}:</label>
                    <p className='c-txt-12'>
                      <a href={e?.value} target='_blank' rel='noreferrer'>
                        {e?.value}
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className='c-form'>
              <div className='nav-amount'>
                <div className='row'>
                  <div className='col-7'>
                    {payLater && (
                      <p className='note fs-10'>
                        <b>PAY LATER:</b>
                        <br />
                        Host requests payment to be made after the event closed. Sharing of delivery
                        charges, discounts and admin fee may apply.
                      </p>
                    )}
                  </div>
                  <div className='col-5'>
                    <div className='sum text-right'>
                      <p className='c-txt-org text-right'>
                        $<span>{formatPrice(calculateTotalPrice())}</span> SGD
                        <br />
                        <small style={{ display: 'block' }}>
                          $<span>{formatPrice(adminCost)}</span> admin fee included
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {!oid && !shareCode && adminOrHost ? (
                <div className='text-center'>
                  <span
                    className='link-share text-underline'
                    onClick={() => setIsPurchase(!isPurchase)}
                    style={{ cursor: 'pointer' }}
                  >
                    Purchase-on-Behalf
                  </span>
                </div>
              ) : null}
              {isPurchase && (
                <div className='form-group'>
                  <input
                    className='form-control'
                    placeholder='Phone or email'
                    value={phoneOrEmail}
                    onChange={(e) => setPhoneOrEmail(e.target.value.trim())}
                  />
                </div>
              )}
              <div className='c-form'>
                <div className='form-group'>
                  <label htmlFor='note'>
                    <strong>NOTES TO THE HOST</strong>
                  </label>
                  <textarea
                    className='form-control'
                    value={comment}
                    rows={3}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
                {error && <small style={{ color: '#ff9900' }}>{error}</small>}
                {allowPlaceOrder && (
                  <div className='row justify-content-center'>
                    <div className='col-6'>
                      <span
                        onClick={() => history.goBack()}
                        className='btn btn--solid btn--solid03'
                      >
                        CANCEL
                      </span>
                    </div>
                    <div className='col-6'>
                      <button className='btn btn--main' onClick={onSubmit} disabled={isSubmitted}>
                        {isSubmitted ? <Spinner color='light' size='sm' /> : 'PLACE ORDER'}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default withRouter(EventDetail)
