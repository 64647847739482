import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import reportApi from '_api/report'
import { setUserInfo } from '_utils/localData'

const Avatar = ({ photoUrl }) => {
  const { addToast } = useToasts()
  const [avatarFileUrl, setAvatarFileUrl] = useState('')

  const onUserImageUploaded = (e) => {
    const { files } = e.target
    if (files) {
      uploadApi
        .uploadPhoto(files)
        .then(({ msgCode, msgResp }) => {
          if (msgCode % 100 === 0) {
            const { url } = msgResp
            setAvatarFileUrl(url)
            userApi
              .updateUser({ photoUrl: url })
              .then(() => {
                userApi
                  .getUser()
                  .then(({ msgResp: res0 }) => {
                    setUserInfo(res0)
                  })
                  .catch(({ msgResp: res1 }) => {
                    reportApi.report({ message: `[Avatar 01]${msgCode} - ${msgResp}` })
                    addToast(res1, { appearance: 'error', autoDismiss: true })
                  })
              })
              .catch(({ msgResp: res2 }) => {
                reportApi.report({ message: `[Avatar 03]${msgCode} - ${msgResp}` })
                addToast(res2, { appearance: 'error', autoDismiss: true })
              })
          }
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `[Avatar 02]${msgCode} - ${msgResp}` })
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
        })
    }
  }

  useEffect(() => {
    setAvatarFileUrl(photoUrl)
  }, [photoUrl])

  return (
    <div className='profile-avatar'>
      <div className='upload-image'>
        <img id='userAvatarImg' src={avatarFileUrl} alt='' className='w-100' />
        <div className='upload-image-txt d-none'>
          <i className='icon-capture'></i>
          <span>
            Add Profile
            <br />
            Picture
          </span>
        </div>
        <div className='u-file-upload__item'>
          <div className='u-file-upload__wrap'>
            <div className='c-upload preview-images-zone'>
              <input
                id='userAvatarInput'
                type='file'
                accept='image/*'
                name='file_source_01'
                size='40'
                className='inputFile'
                data-error='#error-file_source_01'
                onChange={onUserImageUploaded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Avatar
