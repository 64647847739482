import React from 'react'

export default function ButtonDayCollection({ day, isDisable }) {
  return (
    <div>
      <a
        href=''
        className='btn-purchase btn-purchase-02'
        style={isDisable ? { color: 'gray' } : {}}
      >
        <span>{day} days to Collection</span>
      </a>
    </div>
  )
}
