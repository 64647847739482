import React from 'react'
import PropTypes from 'prop-types'

const SearchDelivery = ({ searchString, onChange, styles = null }) => (
  <div className='c-form c-form--search' style={{ marginTop: 0 }}>
    <div className='form-group' style={styles}>
      <i className='icon-search'></i>
      <input
        className='form-control'
        placeholder='Search Name Or Mobile Number'
        type='text'
        value={searchString}
        onChange={onChange}
      />
    </div>
  </div>
)

SearchDelivery.propTypes = {
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
}

export default SearchDelivery
