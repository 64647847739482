/* eslint-disable multiline-ternary */
import PropTypes from 'prop-types'
import React from 'react'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import authApi from '_api/auth'
import { FACEBOOK, GOOGLE, UNKNOWN } from '_utils/constant'
import { setSocialToken, setUserInfo, setUserToken } from '_utils/localData'
import { useDispatch } from 'react-redux'
import { getAllEventsOpening } from '../redux/modules/event'
import { getListShopPaid } from '../redux/modules/shop'
import { fetchUserInfo, userFirstLogin } from '../redux/modules/user'

const SocialButton = ({ social = FACEBOOK, isLogin = true, setIsShowFormConnect }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()

  const response = async (resp) => {
    let userInfo = null
    const token = resp?.accessToken
    if (social === FACEBOOK) {
      if (resp.status !== UNKNOWN) {
        userInfo = {
          name: resp.name,
          email: resp.email,
          imageUrl: resp.picture.data.url,
          id: resp.userID,
        }
      } else {
        return
      }
    } else if (social === GOOGLE) {
      const { name, email, imageUrl, googleId: id } = resp.profileObj
      userInfo = {
        name,
        email,
        imageUrl,
        id,
      }
    }

    if (isLogin) {
      try {
        const _res = await authApi.login3rdParty({ type: social, accessToken: token })
        const isShareGroup = localStorage.getItem('isShareGroup')

        setUserToken(_res.msgResp.token)

        await dispatch(fetchUserInfo())

        await Promise.all([dispatch(getAllEventsOpening), dispatch(getListShopPaid())])

        if (isShareGroup) {
          history.push(`/group/join/${isShareGroup.replace('/group/join/', '').trim()}`)
        } else {
          history.push('/welcome')
        }
      } catch ({ msgResp }) {
        addToast(msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      setIsShowFormConnect(true)
      userInfo.social = social
      setUserInfo(userInfo)
      setSocialToken(token)
    }

    dispatch(userFirstLogin())
  }

  return (
    <>
      {social === FACEBOOK ? (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields='name,email,picture'
          callback={response}
          cssClass={isLogin ? 'btn btn--gray btn--bold' : 'btn btn--graylight btn--bold'}
          icon={<i className='icon-fb'></i>}
          textButton='Facebook'
          isMobile={false}
        />
      ) : (
        <GoogleLogin
          icon={false}
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          onSuccess={response}
          render={(renderProps) => (
            <button className='btn btn--gray btn--bold' onClick={renderProps.onClick}>
              <i className='icon-google'></i>Google
            </button>
          )}
        />
      )}
    </>
  )
}

export default SocialButton

SocialButton.propTypes = {
  setIsAuthError: PropTypes.func,
  setIsShowFormConnect: PropTypes.func,
  social: PropTypes.string,
  isLogin: PropTypes.bool,
}
