import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'reactstrap'

const ModalApprove = ({ modal, toggle, memberSelected, onReject, onAccept }) => (
  <Modal isOpen={modal} toggle={toggle} centered className='approve-modal'>
    <div className='modal-content'>
      <div className='container'>
        <div className='row-top'>
          <div className='modal-yellow'>
            <h2 className='text-center c-ttl'>
              <span>{memberSelected?.name}</span>
              <br />
              Request
            </h2>
            <div className='c-form mb-4'>
              <div className='form-group'>
                <label className='mb-2'></label>
                <input
                  className='form-control form-control--gray form-control--radius'
                  type='text'
                  disabled
                  value={memberSelected?.answer || ''}
                />
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-6'>
              <button className='btn btn--gray' onClick={onReject}>
                REJECT
              </button>
            </div>
            <div className='col-6'>
              <button className='btn btn--org btn--org02' onClick={onAccept}>
                ACCEPT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
)

ModalApprove.propTypes = {
  memberSelected: PropTypes.shape({
    answer: PropTypes.string,
    name: PropTypes.string,
  }),
  modal: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  toggle: PropTypes.func,
}

export default ModalApprove
