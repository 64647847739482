import React, { useEffect, useState, memo } from 'react'
import { Line, } from 'react-chartjs-2'

function AverageChart({ data }) {
  const [date, setDate] = useState()
  const [value, setValue] = useState()

  useEffect(() => {
    if (data?.length > 0) {
      if (data[0].typeShowData === 'weeks') {
        const listDate = data?.map((element) => {
          const getDate = element.date.slice(0, 5)
          return `${getDate}`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else if (data[0].typeShowData === 'months') {
        const listDate = data?.map((element) => {
          const getMonth = element.monthInhYear.slice(0, 2)
          return `${getMonth} M`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else {
          const listDate = data?.map((element) => {
            const getYear = element.year
            return `${getYear} Y`
          })
          const listValue = data?.map((element) => element.totalValue)
          setValue(listValue)
          setDate(listDate)
      }
    }
  }, [data])

  return (
    <>
      <Line
        data={{
          labels: date,
          datasets: [
            {
              label: 'AVERAGE ORDER SIZE',
              data: value,
              borderColor: '#14CDCA',
              backgroundColor: '#14CDCA',
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              display: false
            },
          },
        }}
      />
    </>
  )
}

export default memo(AverageChart)
